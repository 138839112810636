import { HTTP_CP_Lender } from '@api-new/mortgageservice';
import { ErrorModel } from '@app/OLD_shared/models/app-state.model';
import { Action } from '@ngrx/store';
import { MortgageErcModel } from '../../models/mortgage-erc.model';
import { MortgagePartModel } from '../../models/mortgage-part.model';
import { MortgageEditMetadata, MortgageEditPartModel, MortgageEditRateModel, MortgageModel } from '../../models/mortgage.model';
import { OverpaymentModel } from '../../models/overpayment.model';
import { PayloadAction } from '../store-utils';

export class UploadCurrentEditedMortgage implements Action {
  static readonly type = 'UploadCurrentEditedMortgage';
  readonly type = UploadCurrentEditedMortgage.type;
}

export class SetCurrentMortgageAsEditedAction implements Action {
  static readonly type = 'SetCurrentMortgageAsEditedAction';
  readonly type = SetCurrentMortgageAsEditedAction.type;
}

export class SetEditedMortgageAction extends PayloadAction<MortgageModel> {
  static readonly type = 'SetEditedMortgageAction';
  readonly type = SetEditedMortgageAction.type;
}

export class SetEditMortgageAttributeAction<T = MortgageModel, U extends keyof T = keyof T> implements Action {
  static readonly type = 'SetEditMortgageAttributeAction';
  readonly type = SetEditMortgageAttributeAction.type;

  constructor(
    public readonly key: U,
    public readonly value: T[U],
  ) {}
}

export class SetMortgageEditMetadataValueAction<T = MortgageEditMetadata, U extends keyof T = keyof T> implements Action {
  static readonly type = 'SetMortgageEditMetadataValueAction';
  readonly type = SetMortgageEditMetadataValueAction.type;

  constructor(
    public readonly key: U,
    public readonly value: T[U],
  ) {}
}

export class SetMortgageEditCurrentPartValueAction<T = MortgageEditPartModel, U extends keyof T = keyof T> implements Action {
  static readonly type = 'SetMortgageEditCurrentPartValueAction';
  readonly type = SetMortgageEditCurrentPartValueAction.type;

  constructor(
    public readonly key: U,
    public readonly value: T[U],
  ) {}
}

export class AddMortgageEditPartAction extends PayloadAction<MortgagePartModel> {
  static readonly type = 'AddMortgageEditPartAction';
  readonly type = AddMortgageEditPartAction.type;
}

export class DeleteMortgageEditCurrentPartAction implements Action {
  static readonly type = 'DeleteMortgageEditCurrentPartAction';
  readonly type = DeleteMortgageEditCurrentPartAction.type;
}

export class AddMortgageEditOverpaymentAction implements Action {
  static readonly type = 'AddMortgageEditOverpaymentAction';
  readonly type = AddMortgageEditOverpaymentAction.type;
}

export class DeleteMortgageEditOverpaymentAction extends PayloadAction<number> {
  static readonly type = 'DeleteMortgageEditOverpaymentAction';
  readonly type = DeleteMortgageEditOverpaymentAction.type;
}

export class SetMortgageEditOverpaymentValueAction<T = OverpaymentModel, U extends keyof T = keyof T> implements Action {
  static readonly type = 'SetMortgageEditOverpaymentValueAction';
  readonly type = SetMortgageEditOverpaymentValueAction.type;

  constructor(
    public readonly key: U,
    public readonly value: T[U],
    public readonly index: number,
  ) {}
}

export class AddMortgageEditUnderpaymentAction implements Action {
  static readonly type = 'AddMortgageEditUnderpaymentAction';
  readonly type = AddMortgageEditUnderpaymentAction.type;
}

export class DeleteMortgageEditUnderpaymentAction extends PayloadAction<number> {
  static readonly type = 'DeleteMortgageEditUnderpaymentAction';
  readonly type = DeleteMortgageEditUnderpaymentAction.type;
}

export class SetMortgageEditUnderpaymentValueAction<T = OverpaymentModel, U extends keyof T = keyof T> implements Action {
  static readonly type = 'SetMortgageEditUnderpaymentValueAction';
  readonly type = SetMortgageEditUnderpaymentValueAction.type;

  constructor(
    public readonly key: U,
    public readonly value: T[U],
    public readonly index: number,
  ) {}
}

export class SetMortgageEditCurrentPartRateValueAction<T = MortgageEditRateModel, U extends keyof T = keyof T> implements Action {
  static readonly type = 'SetMortgageEditCurrentPartRateValueAction';
  readonly type = SetMortgageEditCurrentPartRateValueAction.type;

  constructor(
    public readonly key: U,
    public readonly value: T[U],
    public readonly index: number,
  ) {}
}

export class SetMortgageEditCurrentPartSVRAction extends PayloadAction<{
  fixedInterestRate: number;
  variableLenderSvrId: string;
  rateIndex: number;
}> {
  static readonly type = 'SetMortgageEditCurrentPartSVRAction';
  readonly type = SetMortgageEditCurrentPartSVRAction.type;
}

export class AddMortgageEditRateAction implements Action {
  static readonly type = 'AddMortgageEditRateAction';
  readonly type = AddMortgageEditRateAction.type;
}

export class DeleteMortgageEditRateAction extends PayloadAction<number> {
  static readonly type = 'DeleteMortgageEditRateAction';
  readonly type = DeleteMortgageEditRateAction.type;
}

export class AddMortgageEditERCAction implements Action {
  static readonly type = 'AddMortgageEditERCAction';
  readonly type = AddMortgageEditERCAction.type;
}

export class DeleteMortgageEditERCAction extends PayloadAction<number> {
  static readonly type = 'DeleteMortgageEditERCAction';
  readonly type = DeleteMortgageEditERCAction.type;
}

export class SetMortgageEditERCValueAction<T = MortgageErcModel, U extends keyof T = keyof T> implements Action {
  static readonly type = 'SetMortgageEditERCValueAction';
  readonly type = SetMortgageEditERCValueAction.type;

  constructor(
    public readonly key: U,
    public readonly value: T[U],
    public readonly index: number,
  ) {}
}

export class CalculateMortgageRatePaymentsAction implements Action {
  static readonly type = 'CalculateMortgageRatePaymentsAction';
  readonly type = CalculateMortgageRatePaymentsAction.type;
}

export class CalculateMortgageRatePaymentsSuccessAction extends PayloadAction<number[]> {
  static readonly type = 'CalculateMortgageRatePaymentsSuccessAction';
  readonly type = CalculateMortgageRatePaymentsSuccessAction.type;
}

export class GetLenderSvrsForMortgageEditSuccessAction extends PayloadAction<HTTP_CP_Lender> {
  static readonly type = 'GetLenderSvrsForMortgageEditSuccessAction';
  readonly type = GetLenderSvrsForMortgageEditSuccessAction.type;
}

export class GetLenderSvrsForMortgageEditFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'GetLenderSvrsForMortgageEditFailureAction';
  readonly type = GetLenderSvrsForMortgageEditFailureAction.type;
}
export class GetLenderSvrsForMortgageEditAction extends PayloadAction<string> {
  static readonly type = 'GetLenderSvrsForMortgageEditAction';
  readonly type = GetLenderSvrsForMortgageEditAction.type;
}

export type MortgageEditAction =
  | UploadCurrentEditedMortgage
  | SetCurrentMortgageAsEditedAction
  | SetEditedMortgageAction
  | SetEditMortgageAttributeAction
  | SetMortgageEditMetadataValueAction
  | SetMortgageEditCurrentPartValueAction
  | AddMortgageEditPartAction
  | DeleteMortgageEditCurrentPartAction
  | SetMortgageEditOverpaymentValueAction
  | AddMortgageEditOverpaymentAction
  | DeleteMortgageEditOverpaymentAction
  | SetMortgageEditUnderpaymentValueAction
  | AddMortgageEditUnderpaymentAction
  | DeleteMortgageEditUnderpaymentAction
  | SetMortgageEditCurrentPartRateValueAction
  | SetMortgageEditCurrentPartSVRAction
  | AddMortgageEditRateAction
  | DeleteMortgageEditRateAction
  | AddMortgageEditERCAction
  | DeleteMortgageEditERCAction
  | SetMortgageEditERCValueAction
  | CalculateMortgageRatePaymentsAction
  | CalculateMortgageRatePaymentsSuccessAction
  | GetLenderSvrsForMortgageEditSuccessAction
  | GetLenderSvrsForMortgageEditFailureAction
  | GetLenderSvrsForMortgageEditAction;
