import { HTTP_CP_ListMortgageCases_Response } from '@api-new/caseservice';
import { ErrorModel } from '@app/OLD_shared/models/app-state.model';
import { createAction, props } from '@ngrx/store';

export const getMortgageCases = createAction('[MORTGAGE-CASES] Get Mortgage Cases');
export const closeMortgageCase = createAction('[MORTGAGE-CASES] Close Mortgage Case');

export const closeMortgageCasesSuccess = createAction(
  '[MORTGAGE-CASES] Close Mortgage Case Success',
  props<{
    mortgageCaseId: string;
  }>(),
);

export const getMortgageCasesSuccess = createAction(
  '[MORTGAGE-CASES] Get Mortgage Cases Success',
  props<HTTP_CP_ListMortgageCases_Response>(),
);
export const getMortgageCasesFailure = createAction('[MORTGAGE-CASES] Get Mortgage Cases Failure', props<{ error: ErrorModel }>());
