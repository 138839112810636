import { HTTP_CP_HelpCase } from '@api-new/caseservice';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  addHelpCase,
  closeHelpCase,
  closeHelpCaseFailure,
  closeHelpCaseSuccess,
  getHelpCases,
  getHelpCasesFailure,
  getHelpCasesSuccess,
} from '@shared/store/help-cases/help-cases.actions';
import { HelpCasesState } from '@shared/store/help-cases/help-cases.state';

export const getHelpCaseById = (helpCase: Pick<HTTP_CP_HelpCase, 'id'>): string => helpCase.id;

export const helpCasesAdapter: EntityAdapter<HTTP_CP_HelpCase> = createEntityAdapter<HTTP_CP_HelpCase>({
  selectId: getHelpCaseById,
});

export const helpCasesInitialState: HelpCasesState = helpCasesAdapter.getInitialState({
  loading: true,
  errors: null,
});

const reducer = createReducer(
  helpCasesInitialState,

  on(getHelpCases, closeHelpCase, (state) => ({
    ...state,
    loading: true,
  })),

  on(getHelpCasesSuccess, (state, { helpCases }) =>
    helpCasesAdapter.setAll(helpCases ? helpCases : [], {
      ...state,
      loading: false,
      errors: null,
    }),
  ),

  on(getHelpCasesFailure, closeHelpCaseFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),

  on(addHelpCase, (state, { helpCase }) => helpCasesAdapter.addOne(helpCase, state)),

  on(closeHelpCaseSuccess, (state, { helpCaseId }) =>
    helpCasesAdapter.removeOne(helpCaseId, {
      ...state,
      loading: false,
    }),
  ),
);

export function helpCasesReducer(state: HelpCasesState = helpCasesInitialState, action: Action): HelpCasesState {
  return reducer(state, action);
}
