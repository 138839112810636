import { ENUM_PropertyInvestmentType } from '@api-new/common';
import { HTTP_CP_Property } from '@api-new/propertyservice';
import { MemoizedSelector, createSelector } from '@ngrx/store';
import { AppState, PropertyState } from '../../models/app-state.model';

export const $propertyState = (state: AppState): PropertyState => state.OLD_property;

export const $propertyList = createSelector($propertyState, (state: PropertyState) =>
  Object.keys(state.entities)
    .reduce((acc, item) => [...acc, state.entities[item]], [])
    .filter((property) => !property.isClosed),
);

export const $propertiesLoaded = createSelector($propertyState, (state: PropertyState) => state.loaded);

export const $selectPropertyById = (id: string): MemoizedSelector<AppState, HTTP_CP_Property, (s1: PropertyState) => HTTP_CP_Property> =>
  createSelector($propertyState, (state: PropertyState) => (state.entities != null ? state.entities[id] : null));

export const $selectedProperty = createSelector(
  $propertyState,
  (state: PropertyState): HTTP_CP_Property => (state.entities != null ? state.entities[state.metadata.selectedProperty] : null),
);

export const $residentialProperty = createSelector($propertyState, (state: PropertyState) =>
  state.entities == null || Object.keys(state.entities).length < 1
    ? null
    : Object.keys(state.entities)
        .map((key) => state.entities[key])
        .find((item) => item.investmentType === ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_RESIDENTIAL),
);
