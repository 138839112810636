import { ErrorHandler, Inject, Injectable, InjectionToken } from '@angular/core';
import { SkipableErrorHandler } from './skipable-error-handler.interface';

export const ERROR_HANDLERS = new InjectionToken<ErrorHandler[]>('ERROR_HANDLERS');

/**
 * GlobalErrorHandler takes ERROR_HANDLERS injection token and runs sequence of error handlers - method handleError,
 * - if one of handlers returns false, next handlers are skipped
 */
@Injectable({
  providedIn: 'root',
})
export class GlobalErrorHandler implements ErrorHandler {
  constructor(@Inject(ERROR_HANDLERS) private errorHandlers: SkipableErrorHandler[]) {}

  handleError(error: any): void {
    this.errorHandlers.every((errorHandler) => errorHandler.handleError(error) !== false);
  }
}
