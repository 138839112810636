import { HTTP_CP_Mortgage, HTTP_CP_SmartSearch } from '@api-new/mortgageservice';
import { DEFAULTS } from '../../defaults';
import { ErrorModel, MortgageState } from '../../models/app-state.model';
import { MortgageSearchCriteriaMetadataModel } from '../../models/mortgage-search.model';
import { MortgageModel } from '../../models/mortgage.model';
import {
  ClearMortgageWaitingForChange,
  ClearMortgageWaitingForClose,
  CloseMortgageAction,
  CloseMortgageFailureAction,
  CloseMortgageSuccessAction,
  CreateMortgageAction,
  CreateMortgageFailureAction,
  GetClientUserDataSuccessAction,
  GetMortgagesAction,
  GetMortgagesFailureAction,
  GetMortgagesSuccessAction,
  GetSingleMortgageSuccessAction,
  IncrementSearchCriteriaSelectedIndexAction,
  MortgageAction,
  SetMortgageWaitingForChange,
  SetMortgageWaitingForClose,
  UpdateAccountsMetadata,
  UpdateMortgageAction,
  UpdateMortgageFailureAction,
  UpdateMortgageSuccessAction,
  UpdateSearchCriteriaAction,
  UpdateSearchCriteriaFailureAction,
  UpdateSearchCriteriaMetadataAction,
  UpdateSearchCriteriaSelectedIndexAction,
  UpdateSearchCriteriaSuccessAction,
} from '../actions/mortgage.action';
import { PayloadAction } from '../store-utils';

const initialState: MortgageState = {
  entities: {},
  loading: false,
  loaded: false,
  errors: null,

  metadata: {
    searchPreferencesMetadata: DEFAULTS.defaultSearchPreferencesMetadata(),
    selectedToClose: null,
    selectedToChange: null,
    smartSearches: null,
    accountsMetadata: {
      dealHash: null,
      detailId: null,
      detailType: null,
      editing: false,
    },
  },
};

export function mortgageReducer(state: MortgageState = initialState, action: MortgageAction): MortgageState {
  switch (action.type) {
    case GetMortgagesAction.type:
      return {
        ...state,
        loading: !state.loaded,
      };
    case CreateMortgageAction.type:
    case UpdateMortgageAction.type:
    case CloseMortgageAction.type:
    case UpdateSearchCriteriaAction.type:
      return {
        ...state,
        loading: true,
      };

    case GetMortgagesFailureAction.type:
    case CreateMortgageFailureAction.type:
    case UpdateMortgageFailureAction.type:
    case CloseMortgageFailureAction.type:
    case UpdateSearchCriteriaFailureAction.type:
      return {
        ...state,
        loading: false,
        errors: (action as PayloadAction<ErrorModel>).payload,
      };

    case GetMortgagesSuccessAction.type: {
      const mortgages: MortgageModel[] = action.payload;
      return {
        ...state,
        entities: {
          ...(mortgages || []).reduce(
            (acc: { [id: string]: MortgageModel }, mortgage) => ({
              ...acc,
              [mortgage.id]: mortgage,
            }),
            {},
          ),
        },
        loading: false,
        loaded: mortgages != null,
      };
    }

    case GetClientUserDataSuccessAction.type: {
      const smartSearches: HTTP_CP_SmartSearch[] = action.payload;
      return {
        ...state,
        metadata: {
          ...state.metadata,
          smartSearches,
        },
        loading: false,
      };
    }

    case GetSingleMortgageSuccessAction.type: {
      const mortgage = action.payload;
      if (mortgage == null || mortgage.id == null) {
        return state;
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [mortgage.id]: mortgage,
        },
      };
    }

    case UpdateMortgageSuccessAction.type: {
      const mortgage = (action as PayloadAction<MortgageModel>).payload;
      const entities = {
        ...state.entities,
        [mortgage.id]: mortgage,
      };
      return {
        ...state,
        entities,
        loading: false,
      };
    }

    case CloseMortgageSuccessAction.type: {
      const closingModel = action.payload;
      const { [closingModel.id]: removed, ...entities } = state.entities;
      return {
        ...state,
        entities,
        loading: false,
      };
    }

    case ClearMortgageWaitingForClose.type:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          selectedToClose: null,
        },
      };

    case ClearMortgageWaitingForChange.type:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          selectedToChange: null,
        },
      };

    case UpdateSearchCriteriaSuccessAction.type: {
      const criteria = action.payload;
      const entities = {
        ...state.entities,
        [criteria.mortgageId]: {
          ...state.entities[criteria.mortgageId],
          mortgageComparisonSettings: DEFAULTS.fillDefaultMortgageSearch(criteria),
        } as HTTP_CP_Mortgage,
      };
      return {
        ...state,
        entities,
        loading: false,
      };
    }

    case UpdateSearchCriteriaSelectedIndexAction.type: {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          searchPreferencesMetadata: {
            ...state.metadata.searchPreferencesMetadata,
            selectedIndex: action.payload,
          },
        },
      };
    }

    case IncrementSearchCriteriaSelectedIndexAction.type: {
      const increment: boolean = action.payload;
      const mortgagesLength: number = Object.keys(state.entities).length;
      const index = (state.metadata.searchPreferencesMetadata.selectedIndex + (increment ? 1 : mortgagesLength - 1)) % mortgagesLength;

      return {
        ...state,
        metadata: {
          ...state.metadata,
          searchPreferencesMetadata: {
            ...state.metadata.searchPreferencesMetadata,
            selectedIndex: index,
          },
        },
      };
    }

    case UpdateSearchCriteriaMetadataAction.type: {
      const selectedIndex = state.metadata.searchPreferencesMetadata.selectedIndex;
      let newMetadata: MortgageSearchCriteriaMetadataModel = {
        ...DEFAULTS.defaultSearchPreferencesMetadata(),
        selectedIndex,
      };
      const mortgage: MortgageModel = state.entities[Object.keys(state.entities)[newMetadata.selectedIndex]];

      if (mortgage != null) {
        const mortgageSearch = DEFAULTS.fillDefaultMortgageSearch(mortgage.mortgageComparisonSettings);

        newMetadata = {
          ...newMetadata,
          restrictBanks: mortgageSearch.excludedLenders != null && mortgageSearch.excludedLenders.length > 0,
          minimumSavingsForMortgageExpiryTypeEarly: mortgageSearch.minimumSavingsForMortgageExpiryTypeEarly != null,
          maxFees: mortgageSearch.maxFees != null,
          maxRate: mortgageSearch.maxInterestRate != null,
          maxInitialPayments: mortgageSearch.maxInitialPayments > 0,
          minRatePeriod: mortgageSearch.minRatePeriod != null,
          maxRatePeriod: mortgageSearch.maxRatePeriod != null,
        };
      }

      return {
        ...state,
        metadata: {
          ...state.metadata,
          searchPreferencesMetadata: {
            ...newMetadata,
          },
        },
      };
    }

    case UpdateAccountsMetadata.type: {
      return {
        ...state,
        metadata: {
          ...state.metadata,
          accountsMetadata: {
            ...action.payload,
          },
        },
      };
    }

    case SetMortgageWaitingForClose.type:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          selectedToClose: action.payload,
        },
      };

    case SetMortgageWaitingForChange.type:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          selectedToChange: action.payload,
        },
      };

    default:
      return state;
  }
}
