import { Injectable } from '@angular/core';
import { ENUM_MortgageRepaymentType } from '@api-new/common';
import { MortgageApiService } from '@api-new/mortgageservice';
import { ToastService, ToastSeverity } from '@shared-lib/services/toast.service';
import { Observable, ReplaySubject, of, throwError } from 'rxjs';
import { map, take } from 'rxjs/operators';
import { OIREqualPaymentsRequestModel, PaymentCalculatorModel } from '../models/calculations.model';

@Injectable({ providedIn: 'root' })
export class CalculationsService {
  private readonly storedResult = new ReplaySubject<number>(1);
  storedRequest: OIREqualPaymentsRequestModel = null;
  storedPaymentRequest: PaymentCalculatorModel = null;
  storedPaymentResult: { monthlyPayments: number[] };

  $interestRate: Observable<number>;

  constructor(
    private readonly mortgageApi: MortgageApiService,
    private readonly toastService: ToastService,
  ) {
    this.$interestRate = this.storedResult.asObservable();
  }

  OIR_Equal(payload: OIREqualPaymentsRequestModel): void {
    if (JSON.stringify(this.storedRequest) !== JSON.stringify(payload)) {
      this.storedRequest = { ...payload };
      this.calculateInterestRateByRepaymentType(payload.repayment).subscribe(
        (item: { interestRate: number }) => this.storedResult.next(item.interestRate),
        (error) => this.handleError(payload.repayment, error),
      );
    } else {
      this.storedResult.pipe(take(1)).subscribe((result) => this.storedResult.next(result));
    }
  }

  handleError(repaymentType: ENUM_MortgageRepaymentType, error?: any): void {
    console.error(error || `Cannot calculate interest rate for provided repayment type. Type "${repaymentType}" is not supported`);
    this.toastService.showToast(ToastSeverity.error, 'Could not calculate interest rate');
    this.storedResult.next(null);
  }

  calculatedPayment(data: PaymentCalculatorModel): Observable<{ monthlyPayments: number[] }> {
    if (JSON.stringify(data) !== JSON.stringify(this.storedPaymentRequest) || this.storedPaymentResult == null) {
      this.storedPaymentRequest = {
        ...data,
        parts: [
          {
            ...data.parts[0],
            rates: data.parts[0].rates.sort((a, b) => a.ends - b.ends),
          },
        ],
      };
      const enhancedData = {
        initialLoanAmount: data.parts[0].initialAmount,
        isInterestOnly: data.parts[0].interestOnly,
        interestRateSteps: data.parts[0].interestRateSteps,
      };

      this.storedPaymentResult = null;
      return this.mortgageApi.HTTP_X_CalculateMonthlyPayments(enhancedData).pipe(
        map((response) => {
          this.storedPaymentResult = response;
          return response;
        }),
      );
    }
    return of(this.storedPaymentResult);
  }

  private calculateInterestRateByRepaymentType(repayment: ENUM_MortgageRepaymentType): Observable<{ interestRate: number }> {
    if (repayment === ENUM_MortgageRepaymentType.MORTGAGE_REPAYMENT_TYPE_FULL_REPAYMENT) {
      const { repayment: _, ...payload } = this.storedRequest;
      return this.mortgageApi.HTTP_X_CalculateInterestRate_ForFullRepayment({ ...payload });
    } else if (repayment === ENUM_MortgageRepaymentType.MORTGAGE_REPAYMENT_TYPE_INTEREST_ONLY) {
      const { repayment: _, termInMonths: __, ...payload } = this.storedRequest;
      return this.mortgageApi.HTTP_X_CalculateInterestRate_ForInterestOnlyRepayment({ ...payload });
    } else {
      return throwError(() => new Error('No repayment type selected'));
    }
  }
}
