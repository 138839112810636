import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store } from '@ngrx/store';
import { AuthService } from '@shared/services/auth.service';
import { getClientUserSuccess } from '@shared/store/client-user/client-user.actions';
import { selectClientUserId } from '@shared/store/client-user/client-user.selectors';
import { filter, tap, withLatestFrom } from 'rxjs/operators';
import { companyIdentifier } from '../../common';
import { AppState } from '../../models/app-state.model';

@Injectable()
export class FullstoryEffect {
  identifyUser = createEffect(
    () =>
      this.actions.pipe(
        ofType(getClientUserSuccess),
        filter(() => !!window.FS && !!window.FS.identify),
        withLatestFrom(this.store.select(selectClientUserId)),
        filter(([action, userId]) => action.clientUser && !!userId),
        tap(([action, userId]) => {
          window.FS.identify(userId, {
            displayName: `${action.clientUser.firstName} ${action.clientUser.lastName}`,
            email: action.clientUser.email,
            companyIdentifier: companyIdentifier(action.clientUser.companyId, this.authService.isImpersonated),
          });
        }),
      ),
    { dispatch: false },
  );

  constructor(
    private readonly actions: Actions,
    private readonly store: Store<AppState>,
    private readonly authService: AuthService,
  ) {}
}
