import { HTTP_CP_MortgageCase } from '@api-new/caseservice';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  closeMortgageCase,
  closeMortgageCasesSuccess,
  getMortgageCases,
  getMortgageCasesFailure,
  getMortgageCasesSuccess,
} from '@shared/store/mortgage-cases/mortgage-cases.actions';
import { MortgageCasesState } from '@shared/store/mortgage-cases/mortgage-cases.state';

export const getMortgageCaseById = (mortgageCase: Pick<HTTP_CP_MortgageCase, 'id'>): string => mortgageCase.id;

export const mortgageCasesAdapter: EntityAdapter<HTTP_CP_MortgageCase> = createEntityAdapter<HTTP_CP_MortgageCase>({
  selectId: getMortgageCaseById,
});

export const mortgageCasesInitialState: MortgageCasesState = mortgageCasesAdapter.getInitialState({
  loading: true,
  errors: null,
});

const reducer = createReducer(
  mortgageCasesInitialState,

  on(getMortgageCases, (state) => ({
    ...state,
    loading: true,
  })),

  on(getMortgageCasesSuccess, (state, { mortgageCases }) =>
    mortgageCasesAdapter.setAll(mortgageCases ? mortgageCases : [], {
      ...state,
      loading: false,
      errors: null,
    }),
  ),

  on(getMortgageCasesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),

  on(closeMortgageCasesSuccess, (state, { mortgageCaseId }) => {
    return mortgageCasesAdapter.removeOne(mortgageCaseId, {
      ...state,
      loading: false,
    });
  }),
  on(closeMortgageCase, (state) => ({
    ...state,
    loading: true,
  })),
);

export function mortgageCasesReducer(state: MortgageCasesState = mortgageCasesInitialState, action: Action): MortgageCasesState {
  return reducer(state, action);
}
