import { HTTP_CP_SmartSearch } from '@api-new/mortgageservice';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  addSmartSearch,
  getSmartSearches,
  getSmartSearchesFailure,
  getSmartSearchesSuccess,
  updateSmartSearch,
} from '@shared/store/smart-searches/smart-searches.actions';
import { SmartSearchesState } from '@shared/store/smart-searches/smart-searches.state';

export const getSmartSearchById = (smartSearch: Pick<HTTP_CP_SmartSearch, 'id'>): string => smartSearch.id;

export const smartSearchesAdapter: EntityAdapter<HTTP_CP_SmartSearch> = createEntityAdapter<HTTP_CP_SmartSearch>({
  selectId: getSmartSearchById,
});

export const smartSearchesInitialState: SmartSearchesState = smartSearchesAdapter.getInitialState({
  loading: true,
  pagination: { pageSize: 5, pageNumber: 1, totalCount: null, totalPages: null },
  errors: null,
});

const reducer = createReducer(
  smartSearchesInitialState,

  on(getSmartSearches, (state) => ({
    ...state,
    loading: true,
  })),

  on(getSmartSearchesSuccess, (state, smartSearchesResponse) =>
    smartSearchesAdapter.setAll(smartSearchesResponse.smartSearches ? smartSearchesResponse.smartSearches : [], {
      ...state,
      loading: false,
      errors: null,
    }),
  ),

  on(getSmartSearchesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),

  on(addSmartSearch, (state, { smartSearch }) => smartSearchesAdapter.addOne(smartSearch, state)),

  on(updateSmartSearch, (state, { smartSearch }) => smartSearchesAdapter.updateOne({ id: smartSearch.id, changes: smartSearch }, state)),
);

export function smartSearchesReducer(state: SmartSearchesState = smartSearchesInitialState, action: Action): SmartSearchesState {
  return reducer(state, action);
}
