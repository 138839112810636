import { ENUM_MortgageClosedReason, ENUM_MortgageType } from '@api-new/common';
import { HTTP_CP_Lender, HTTP_CP_MortgageComparisonSettings } from '@api-new/mortgageservice';
import { Moment } from 'moment';
import { BaseModel } from './base.model';
import { MortgagePartStepModel } from './mortgage-part-step.model';
import { MortgagePartModel } from './mortgage-part.model';

export type MortgageDetailGraphType = 'Monthly payment' | 'Outstanding balance' | 'Property value' | '';

export interface MortgageModel extends BaseModel {
  closedReason?: ENUM_MortgageClosedReason;
  closingDate?: string | Moment;
  mortgageType?: ENUM_MortgageType; // readonly
  accountNumber?: string;
  paymentDay?: number;
  defaqtoProductId?: string;
  lenderId?: string;
  propertyId?: string;
  clientUserId?: string;
  svrId?: string;
  mortgageParts?: MortgagePartModel[];
  ltvRatio?: number;
  term?: number; // in months
  termRemaining?: number; // in months
  startDate?: string | Moment;
  endDate?: string | Moment;
  currentMonthlyPayment?: number;
  roughCurrentMonthlyPayment?: boolean;
  roundedCurrentOutstandingBalance?: number;
  outstandingBalanceDate?: string | Moment;
  totalCosts?: number;
  steps?: number[]; // this is array of exactly 17 payments where current payment is on index 0
  currentCosts?: number;
  mortgageComparisonSettings?: HTTP_CP_MortgageComparisonSettings;
  repaymentCharge?: number;
}

export interface MortgageClosingModel {
  id: string;
  reason: ENUM_MortgageClosedReason;
  when?: Moment | string;
}

export interface AccountsMetadata {
  dealHash?: string;
  detailType: string;
  detailId: string;
  editing?: boolean;
  isLiveSearch?: boolean;
  productSearchLiveResults?: boolean;
}

// FE only

export interface MortgageCompareHeaderData {
  remortgageInFuture: boolean;
  remortgageDate?: Moment;
  currentMonthlyPayments?: number;
  newMonthlyPayments?: number;
  paymentsDifference?: number;
  newOutstandingBalance?: number;
  timeLeft?: string;
}

export interface MortgageHeaderGraphData {
  label: MortgageDetailGraphType;
  value: number;
  data: any[];
  labels: string[];
}

export enum MortgageEditMenuEnum {
  MORTGAGE_SUMMARY = 0,
  OVERPAYMENTS,
  UNDERPAYMENTS,
  MORTGAGE_PART,
}

export interface MortgageEditTabModel {
  index: number;
  label: string;
  type: MortgageEditMenuEnum;
  partIndex?: number;
}

export interface MortgageEditMetadata {
  selectedTab: MortgageEditTabModel;
  addingPart: boolean;
  ratePayments: number[][];
  lender: HTTP_CP_Lender;
}

export interface MortgageEditRateModel extends MortgagePartStepModel {
  termMonths?: number;
  intervalString?: string;
}

export interface MortgageEditPartModel extends MortgagePartModel {
  mortgagePartSteps: MortgageEditRateModel[];
}
