<div class="forms">
  <div class="form-container">
    <div class="landing-logo-container">
      <a href="https://www.dashly.com/" target="_blank">
        @if (isMabDomain) {
          <img
            class="landing-logo mab"
            src="https://storage.googleapis.com/dashlyprod-company-logos/154/logo-1702149160.png" />
        } @else {
          <img class="landing-logo" src="assets/images/mortgage-monitor-blue.png" />
        }
      </a>
      <span [routerLink]="'/auth/login'" class="forgot-pass">Go back</span>
    </div>
    <form [formGroup]="verificationCodeForm" class="login-form">
      @if (authError$ | async) {
        @switch (lastSignInType$ | async) {
          @case (SignInType.VERIFICATION_CODE) {
            @if ((authError$ | async) === ApiErrorCode.SIGN_IN_CODE_EXPIRED) {
              <span class="landing-title"> The code you provided is expired! </span>
              <span class="landing-subtitle">
                Please click the resend button to get a new verification code sent to
                <span class="bold">{{ email }}.</span>
              </span>
            }
            @if ((authError$ | async) === ApiErrorCode.SIGN_IN_CODE_INVALID) {
              <span class="landing-title"> The code you provided is not valid! </span>
              <span class="landing-subtitle">
                Please click the resend button to get a new verification code sent to
                <span class="bold">{{ email }}.</span>
              </span>
            }
            @if (
              (authError$ | async) !== ApiErrorCode.SIGN_IN_CODE_INVALID &&
              (authError$ | async) !== ApiErrorCode.SIGN_IN_CODE_EXPIRED
            ) {
              <span class="landing-title"> Unexpected error occured! </span>
              <span class="landing-subtitle">
                We are really sorry about this! Please try again later, or contact customer support.
              </span>
            }
          }
          @case (SignInType.SHORT_MAGIC_LINK) {
            @if ((authError$ | async) === ApiErrorCode.SIGN_IN_CODE_EXPIRED) {
              <span class="landing-title"> The link you followed is expired! </span>
              <span class="landing-subtitle">
                Please click the resend button to get a new verification code sent to
                <span class="bold">{{ email }}.</span>
              </span>
            }
            @if ((authError$ | async) === ApiErrorCode.SIGN_IN_CODE_INVALID) {
              <span class="landing-title"> The link you followed is not valid! </span>
              <span class="landing-subtitle">
                Please click the resend button to get a new verification code sent to
                <span class="bold">{{ email }}.</span>
              </span>
            }
            @if (
              (authError$ | async) !== ApiErrorCode.SIGN_IN_CODE_INVALID &&
              (authError$ | async) !== ApiErrorCode.SIGN_IN_CODE_EXPIRED
            ) {
              <span class="landing-title"> Unexpected error occured! </span>
              <span class="landing-subtitle">
                We are really sorry about this! Please try again later, or contact customer support.
              </span>
            }
          }
          @case (SignInType.LONG_MAGIC_LINK) {
            @if ((authError$ | async) === ApiErrorCode.SIGN_IN_CODE_EXPIRED) {
              <span class="landing-title"> The link you followed is expired! </span>
              <span class="landing-subtitle">
                Please click the resend button to get a new verification code sent to
                <span class="bold">{{ email }}.</span>
              </span>
            }
            @if (
              (authError$ | async) === ApiErrorCode.SIGN_IN_CODE_INVALID ||
              (authError$ | async) === ApiErrorCode.SIGN_IN_CREDENTIALS_INVALID
            ) {
              <span class="landing-title"> The link you followed is not valid! </span>
              <span class="landing-subtitle">
                Please click the resend button to get a new verification code sent to
                <span class="bold">{{ email }}.</span>
              </span>
            }
            @if (
              (authError$ | async) !== ApiErrorCode.SIGN_IN_CODE_INVALID &&
              (authError$ | async) !== ApiErrorCode.SIGN_IN_CODE_EXPIRED &&
              (authError$ | async) !== ApiErrorCode.SIGN_IN_CREDENTIALS_INVALID
            ) {
              <span class="landing-title"> Unexpected error occured! </span>
              <span class="landing-subtitle">
                We are really sorry about this! Please try again later, or contact customer support.
              </span>
            }
          }
          @default {
            <span class="landing-title">Unexpected error occured!</span>
          }
        }
      } @else {
        <span class="landing-title"> Enter your <span class="bold">verification code</span> </span>
        @if ((isSendingVerificationCode$ | async) === false) {
          <span class="landing-subtitle">
            A 6-digit code has been sent to <span class="bold">{{ email }},</span> please enter this
            below.
          </span>
        } @else {
          <span class="landing-subtitle">
            Sending verification code to <span class="bold">{{ email }}</span
            >...
          </span>
        }
      }

      <div class="inputs">
        <div class="landing-responsive-row left-0">
          <cp-verification-input
            (clearedFields)="clearLastSentVerificationCode()"
            [formControl]="verificationCodeForm.controls.verificationCode"
            [warnState]="
              ((isInvalidCodeError$ | async) || (isExpiredCodeError$ | async)) &&
              !((lastSignInType$ | async) === SignInType.SHORT_MAGIC_LINK)
            "
            [verificationCodeLength]="6" />
        </div>
        @if (!((lastSignInType$ | async) === SignInType.SHORT_MAGIC_LINK)) {
          <div class="dashly-warn-text-container">
            @if (isInvalidCodeError$ | async) {
              <span class="dashly-warn-text">You have entered an invalid code or link.</span>
            }
            @if (isExpiredCodeError$ | async) {
              <span class="dashly-warn-text">You have entered an expired code or link.</span>
            }
            @if ((isExpiredCodeError$ | async) && (isSendingVerificationCode$ | async)) {
              <span class="dashly-warn-text">We are sending you a new one to your e-mail.</span>
            }
          </div>
        }

        <div class="login-button-container two-buttons mt-2">
          <app-arrow-button
            (click)="resendCode()"
            [disabled]="timeLeftTillResendEnabled !== 0"
            [loading]="isSendingVerificationCode$ | async"
            variant="reset">
            Resend
          </app-arrow-button>
          <app-arrow-button
            (click)="verifyCode()"
            [disabled]="verificationCodeForm.invalid || !!(authError$ | async)"
            [loading]="loading$ | async">
            Verify
          </app-arrow-button>
        </div>

        <div class="inputs-row divider">
          @if (timeLeftTillResendEnabled) {
            <span class="text-under-buttons">
              Didn't get one? You can resend the code in
              <span class="bold">{{ timeLeftTillResendEnabled }} seconds.</span></span
            >
          } @else {
            <span class="text-under-buttons">Didn't get one? You can resend the code.</span>
          }
        </div>
      </div>
    </form>
  </div>
  <div class="footer">
    <span>Need an account?&nbsp;</span>
    <a [routerLink]="'/auth/register'" class="form-change-button">Create an account</a>
  </div>
</div>
