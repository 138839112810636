<div class="input-container" [ngClass]="{ disabled: disabled, warn: warnState }">
  @for (input of verificationCodeLengthArray; track input) {
    <input
      class="input-element verification-input"
      name="code"
      type="number"
      (click)="clearFieldIfFilled(input)"
      [id]="input"
      [disabled]="disabled"
      (focus)="onFocus()"
      (blur)="onBlur()"
      (keydown)="filterInput($event)" />
  }
</div>
