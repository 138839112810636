export abstract class Environment {
  abstract readonly production: boolean;
  abstract readonly urls: {
    readonly apiUrl: string;
  };
}

export const mockEnvironment: Environment = {
  production: false,
  urls: {
    apiUrl: '',
  },
};
