import { HTTP_CP_Property } from '@api-new/propertyservice';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  addProperty,
  addPropertyFailure,
  addPropertySuccess,
  closePropertySuccess,
  closePropertyWithMortgageSuccess,
  deletePropertyValueSuccess,
  getProperties,
  getPropertiesFailure,
  getPropertiesSuccess,
  updateProperty,
  updatePropertyFailure,
  updatePropertySuccess,
  updatePropertyValuation,
  updatePropertyValuationFailure,
  updatePropertyValuationSuccess,
} from '@shared/store/properties/properties.actions';
import { PropertiesState } from '@shared/store/properties/properties.state';

export const getPropertyById = (property: Pick<HTTP_CP_Property, 'id'>): string => property.id;

export const propertiesAdapter: EntityAdapter<HTTP_CP_Property> = createEntityAdapter<HTTP_CP_Property>({
  selectId: getPropertyById,
});

export const propertiesInitialState: PropertiesState = propertiesAdapter.getInitialState({
  loading: true,
  errors: null,
  updating: false,
});

const reducer = createReducer(
  propertiesInitialState,

  on(getProperties, (state) => ({
    ...state,
    loading: true,
  })),

  on(getPropertiesSuccess, (state, { properties }) =>
    propertiesAdapter.setAll(properties ? properties : [], {
      ...state,
      loading: false,
      errors: null,
    }),
  ),

  on(getPropertiesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),

  on(addProperty, (state) => ({ ...state, loading: true })),

  on(addPropertySuccess, (state, { property }) => propertiesAdapter.upsertOne(property, { ...state, loading: false })),

  on(addPropertyFailure, (state, { error }) => ({ ...state, loading: false, error })),

  on(updateProperty, (state) => ({ ...state, updating: true })),

  on(updatePropertySuccess, (state, { property }) => propertiesAdapter.upsertOne(property, { ...state, updating: true })),

  on(updatePropertyFailure, (state, { error }) => ({ ...state, updating: false, error })),

  on(updatePropertyValuation, (state) => ({ ...state, updating: true })),

  on(updatePropertyValuationSuccess, deletePropertyValueSuccess, (state, payload) => {
    const property: HTTP_CP_Property = {
      ...state.entities[payload.propertyId],
      roundedCurrentValue: payload.propertyValuation.currentPropertyValue,
      propertyValues: payload.propertyValuation.propertyValues,
    };
    return propertiesAdapter.upsertOne(property, { ...state, updating: false });
  }),

  on(updatePropertyValuationFailure, (state, { error }) => ({ ...state, updating: false, error })),

  on(closePropertySuccess, closePropertyWithMortgageSuccess, (state, { propertyId }) => {
    return propertiesAdapter.removeOne(propertyId, { ...state });
  }),
);

export function propertiesReducer(state: PropertiesState = propertiesInitialState, action: Action): PropertiesState {
  return reducer(state, action);
}
