import { Injectable } from '@angular/core';
import { CaseApiService } from '@api-new/caseservice';
import { ENUM_HelpCaseClosedReason } from '@api-new/common';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { AuthService } from '@shared/services/auth.service';
import { loadInitialPlatformData } from '@shared/store/common.actions';
import {
  closeHelpCase,
  closeHelpCaseFailure,
  closeHelpCaseSuccess,
  getHelpCases,
  getHelpCasesFailure,
  getHelpCasesSuccess,
} from '@shared/store/help-cases/help-cases.actions';
import '@shared/store/smart-search-cases/smart-search-cases.actions';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class HelpCasesEffects {
  handleLoadInitialPlatformData = createEffect(() => this.actions$.pipe(ofType(loadInitialPlatformData), map(getHelpCases)));

  getHelpCases = createEffect(() =>
    this.actions$.pipe(
      ofType(getHelpCases),
      switchMap(() => this.caseApiService.HTTP_CP_ListHelpCases()),
      map((response) => getHelpCasesSuccess(response)),
      catchError((error) => of(getHelpCasesFailure({ error }))),
    ),
  );

  closeHelpCase = createEffect(() =>
    this.actions$.pipe(
      ofType(closeHelpCase),
      switchMap(({ helpCaseId }) =>
        this.caseApiService
          .HTTP_CP_UpdateHelpCase({
            helpCaseId,
            updateStatusToClosed: { closedReason: ENUM_HelpCaseClosedReason.HELP_CASE_CLOSED_REASON_CANCELLED },
          })
          .pipe(
            map((response) => closeHelpCaseSuccess({ helpCaseId })), // Using helpCaseCaseId from the action
            catchError((error) => of(closeHelpCaseFailure({ error }))),
          ),
      ),
    ),
  );

  constructor(
    private readonly caseApiService: CaseApiService,
    private readonly actions$: Actions,
    private readonly authService: AuthService,
  ) {}
}
