import {
  HTTP_CP_CreateMortgage_Request,
  HTTP_CP_ListMortgages_Response,
  HTTP_CP_Mortgage,
  HTTP_CP_UpdateMortgage_Request,
} from '@api-new/mortgageservice';
import { ErrorModel } from '@app/OLD_shared/models/app-state.model';
import { createAction, props } from '@ngrx/store';

export const getMortgages = createAction('[MORTGAGES] Get Mortgages');
export const getMortgagesSuccess = createAction('[MORTGAGES] Get Mortgages Success', props<HTTP_CP_ListMortgages_Response>());
export const getMortgagesFailure = createAction('[MORTGAGES] Get Mortgages Failure', props<{ error: ErrorModel }>());

export const createMortgage = createAction('[MORTGAGES] Create Mortgage', props<{ mortgage: HTTP_CP_CreateMortgage_Request }>());
export const createMortgageSuccess = createAction('[MORTGAGES] Create Mortgage Success', props<{ mortgage: HTTP_CP_Mortgage }>());
export const createMortgageFailure = createAction('[MORTGAGES] Create Mortgage Failure', props<{ error: ErrorModel }>());

export const updateMortgage = createAction('[MORTGAGES] Update Mortgage', props<{ mortgage: HTTP_CP_UpdateMortgage_Request }>());
export const updateMortgageSuccess = createAction('[MORTGAGES] Update Mortgage Success', props<{ mortgage: HTTP_CP_Mortgage }>());
export const updateMortgageFailure = createAction('[MORTGAGES] Update Mortgage Failure', props<{ error: ErrorModel }>());

export const closeMortgage = createAction('[MORTGAGES] Close Mortgage', props<{ mortgageId: string; closeDated: Date }>());
export const closeMortgageSuccess = createAction('[MORTGAGES] Close Mortgage Success', props<{ mortgageId: string }>());
export const closeMortgageFailure = createAction('[MORTGAGES] Close Mortgage Failure', props<{ error: ErrorModel }>());
