import { NgClass } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  DestroyRef,
  EventEmitter,
  Input,
  Output,
  forwardRef,
  inject,
} from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { BehaviorSubject } from 'rxjs';
import { debounceTime } from 'rxjs/operators';

const INVALID_KEYS = ['e', 'E'];

@Component({
  selector: 'cp-verification-input',
  templateUrl: './verification-input.component.html',
  styleUrls: ['./verification-input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [NgClass],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => VerificationInputComponent),
      multi: true,
    },
  ],
})
export class VerificationInputComponent implements AfterViewInit {
  @Input() disabled = false;
  @Input() warnState = false;
  @Output() clearedFields = new EventEmitter();
  isFocused: boolean;

  @Input() set verificationCodeLength(verificationCodeLength: number) {
    this.verificationCodeLengthArray = Array.from(new Array(verificationCodeLength), (x, i) => i);
  }

  @Output() inputFocus = new EventEmitter<boolean>();
  @Output() inputValueChange = new EventEmitter<number>();

  private readonly changeDetectorRef = inject(ChangeDetectorRef);
  private readonly destroyRef = inject(DestroyRef);

  private _inputValue: string;

  verificationCodeLengthArray: number[];
  inputElements: HTMLInputElement[];
  valueChanged: BehaviorSubject<string> = new BehaviorSubject<string>(null);

  changeCallback: (value: any) => void = () => null;
  touchCallback: () => void = () => null;

  set inputValue(value: string) {
    this.inputElements?.forEach((element, index) => {
      if (this.inputValue?.length > index) {
        element.value = this.inputValue[index];
      }
    });
    this._inputValue = value;
  }

  get inputValue(): string {
    return this._inputValue;
  }

  ngAfterViewInit(): void {
    this.inputElements = Array.from(document.querySelectorAll('input.verification-input'));
    this.inputElements.forEach((element, idx) => {
      if (this.inputValue?.length > idx) {
        element.value = this.inputValue[idx];
      }
      this.moveCursorToBeginningOfInputOnFocus(element);
      this.moveBetweenBoxesOnInput(element, idx);
    });

    this.valueChanged.pipe(takeUntilDestroyed(this.destroyRef), debounceTime(100)).subscribe((value) => {
      this.changeCallback(value);
    });
  }

  moveCursorToBeginningOfInputOnFocus(element: HTMLInputElement): void {
    element.addEventListener('click', () => {
      element.type = 'text';
      element.setSelectionRange(0, 0);
      element.type = 'number';
      element.focus();
    });
  }

  moveBetweenBoxesOnInput(element: HTMLInputElement, index: number): void {
    element.addEventListener('beforeinput', (inputEvent: InputEvent) => {
      if (element.value !== '' && !inputEvent.inputType.startsWith('delete')) {
        element.value = '';
      }
    });

    element.addEventListener('input', (event: any) => {
      const [firstLetter, ...rest] = event.target.value;
      event.target.value = firstLetter ?? '';

      const lastInputBox = index === this.inputElements.length - 1;
      const firstInputBox = index === 0;
      const didInsertContent = firstLetter !== undefined;

      if (didInsertContent && !lastInputBox) {
        this.inputElements[index + 1].focus();
        if (rest.join('')?.length) {
          this.inputElements[index + 1].value = rest.join('');
          this.inputElements[index + 1].dispatchEvent(new InputEvent('input'));
        }
      } else if (!didInsertContent && !firstInputBox) {
        this.inputElements[index - 1].focus();
      }
      this.valueChanged.next(this.inputElements.map(({ value }) => value).join(''));
    });
  }

  focusInput(): void {
    this.changeDetectorRef.detectChanges();
  }

  onFocus(): void {
    this.inputFocus.emit(true);
    this.isFocused = true;
  }

  onBlur(): void {
    this.inputFocus.emit(false);
    this.touched();
  }

  filterInput(event: KeyboardEvent): boolean {
    if (this.inputValue && this.inputValue.length === 0 && event.key === ' ') {
      return false;
    }
    return !INVALID_KEYS.find((key) => key === event.key);
  }

  writeValue(value: any): void {
    this.changeCallback(this.inputValue);
    this.changeDetectorRef.markForCheck();
    this.inputValue = value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.changeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.touchCallback = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  touched(): void {
    if (this.touchCallback) {
      this.touchCallback();
    }
  }

  clearFieldIfFilled(input: number): void {
    if (this.inputElements.map((e) => e.value).join('').length === 6) {
      this.inputElements.forEach((element, idx) => {
        element.value = null;
      });
      this._inputValue = null;
    }
  }
}
