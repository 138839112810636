import { HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable()
export class DashlyErrorInterceptor implements HttpInterceptor {
  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(
        () => {
          /** nothing **/
        },
        (error: HttpErrorResponse) => {
          if (window.location.origin.includes('localhost')) {
            return;
          }
          if (error) {
            Sentry.captureMessage(error.message);
          }
        },
      ),
    );
  }
}
