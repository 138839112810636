import { Injectable } from '@angular/core';
import { MortgageApiService } from '@api-new/mortgageservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadInitialPlatformData } from '@shared/store/common.actions';
import { getLenders, getLendersFailure, getLendersSuccess } from '@shared/store/lender/lenders.actions';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class LendersEffects {
  handleLoadInitialPlatformData = createEffect(() => this.actions$.pipe(ofType(loadInitialPlatformData), map(getLenders)));

  getLenders = createEffect(() =>
    this.actions$.pipe(
      ofType(getLenders),
      switchMap(() => this.mortgageApiService.HTTP_X_ListLenders(null)),
      map((lenders) => getLendersSuccess(lenders)),
      catchError((error) => of(getLendersFailure({ error }))),
    ),
  );

  constructor(
    private readonly mortgageApiService: MortgageApiService,
    private readonly actions$: Actions,
  ) {}
}
