// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_HelpCaseClosedReason.proto

export enum ENUM_HelpCaseClosedReason {
  HELP_CASE_CLOSED_REASON_UNSPECIFIED = 'HELP_CASE_CLOSED_REASON_UNSPECIFIED',
  /** HELP_CASE_CLOSED_REASON_COMPLETED - TODO CLOSING_REFACTOR migrate */
  HELP_CASE_CLOSED_REASON_COMPLETED = 'HELP_CASE_CLOSED_REASON_COMPLETED',
  /** HELP_CASE_CLOSED_REASON_CANCELLED - NEW */
  HELP_CASE_CLOSED_REASON_CANCELLED = 'HELP_CASE_CLOSED_REASON_CANCELLED',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
