import { HTTP_CP_SignIn_WithCode_Response } from '@api-new/authservice';
import { HTTP_CP_DoesEmailExist_Response } from '@api-new/userservice';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../../OLD_shared/models/app-state.model';
import { ApiErrorCode } from '../../../auth/enums/api-error-code.enum';

export const loginWithTokenAction = createAction('[AUTH] Login With Token', props<{ token: string; redirectUrl?: string }>());
export const loginSuccessAction = createAction(
  '[AUTH] Login Success',
  props<{
    signInResponse: HTTP_CP_SignIn_WithCode_Response;
  }>(),
);

export const clearLastSignInCode = createAction('[AUTH] Clear last sign in code');

export const passwordResetAction = createAction('[AUTH] Password Reset', props<{ email: string }>());

export const passwordResetSuccessAction = createAction('[AUTH] Password Reset Success');

export const passwordResetFailureAction = createAction('[AUTH] Password Reset Failure', props<{ error: ErrorModel }>());

export const resetPasswordResetDoneAction = createAction('[AUTH] Reset Password Reset Done');

export const logoutAndReloadAction = createAction('[AUTH] Logout And Reload');

export const resetStoreAction = createAction('[AUTH] Reset Store');

export const authErrorAction = createAction('[AUTH] Auth Error', props<{ authErrorCode: ApiErrorCode }>());

export const sendVerificationCodeAction = createAction('[AUTH] Send verification code to a mail', props<{ email: string }>());

export const sendVerificationCodeSuccessAction = createAction('[AUTH] Send verification code to a mail success');

export const sendVerificationCodeErrorAction = createAction(
  '[AUTH] Send verification code to a mail error',
  props<{ error: ErrorModel }>(),
);

export const checkLoginMailAction = createAction('[AUTH] Check user mail before login', props<{ email: string }>());

export const checkLoginMailActionSuccess = createAction(
  '[AUTH] Check user mail before login success',
  props<HTTP_CP_DoesEmailExist_Response>(),
);

export const checkLoginMailActionFailure = createAction('[AUTH] Check user mail before login error', props<{ error: ErrorModel }>());

export const loginAction = createAction('[AUTH] Login', props<{ email: string; verificationCode: string; redirectUrl?: string }>());

export const loginActionSuccess = createAction(
  '[AUTH] Login Success',
  props<{
    signInResponse: HTTP_CP_SignIn_WithCode_Response;
    link?: string;
  }>(),
);

export const loginActionFailure = createAction('[AUTH] Login error', props<{ error: ErrorModel; email: string }>());

export const loginMagicLinkAction = createAction(
  '[AUTH] Login MagicLink',
  props<{ email: string; verificationCode: string; redirectUrl?: string }>(),
);

export const loginMagicLinkActionSuccess = createAction(
  '[AUTH] Login MagicLink Success',
  props<{
    signInResponse: HTTP_CP_SignIn_WithCode_Response;
    link?: string;
  }>(),
);

export const loginMagicLinkActionFailure = createAction(
  '[AUTH] Login MagicLink error',
  props<{ error: ErrorModel; email: string; code: string }>(),
);

export const agreeToTermsAndConditions = createAction('[AUTH] Agree to terms and conditions');

export const agreeToTermsAndConditionsSuccess = createAction('[AUTH] Agree to terms and conditions success');

export const agreeToTermsAndConditionsFailure = createAction('[AUTH] Agree to terms and conditions error', props<{ error: ErrorModel }>());

export const askForTermsAndConditionsAction = createAction('[AUTH] askForTermsAndConditionsAction');

export const navigateToVerifyCodeAndSendEmailAction = createAction('[AUTH] navigateToVerifyCodeAndSendEmailAction');

export const navigateToVerifyCodeAction = createAction('[AUTH] navigateToVerifyCodeAction');

export const setRedirectUrlAfterLoginAction = createAction('[AUTH] Set Redirect Link After Login', props<{ redirectUrl: string }>());
