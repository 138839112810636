import { HTTP_X_ListLenders_Response_Lender } from '@api-new/mortgageservice';
import { ErrorModel, LenderState } from '../../models/app-state.model';
import {
  GetLenderAdditionalsFailureAction,
  GetLenderAdditionalsSuccessAction,
  GetLendersAction,
  GetLendersFailureAction,
  GetLendersSuccessAction,
  LenderAction,
} from '../actions/lender.action';
import { PayloadAction } from '../store-utils';

const initialState: LenderState = {
  entities: {},
  errors: null,
  loading: false,
};

export function lenderReducer(state: LenderState = initialState, action: LenderAction): LenderState {
  switch (action.type) {
    case GetLendersAction.type:
      return {
        ...state,
        loading: !state.loaded,
      };

    case GetLenderAdditionalsFailureAction.type:
      return {
        ...state,
        errors: (action as PayloadAction<ErrorModel>).payload,
      };

    case GetLendersFailureAction.type:
      return {
        ...state,
        loading: false,
        errors: (action as PayloadAction<ErrorModel>).payload,
      };

    case GetLendersSuccessAction.type: {
      const lenders: HTTP_X_ListLenders_Response_Lender[] = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          ...(lenders || []).reduce(
            (acc: { [id: string]: HTTP_X_ListLenders_Response_Lender }, lender) => ({
              [lender.id]: lender,
              ...acc,
            }),
            {},
          ),
        },
        loading: false,
        loaded: lenders != null && lenders.length > 0,
      };
    }

    case GetLenderAdditionalsSuccessAction.type: {
      const lender: HTTP_X_ListLenders_Response_Lender = action.payload;
      if (lender == null || lender.id == null) {
        return state;
      }
      const entities = {
        ...state.entities,
        [lender.id]: {
          ...state.entities[lender.id],
          ...lender,
        },
      };

      return {
        ...state,
        entities,
      };
    }

    default:
      return state;
  }
}
