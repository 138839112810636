<div
  class="input-container"
  [ngClass]="{
    disabled: disabled,
    warn: warnState,
    dark: variant === 'dark',
    stripped: variant === 'stripped',
    'mobile-settings': variant === 'mobile-settings',
    filter: variant === 'filter',
  }">
  @if (label !== null) {
    <div class="input-label">
      <label [for]="inputId">{{ label }}</label>
      @if (tooltipId) {
        <img
          [id]="tooltipId"
          cdk-overlay-origin
          #tooltipOrigin="cdkOverlayOrigin"
          src="assets/icons/circle-info-icon.svg"
          class="dashly-info-icon" />
      }
    </div>
  }

  <input
    #inputElement
    [id]="inputId"
    class="input-element"
    [ngClass]="{
      'input-prefix': prefix || type === 'currency',
      'input-suffix': suffix || type === 'percent',
      'fs-block': fsBlock,
    }"
    [type]="
      type === 'currency' || (sensitive && sensitiveShown)
        ? 'text'
        : type === 'percent'
          ? 'number'
          : type
    "
    [placeholder]="placeholder"
    [min]="min"
    [max]="max"
    [pattern]="pattern"
    [disabled]="disabled"
    [name]="name"
    [(ngModel)]="value"
    (paste)="onPaste($event)"
    (click)="onClick()"
    (focus)="onFocus()"
    (blur)="onBlur()"
    (keydown)="filterInput($event)"
    (keydown.enter)="enterSubmit.emit()" />
  <!--Prefix-->
  @if (prefix) {
    <span class="prefix">{{ prefix }}</span>
  }
  @if (!prefix && type === "currency") {
    <span class="prefix">£</span>
  }
  <!--Sensitive info toggle-->
  @if (sensitive) {
    <div (click)="changeVisibility()" class="input-sensitive-container">
      @if (sensitiveShown) {
        <img class="input-sensitive-icon" src="assets/icons/eye.svg" />
      }
      @if (!sensitiveShown) {
        <img class="input-sensitive-icon" src="assets/icons/eye-mute.svg" />
      }
    </div>
  }
  <!--Suffix-->
  @if (suffix) {
    <span class="suffix">{{ suffix }}</span>
  }
  @if (!suffix && type === "percent") {
    <span class="suffix">%</span>
  }
</div>
