import { Action, createReducer, on } from '@ngrx/store';
import {
  betterDealNotFound,
  clearSelectedBetterDeal,
  getBetterDeal,
  getBetterDealFailure,
  getBetterDeals,
  getBetterDealsFailure,
  getBetterDealsSuccess,
  setSelectedBetterDeal,
} from '@shared/store/better-deal/better-deal.actions';
import { BetterDealsState } from '@shared/store/better-deal/better-deal.state';

const initialState: BetterDealsState = {
  entities: [],
  selectedBetterDeal: null,
  betterDealNotFound: false,
  loading: false,
  errors: null,
  selectedBetterDealIndex: 0,
};

const reducer = createReducer(
  initialState,

  on(getBetterDeals, (state) => ({
    ...state,
    loading: true,
  })),
  on(getBetterDeal, (state) => ({
    ...state,
    loading: true,
  })),

  on(getBetterDealsSuccess, (state, { mortgageId, mortgageDeals }) => ({
    ...state,
    entities: {
      ...state.entities,
      [mortgageId]: mortgageDeals ? mortgageDeals : [],
    },
    loading: false,
  })),

  on(getBetterDealsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),

  on(getBetterDealFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),
  on(setSelectedBetterDeal, (state, { deal, selectedBetterDealIndex }) => ({
    ...state,
    selectedBetterDeal: deal,
    betterDealNotFound: false,
    loading: false,
    selectedBetterDealIndex,
  })),
  on(clearSelectedBetterDeal, (state) => ({
    ...state,
    selectedBetterDeal: null,
    betterDealNotFound: false,
  })),
  on(betterDealNotFound, (state) => ({
    ...state,
    selectedBetterDeal: null,
    betterDealNotFound: true,
  })),
);

export function betterDealsReducer(state: BetterDealsState = initialState, action: Action): BetterDealsState {
  return reducer(state, action);
}
