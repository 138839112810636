import { Action, createReducer, on } from '@ngrx/store';
import { CompanyState } from '../../models/app-state.model';
import { getCompanyByIDAction, getCompanyFailureAction, getCompanySuccessAction } from './company.action';

export const companyInitialState: CompanyState = {
  loading: false,
  entity: null,
  errors: null,
};

const reducer = createReducer(
  companyInitialState,

  on(getCompanyByIDAction, (state) => ({
    ...state,
    loading: false,
  })),

  on(getCompanySuccessAction, (state, { company }) => ({
    ...state,
    loading: false,
    loaded: true,
    entity: {
      ...state.entity,
      company,
    },
  })),

  on(getCompanyFailureAction, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),
);

export function companyReducer(state: CompanyState = companyInitialState, action: Action): CompanyState {
  return reducer(state, action);
}
