import {
  HTTP_CP_Mortgage,
  HTTP_CP_MortgageComparisonSettings,
  HTTP_CP_SmartSearch,
  HTTP_CP_UpdateMortgageComparisonSettings_Request,
  HTTP_CP_UpdateMortgage_Request,
} from '@api-new/mortgageservice';
import { Action } from '@ngrx/store';
import { ErrorModel } from '../../models/app-state.model';
import { CreateMortgageRequest } from '../../models/create-mortgage-request.model';
import { AccountsMetadata, MortgageClosingModel, MortgageModel } from '../../models/mortgage.model';
import { PayloadAction } from '../store-utils';

export class GetMortgagesAction implements Action {
  static readonly type = 'GetMortgagesAction';
  readonly type = GetMortgagesAction.type;
}
export class GetClientUserDataAction implements Action {
  static readonly type = 'getClientUserDataAction';
  readonly type = GetClientUserDataAction.type;
}

export class GetClientUserDataSuccessAction extends PayloadAction<HTTP_CP_SmartSearch[]> {
  static readonly type = 'GetClientUserDataSuccessAction';
  readonly type = GetClientUserDataSuccessAction.type;
}

export class GetClientUserDataFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'GetClientUserDataFailureAction';
  readonly type = GetClientUserDataFailureAction.type;
}

export class GetMortgagesSuccessAction extends PayloadAction<HTTP_CP_Mortgage[]> {
  static readonly type = 'GetMortgagesSuccessAction';
  readonly type = GetMortgagesSuccessAction.type;
}

export class GetMortgagesFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'GetMortgagesFailureAction';
  readonly type = GetMortgagesFailureAction.type;
}

export class GetSingleMortgageSuccessAction extends PayloadAction<MortgageModel> {
  static readonly type = 'GetSingleMortgageSuccessAction';
  readonly type = GetSingleMortgageSuccessAction.type;
}

export class GetSingleMortgageFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'GetSingleMortgageFailureAction';
  readonly type = GetSingleMortgageFailureAction.type;
}

export class CreateMortgageAction extends PayloadAction<CreateMortgageRequest> {
  static readonly type = 'CreateMortgageAction';
  readonly type = CreateMortgageAction.type;
}

export class CreateMortgageSuccessAction extends PayloadAction<MortgageModel> {
  static readonly type = 'CreateMortgageSuccessAction';
  readonly type = CreateMortgageSuccessAction.type;
}

export class CreateMortgageFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'CreateMortgageFailureAction';
  readonly type = CreateMortgageFailureAction.type;
}

export class UpdateMortgageAction extends PayloadAction<HTTP_CP_UpdateMortgage_Request> {
  static readonly type = 'UpdateMortgageAction';
  readonly type = UpdateMortgageAction.type;
}

export class UpdateMortgageSuccessAction extends PayloadAction<MortgageModel> {
  static readonly type = 'UpdateMortgageSuccessAction';
  readonly type = UpdateMortgageSuccessAction.type;
}

export class UpdateMortgageFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'UpdateMortgageFailureAction';
  readonly type = UpdateMortgageFailureAction.type;
}

export class CloseMortgageAction extends PayloadAction<MortgageClosingModel> {
  static readonly type = 'CloseMortgageAction';
  readonly type = CloseMortgageAction.type;
}

export class CloseMortgageSuccessAction extends PayloadAction<MortgageClosingModel> {
  static readonly type = 'CloseMortgageSuccessAction';
  readonly type = CloseMortgageSuccessAction.type;
}

export class CloseMortgageFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'CloseMortgageFailureAction';
  readonly type = CloseMortgageFailureAction.type;
}

export class UpdateSearchCriteriaAction extends PayloadAction<HTTP_CP_UpdateMortgageComparisonSettings_Request> {
  static readonly type = 'UpdateSearchCriteriaAction';
  readonly type = UpdateSearchCriteriaAction.type;
}

export class UpdateSearchCriteriaSuccessAction extends PayloadAction<HTTP_CP_MortgageComparisonSettings> {
  static readonly type = 'UpdateSearchCriteriaSuccessAction';
  readonly type = UpdateSearchCriteriaSuccessAction.type;
}

export class UpdateSearchCriteriaFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'UpdateSearchCriteriaFailureAction';
  readonly type = UpdateSearchCriteriaFailureAction.type;
}

export class UpdateSearchCriteriaSelectedIndexAction extends PayloadAction<number> {
  static readonly type = 'UpdateSearchCriteriaSelectedIndexAction';
  readonly type = UpdateSearchCriteriaSelectedIndexAction.type;
}

export class IncrementSearchCriteriaSelectedIndexAction extends PayloadAction<boolean> {
  static readonly type = 'IncrementSearchCriteriaSelectedIndexAction';
  readonly type = IncrementSearchCriteriaSelectedIndexAction.type;
}

export class UpdateSearchCriteriaMetadataAction implements Action {
  static readonly type = 'UpdateSearchCriteriaMetadataAction';
  readonly type = UpdateSearchCriteriaMetadataAction.type;
}

export class SetMortgageWaitingForClose extends PayloadAction<MortgageClosingModel> {
  static readonly type = 'SetMortgageWaitingForClose';
  readonly type = SetMortgageWaitingForClose.type;
}

export class SetMortgageWaitingForChange extends PayloadAction<MortgageModel> {
  static readonly type = 'SetMortgageWaitingForChange';
  readonly type = SetMortgageWaitingForChange.type;
}

export class ClearMortgageWaitingForClose implements Action {
  static readonly type = 'ClearMortgageWaitingForClose';
  readonly type = ClearMortgageWaitingForClose.type;
}

export class ClearMortgageWaitingForChange implements Action {
  static readonly type = 'ClearMortgageWaitingForChange';
  readonly type = ClearMortgageWaitingForChange.type;
}

export class UpdateAccountsMetadata extends PayloadAction<AccountsMetadata> {
  static readonly type = 'UpdateAccountsMetadata';
  readonly type = UpdateAccountsMetadata.type;
}

export class RefreshSearchAction extends PayloadAction<string> {
  static readonly type = 'RefreshSearchAction';
  readonly type = RefreshSearchAction.type;
}

export type MortgageAction =
  | GetMortgagesAction
  | GetMortgagesSuccessAction
  | GetMortgagesFailureAction
  | GetClientUserDataAction
  | GetClientUserDataSuccessAction
  | GetClientUserDataFailureAction
  | GetSingleMortgageSuccessAction
  | GetSingleMortgageFailureAction
  | CreateMortgageAction
  | CreateMortgageSuccessAction
  | CreateMortgageFailureAction
  | UpdateMortgageAction
  | UpdateMortgageSuccessAction
  | UpdateMortgageFailureAction
  | CloseMortgageAction
  | CloseMortgageSuccessAction
  | CloseMortgageFailureAction
  | UpdateSearchCriteriaAction
  | UpdateSearchCriteriaSuccessAction
  | UpdateSearchCriteriaFailureAction
  | UpdateSearchCriteriaSelectedIndexAction
  | IncrementSearchCriteriaSelectedIndexAction
  | UpdateSearchCriteriaMetadataAction
  | SetMortgageWaitingForClose
  | SetMortgageWaitingForChange
  | ClearMortgageWaitingForClose
  | ClearMortgageWaitingForChange
  | UpdateAccountsMetadata
  | RefreshSearchAction;
