import { Injectable } from '@angular/core';
import { CaseApiService } from '@api-new/caseservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadInitialPlatformData } from '@shared/store/common.actions';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';
import { getMortgageCases, getMortgageCasesFailure, getMortgageCasesSuccess } from './mortgage-cases.actions';

@Injectable()
export class MortgageCasesEffects {
  handleLoadInitialPlatformData = createEffect(() => this.actions$.pipe(ofType(loadInitialPlatformData), map(getMortgageCases)));

  getMortgageCases = createEffect(() =>
    this.actions$.pipe(
      ofType(getMortgageCases),
      switchMap(() => this.caseApiService.HTTP_CP_ListMortgageCases()),
      map((properties) => getMortgageCasesSuccess(properties)),
      catchError((error) => of(getMortgageCasesFailure({ error }))),
    ),
  );

  constructor(
    private readonly caseApiService: CaseApiService,
    private readonly actions$: Actions,
  ) {}
}
