import { Injectable } from '@angular/core';
import { SkipableErrorHandler } from './skipable-error-handler.interface';

@Injectable({ providedIn: 'root' })
export class ChunkLoadErrorHandler implements SkipableErrorHandler {
  handleError(error: any): boolean {
    if (isChunkLoadError(error)) {
      console.error('Error loading chunk, refreshing');
      console.error(error);

      window.location.reload();
      return false;
    }
  }
}

const isChunkLoadError = (error: Error): boolean => error instanceof Error && /ChunkLoadError/.test(error.message);
