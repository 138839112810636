import { HTTP_CP_Mortgage } from '@api-new/mortgageservice';
import { AppState } from '@app/OLD_shared/models/app-state.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import { mortgagesAdapter } from '@shared/store/mortgages/mortgages.reducer';
import { MortgagesState } from '@shared/store/mortgages/mortgages.state';

const { selectAll, selectEntities } = mortgagesAdapter.getSelectors();

export const selectMortgagesState = createFeatureSelector<MortgagesState>('mortgages');

export const selectMortgages = createSelector(selectMortgagesState, (mortgagesState: MortgagesState) => selectAll(mortgagesState));

export const selectMortgageEntities = createSelector(selectMortgagesState, (mortgagesState: MortgagesState) =>
  selectEntities(mortgagesState),
);

export const selectMortgageById = (mortgageId: string): MemoizedSelector<AppState, HTTP_CP_Mortgage> =>
  createSelector(selectMortgages, (mortgages) => mortgages.find((mortgage) => mortgage.id === mortgageId));

export const selectIsLoadingMortgages = createSelector(selectMortgagesState, (mortgagesState: MortgagesState) => mortgagesState.loading);

export const selectMortgageByPropertyId = (propertyId: string): MemoizedSelector<AppState, HTTP_CP_Mortgage> =>
  createSelector(selectMortgages, (mortgages) => mortgages.find((mortgage) => mortgage.propertyId === propertyId));
