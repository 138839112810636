import { Injectable, inject } from '@angular/core';
import { Router } from '@angular/router';
import { AppState } from '@app/OLD_shared/models/app-state.model';
import { Store } from '@ngrx/store';
import { paths } from '@platform/paths';
import {
  agreeToTermsAndConditions,
  checkLoginMailAction,
  clearLastSignInCode,
  loginAction,
  loginMagicLinkAction,
  loginWithTokenAction,
  resetStoreAction,
  sendVerificationCodeAction,
} from '@shared/store/auth/auth.action';
import { SignInType } from '@shared/store/auth/auth.reducer';
import {
  isExpiredSignInCode,
  isInvalidSignInCode,
  selectAuthLoading,
  selectIsAdviserLogInError,
  selectIsLoginFromParamsLoading,
  selectIsSendingVerificationCode,
  selectLastSignInCode,
  selectLastSignInType,
  selectLastTimeVerificationCodeSent,
  selectNoEmailFoundError,
  selectSignInEmail,
  signInErrorCode,
} from '@shared/store/auth/auth.selectors';
import { Observable } from 'rxjs';

@Injectable()
export class AuthFacadeService {
  private readonly store = inject(Store<AppState>);
  private readonly router = inject(Router);

  readonly email$: Observable<string> = this.store.select(selectSignInEmail);
  readonly isAdviserLogInError$: Observable<boolean> = this.store.select(selectIsAdviserLogInError);
  readonly loading$: Observable<boolean> = this.store.select(selectAuthLoading);
  readonly isLoginFromParamsLoading$: Observable<boolean> = this.store.select(selectIsLoginFromParamsLoading);
  readonly isSendingVerificationCode$: Observable<boolean> = this.store.select(selectIsSendingVerificationCode);
  readonly authError$: Observable<string> = this.store.select(signInErrorCode);
  readonly lastTimeVerificationCodeSent$: Observable<Date> = this.store.select(selectLastTimeVerificationCodeSent);
  readonly noEmailFoundError$: Observable<boolean> = this.store.select(selectNoEmailFoundError);
  readonly isExpiredCodeError$: Observable<boolean> = this.store.select(isExpiredSignInCode);
  readonly isInvalidCodeError$: Observable<boolean> = this.store.select(isInvalidSignInCode);
  readonly lasSignInType$: Observable<SignInType> = this.store.select(selectLastSignInType);
  readonly verificationCodeFromFailedMagicLink$: Observable<string> = this.store.select(selectLastSignInCode);

  navigateToCheckMail(): void {
    void this.router.navigate([paths.AUTH, paths.auth.LOGIN]);
  }

  sendVerificationCode(email: string): void {
    this.store.dispatch(sendVerificationCodeAction({ email }));
  }

  login(email: string, verificationCode: string, redirectUrl?: string): void {
    this.store.dispatch(loginAction({ email, verificationCode, redirectUrl }));
  }

  loginMagicLink(email: string, verificationCode: string, redirectUrl?: string): void {
    this.store.dispatch(loginMagicLinkAction({ email, verificationCode, redirectUrl }));
  }

  checkLoginMail(email: string): void {
    this.store.dispatch(checkLoginMailAction({ email }));
  }

  loginWithToken(token: string, link: string): void {
    this.store.dispatch(resetStoreAction());
    this.store.dispatch(loginWithTokenAction({ token, redirectUrl: link }));
  }

  agreeToTermsAndConditions(): void {
    this.store.dispatch(agreeToTermsAndConditions());
  }

  clearLastSentVerificationCode(): void {
    this.store.dispatch(clearLastSignInCode());
  }
}
