import { HTTP_CP_MortgageCase, HTTP_CP_UpdateMortgageCase_Request } from '@api-new/caseservice';
import { createAction, props } from '@ngrx/store';
import { ErrorModel } from '../../models/app-state.model';
import { AccountsMetadata } from '../../models/mortgage.model';

export const getOldMortgageCases = createAction('[MORTGAGE-CASE-OLD] Get Mortgage Cases');

export const getMortgageCasesSuccess = createAction(
  '[MORTGAGE-CASE-OLD] Get Mortgage Cases Success',
  props<{ mortgageCases: HTTP_CP_MortgageCase[] }>(),
);

export const getMortgageCasesFailure = createAction('[MORTGAGE-CASE-OLD] Get Mortgage Cases Failure', props<{ error: ErrorModel }>());

export const closeMortgageCase = createAction(
  '[MORTGAGE-CASE-OLD] Close Mortgage Case',
  props<{ mortgageCaseCloseRequest: HTTP_CP_UpdateMortgageCase_Request }>(),
);

export const closeMortgageCaseSuccess = createAction(
  '[MORTGAGE-CASE-OLD] Close Mortgage Case Success',
  props<{ mortgageCaseId: string }>(),
);

export const closeMortgageCaseFailure = createAction('[MORTGAGE-CASE-OLD] Close Mortgage Case Failure', props<{ error: ErrorModel }>());

export const updateNewMortgageCase = createAction(
  '[MORTGAGE-CASE-OLD]  Update New Mortgage Case',
  props<{
    newMortgageCase: HTTP_CP_MortgageCase;
    newMortgageCaseURLMetadata: AccountsMetadata;
  }>(),
);
