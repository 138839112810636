<div class="forms h-100">
  <div class="form-container">
    <div class="landing-logo-container"></div>
    <form
      [formGroup]="acceptedTermsAndConditionsForm"
      (ngSubmit)="agreeAndLogin()"
      #ngForm="ngForm"
      class="login-form">
      <span class="landing-title">
        <span class="bold">Welcome to your new Mortgage Monitor</span>
      </span>
      <span class="landing-subtitle">
        As part of our ongoing commitment to providing you with expert advice, we monitor your
        mortgage 24/7 to ensure that you’re always on the deal that’s best suited to your
        circumstances.
      </span>
      <div class="mt-2">
        <div class="third-party-getting-started-item">
          <div class="third-party-getting-started-item-icon-container">
            <img
              class="third-party-getting-started-item-icon"
              src="../../../../assets/icons/third-party-getting-started-equity.svg" />
            <img
              class="third-party-getting-started-item-checkmark"
              src="../../../../assets/icons/third-party-getting-srated-checkmark.svg" />
          </div>
          <span class="third-party-getting-started-item-label">
            <span class="bold">Track the equity</span>
            &nbsp;that's building up in your property<br />
            and send you monthly reports
          </span>
        </div>
        <div class="third-party-getting-started-item">
          <div class="third-party-getting-started-item-icon-container">
            <img
              class="third-party-getting-started-item-icon"
              src="../../../../assets/icons/third-party-getting-started-alerts.svg" />
            <img
              class="third-party-getting-started-item-checkmark"
              src="../../../../assets/icons/third-party-getting-srated-checkmark.svg" />
          </div>
          <span class="third-party-getting-started-item-label">
            <span class="bold">Notify you</span>
            &nbsp;the moment there is a better rate your<br />
            rate changes or comes to an end
          </span>
        </div>
        <div class="third-party-getting-started-item">
          <div class="third-party-getting-started-item-icon-container">
            <img
              class="third-party-getting-started-item-icon"
              src="../../../../assets/icons/third-party-getting-started-documents.svg" />
            <img
              class="third-party-getting-started-item-checkmark"
              src="../../../../assets/icons/third-party-getting-srated-checkmark.svg" />
          </div>
          <span class="third-party-getting-started-item-label">
            <span class="bold">Challenge your</span>
            &nbsp;current mortgage deal against<br />
            thousands of other products
          </span>
        </div>
      </div>
      <div class="inputs">
        <div class="terms-and-conditions">
          <div class="row">
            <app-checkbox formControlName="agreeTerms" />
            <span class="terms-and-conditions-label">
              I agree to
              <a class="link" target="_blank" [href]="tacLink">Terms of Use</a>
              and
              <a class="link" target="_blank" [href]="privacyPolicyLink">Privacy Policy</a>
            </span>
          </div>
          <div class="dashly-warn-text-container">
            @if (showWarnState("agreeTerms", ngForm.submitted)) {
              <span class="dashly-warn-text">
                You have to agree with Terms of Use and Privacy Policy
              </span>
            }
          </div>
        </div>
        <div class="login-button-container">
          <app-arrow-button
            (click)="ngForm.onSubmit($event)"
            [disabled]="acceptedTermsAndConditionsForm.invalid"
            [loading]="loading$ | async">
            Continue
          </app-arrow-button>
        </div>
      </div>
    </form>
  </div>
</div>
