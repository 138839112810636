<button
  class="arrow-button"
  [disabled]="disabled"
  [ngClass]="{
    disabled: disabled,
    'dark-variant': variant === 'dark',
    'reset-variant': variant === 'reset',
    'secondary-variant': variant === 'secondary',
    'responsive-height': variant === 'responsive-height',
  }">
  @if (!loading) {
    <span class="arrow-button-content">
      <span class="arrow-button-label">
        <ng-content></ng-content>
      </span>
      @if (!disabled && variant !== "reset") {
        <img class="arrow-button-arrow" src="assets/icons/arrowright.svg" />
      }
      @if (!disabled && variant === "reset") {
        <img class="arrow-button-arrow" src="assets/icons/resend-white.svg" />
      }
      @if (disabled && variant === "reset") {
        <img class="arrow-button-arrow" src="assets/icons/resend.svg" />
      }
    </span>
  }
  @if (loading) {
    <span class="loading-container">
      <app-bouncing-balls [color]="!disabled ? 'white' : 'blue'"></app-bouncing-balls>
    </span>
  }
</button>
