export enum DateTimeFormats {
  MMYYYY_DASH = 'MM-YYYY',
  YYYYMMDD_DASH = 'YYYY-MM-DD',
  ddMMYYYY_SLASH = 'dd/MM/YYYY',
  DDMMYY_SLASH = 'DD/MM/YY',
  MMYYYY_SLASH = 'MM/YYYY',
  DMMM_DOT = 'D.MMM',
  DDMMMMYYYY_SPACE = 'DD MMMM YYYY',
  MMMYYYY_SPACE = 'MMM YYYY',
  MMMMYYYY_SPACE = 'MMMM YYYY',
  MMMMYYYY_COMMA = 'MMMM, YYYY',
  MMMYYYY_COMMA = 'MMM, YYYY',
  MMMMddYYYY_COMMA = 'MMMM, dd YYYY',
  DAY_OF_WEEK = 'D',
  SHORT_DAY_OF_WEEK = 'ddd',
  YEAR = 'YYYY',
  SHORT_MONTH = 'MMM',
  MONTH = 'MMMM',
  HOURS_MINUTES_AM_PM = 'h:mma',
  NTH_OF_MONTH = 'Do MMMM',
  DMMMMYYYY_DOT = '[on] D. MMMM YYYY',
  FULL = 'DD/MM/YYYY h:mm',
  FULL_AM_PM = 'DD/MM/YYYY h:mmA',
}
