import { Injectable } from '@angular/core';
import { MortgageApiService } from '@api-new/mortgageservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { selectClientUser } from '@shared/store/client-user/client-user.selectors';
import { Observable, of } from 'rxjs';
import { catchError, filter, mergeMap, switchMap, take, withLatestFrom } from 'rxjs/operators';
import { BANK_OF_ENGLAND_NAME } from '../../common';
import { AppState } from '../../models/app-state.model';
import { ErrorService } from '../../services/error.service';
import * as lenderActions from '../actions/lender.action';
import { $lendersLoaded } from '../selectors/lender.selectors';

@Injectable()
export class LenderEffect {
  getLenders$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(lenderActions.GetLendersAction.type),
      withLatestFrom(this.store.select(selectClientUser)),
      switchMap(([_, user]) =>
        this.store.pipe(
          select($lendersLoaded),
          take(1),
          filter((loaded) => !loaded),
          switchMap(() =>
            this.mortgageApiService
              .HTTP_X_ListLenders({
                filter: {
                  companyId: user.companyId,
                },
              })
              .pipe(
                mergeMap(({ lenders }) => [
                  new lenderActions.GetLendersSuccessAction(lenders != null ? lenders : []),
                  ...(lenders != null
                    ? [
                        new lenderActions.GetLenderAdditionalsAction(
                          (lenders.find((lender) => lender.name === BANK_OF_ENGLAND_NAME) || { id: '0' }).id,
                        ),
                      ]
                    : []),
                ]),
                catchError((error) => {
                  this.errorService.pushError(error);
                  return of(new lenderActions.GetLendersFailureAction(error));
                }),
              ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly errorService: ErrorService,
    private readonly mortgageApiService: MortgageApiService,
  ) {}
}
