import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { AppModule } from '@app/app.module';
import { environment } from '@environments/environment';

declare global {
  interface Window {
    googleMapsLoaded: () => void;
  }
}

window.googleMapsLoaded = () => {
  platformBrowserDynamic()
    .bootstrapModule(AppModule)
    .catch((err) => console.error(err));
};

export function loadGoogleMapsScript(): Promise<void> {
  return new Promise<void>((resolve, reject) => {
    const googleMapsScript = document.createElement('script');
    googleMapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${environment.googleCloudPlatformApiKey}&callback=googleMapsLoaded`;
    googleMapsScript.onload = () => {
      resolve();
    };
    googleMapsScript.onerror = (error) => {
      console.error('Error loading Google Maps script:', error);
      resolve(); // Resolve the Promise even on error to prevent app crash
    };
    document.body.appendChild(googleMapsScript);
  });
}
