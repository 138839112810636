import { HTTP_CP_MortgageDeal } from '@api-new/mortgageservice/index';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { BestBetterDeal, BestBetterDealViewModel, BestDealFlag } from '@shared/models/best-better-deal-view.model';
import { BestBetterDealState } from '@shared/store/best-better-deals/best-better-deals.state';
import { getDashboardDataSuccess } from '@shared/store/common.actions';

export const getBestBetterDealById = (bestBetterDeal: Pick<{ mortgageId: string; deals: HTTP_CP_MortgageDeal[] }, 'mortgageId'>): string =>
  bestBetterDeal.mortgageId;

export const bestBetterDealAdapter: EntityAdapter<{
  mortgageId: string;
  mortgageDeals: HTTP_CP_MortgageDeal[];
  numberOfMortgageDeals?: number;
}> = createEntityAdapter<{
  mortgageId: string;
  mortgageDeals: HTTP_CP_MortgageDeal[];
  numberOfMortgageDeals?: number;
}>({
  selectId: getBestBetterDealById,
});

const bestBetterDealInitialState: BestBetterDealState = bestBetterDealAdapter.getInitialState({
  loading: true,
  errors: null,
});

const buildMortgageBestDeals = (data): BestBetterDeal[] => {
  const bestDeals: BestBetterDeal[] = [];
  // set biggest saving
  if (data.mortgageDeals) bestDeals.push({ flag: BestDealFlag.BIGGEST_STAVING, ...data.mortgageDeals[0] });
  // set same lender - biggest saving
  if (data.mortgageDealSameLender) bestDeals.push({ flag: BestDealFlag.SAME_LENDER, ...data.mortgageDealSameLender });
  // set lowest payment
  if (data.mortgageDealLowestMonthlyPayment)
    bestDeals.push({ flag: BestDealFlag.LOWEST_PAYMENT, ...data.mortgageDealLowestMonthlyPayment });
  return bestDeals;
};

const reducer = createReducer(
  bestBetterDealInitialState,

  on(getDashboardDataSuccess, (state, dashboardData) => {
    if (!dashboardData.mortgageIdToDashboardDataForMortgageDealsMap) {
      return bestBetterDealAdapter.removeAll({
        ...state,
        loading: false,
        errors: null,
      });
    }
    const map = new Map(Object.entries(dashboardData.mortgageIdToDashboardDataForMortgageDealsMap));
    const entities: BestBetterDealViewModel[] = [];

    map.forEach((value, key) =>
      entities.push({
        mortgageId: key,
        mortgageDeals: value.mortgageDeals,
        numberOfMortgageDeals: value.numberOfMortgageDeals,
        bestDeals: buildMortgageBestDeals(value),
      }),
    );
    return bestBetterDealAdapter.setAll(entities, {
      ...state,
      loading: false,
      errors: null,
    });
  }),
);

export function bestBetterDealsReducer(state: BestBetterDealState = bestBetterDealInitialState, action: Action): BestBetterDealState {
  return reducer(state, action);
}
