// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_ClientUserEmploymentType.proto

export enum ENUM_ClientUserEmploymentType {
  CLIENT_USER_EMPLOYMENT_TYPE_UNSPECIFIED = 'CLIENT_USER_EMPLOYMENT_TYPE_UNSPECIFIED',
  CLIENT_USER_EMPLOYMENT_TYPE_FULL_TIME = 'CLIENT_USER_EMPLOYMENT_TYPE_FULL_TIME',
  CLIENT_USER_EMPLOYMENT_TYPE_PART_TIME = 'CLIENT_USER_EMPLOYMENT_TYPE_PART_TIME',
  CLIENT_USER_EMPLOYMENT_TYPE_SELF_EMPLOYED = 'CLIENT_USER_EMPLOYMENT_TYPE_SELF_EMPLOYED',
  CLIENT_USER_EMPLOYMENT_TYPE_RETIRED = 'CLIENT_USER_EMPLOYMENT_TYPE_RETIRED',
  CLIENT_USER_EMPLOYMENT_TYPE_UNEMPLOYED_HOME = 'CLIENT_USER_EMPLOYMENT_TYPE_UNEMPLOYED_HOME',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
