import { enableProdMode } from '@angular/core';
import { loadGoogleMapsScript } from '@shared/scripts/google-maps-scripts';
// tslint:disable-next-line:no-import-side-effect
import 'hammerjs';
import moment from 'moment';
// tslint:disable-next-line:no-import-side-effect
import 'moment-duration-format';
import { environment } from './environments/environment';

// tslint:disable-next-line:space-before-function-paren no-object-mutation
moment.fn.toJSON = function (): string {
  // tslint:disable-next-line:no-invalid-this
  return this.format('YYYY-MM-DD[T]HH:mm:ss[+00:00]');
};

if (environment.production) {
  enableProdMode();
}

// Load Google Maps script dynamically before bootstrapping the Angular app
loadGoogleMapsScript().catch((error) => {
  console.error('Error loading Google Maps script:', error);
});
