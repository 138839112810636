import { ErrorModel, NotificationSettingsState } from '../../models/app-state.model';
import { PayloadAction } from '../store-utils';
import {
  GetNotificationSettings,
  GetNotificationSettingsFailure,
  GetNotificationSettingsSuccess,
  NotificationSettingsAction,
  UpdateNotificationSettings,
  UpdateNotificationSettingsFailure,
  UpdateNotificationSettingsSuccess,
} from './notification-settings.action';

export const notificationSettingsInitialState: NotificationSettingsState = {
  errors: null,
  loading: false,
};

export function notificationSettingsReducer(
  state: NotificationSettingsState = notificationSettingsInitialState,
  action: NotificationSettingsAction,
): NotificationSettingsState {
  switch (action.type) {
    case GetNotificationSettings.type:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case UpdateNotificationSettings.type:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case GetNotificationSettingsSuccess.type:
    case UpdateNotificationSettingsSuccess.type:
      return {
        ...state,
        entity: action.payload,
        loading: false,
        loaded: true,
        errors: null,
      };

    case GetNotificationSettingsFailure.type:
    case UpdateNotificationSettingsFailure.type:
      return {
        ...state,
        loading: false,
        errors: (action as PayloadAction<ErrorModel>).payload,
      };

    default:
      return state;
  }
}
