import { ENUM_MortgagePartEarlyRepaymentChargeType } from '@api-new/common';
import { PeriodUnits } from '@app/OLD_shared/enums/period-units.enum';
import moment from 'moment';
import { MONTHS_IN_YEAR, MORTGAGE_EDIT_SUMMARY_TAB, getDurationInMonths, getUpdatedRateDate, momentify } from '../../common';
import { DEFAULTS } from '../../defaults';
import { MortgageEditState } from '../../models/app-state.model';
import { MortgageErcModel } from '../../models/mortgage-erc.model';
import { MortgagePartStepModel } from '../../models/mortgage-part-step.model';
import { MortgagePartModel } from '../../models/mortgage-part.model';
import { formatMomentToISO } from '../../utils/date/formatMomentToISO';
import {
  AddMortgageEditERCAction,
  AddMortgageEditOverpaymentAction,
  AddMortgageEditPartAction,
  AddMortgageEditRateAction,
  AddMortgageEditUnderpaymentAction,
  CalculateMortgageRatePaymentsSuccessAction,
  DeleteMortgageEditCurrentPartAction,
  DeleteMortgageEditERCAction,
  DeleteMortgageEditOverpaymentAction,
  DeleteMortgageEditRateAction,
  DeleteMortgageEditUnderpaymentAction,
  GetLenderSvrsForMortgageEditSuccessAction,
  MortgageEditAction,
  SetEditMortgageAttributeAction,
  SetEditedMortgageAction,
  SetMortgageEditCurrentPartRateValueAction,
  SetMortgageEditCurrentPartSVRAction,
  SetMortgageEditCurrentPartValueAction,
  SetMortgageEditERCValueAction,
  SetMortgageEditMetadataValueAction,
  SetMortgageEditOverpaymentValueAction,
  SetMortgageEditUnderpaymentValueAction,
} from '../actions/mortgage-edit.action';

/**
 * Copy ERC's of the first part, but adjust dates to fit
 */
const generateNewPartERCs = (firstPart: MortgagePartModel, newPartShift: number, newPartEndDate: moment.Moment): MortgageErcModel[] =>
  (firstPart.mortgagePartEarlyRepaymentCharges || [])
    .map((oldErc) => {
      const endDate = moment(oldErc.endDate).add(newPartShift, PeriodUnits.DAY);
      return {
        amount: oldErc.amount,
        mortgagePartEarlyRepaymentChargeType: oldErc.mortgagePartEarlyRepaymentChargeType,
        startDate: moment(oldErc.startDate).add(newPartShift, PeriodUnits.DAY),
        endDate: endDate.isAfter(newPartEndDate, PeriodUnits.DAY) ? moment(newPartEndDate) : moment(endDate),
      };
    })
    .filter((erc) => moment(erc.startDate).isBefore(newPartEndDate));

/**
 * Copy rates of the first part, but adjust dates to fit
 */
const generateNewPartRates = (firstPart: MortgagePartModel, newPartShift: number, newPartEndDate: moment.Moment): MortgagePartStepModel[] =>
  (firstPart.mortgagePartSteps || [])
    .map((oldRate) => {
      const rateEndDate = moment(oldRate.endDate).add(newPartShift, PeriodUnits.DAY);
      return {
        ...oldRate,
        id: null,
        createdAt: null,
        updatedAt: null,
        fkMortgagePart: null,
        startDate: moment(oldRate.startDate).add(newPartShift, PeriodUnits.DAY),
        endDate: rateEndDate.isAfter(newPartEndDate, PeriodUnits.DAY) ? moment(newPartEndDate) : moment(rateEndDate),
      };
    })
    .filter((rate) => moment(rate.startDate).isBefore(newPartEndDate));

const initialState: MortgageEditState = {
  entity: null,
  loading: false,
  errors: null,
  metadata: {
    selectedTab: MORTGAGE_EDIT_SUMMARY_TAB,
    addingPart: false,
    ratePayments: [],
    lender: null,
  },
};

export function mortgageEditReducer(state: MortgageEditState = initialState, action: MortgageEditAction): MortgageEditState {
  switch (action.type) {
    case SetEditedMortgageAction.type:
      return {
        ...state,
        entity: action.payload,
        metadata: {
          ...state.metadata,
          ratePayments: (action.payload.mortgageParts || []).map((part) => [...(part.mortgagePartSteps || []).map(() => 0)]),
        },
      };

    case SetEditMortgageAttributeAction.type:
      return {
        ...state,
        entity: {
          ...state.entity,
          [action.key]: action.value,
        },
      };

    case SetMortgageEditMetadataValueAction.type:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          [action.key]: action.value,
        },
      };

    case CalculateMortgageRatePaymentsSuccessAction.type:
      if (state.metadata.selectedTab.partIndex == null) {
        return state;
      }
      return {
        ...state,
        metadata: {
          ...state.metadata,
          ratePayments: (state.metadata.ratePayments || []).map((ratePayments, index) =>
            index === state.metadata.selectedTab.partIndex ? action.payload : ratePayments,
          ),
        },
      };

    /**
     * -------------------------------------
     * PART ACTIONS - ADD, DELETE, SET VALUE
     * -------------------------------------
     */

    case AddMortgageEditPartAction.type:
      const mortgage = state.entity;
      const newPart = action.payload;

      const firstPart = mortgage.mortgageParts[0];
      const newPartStartDate = momentify(newPart.startDate);
      const newPartEndDate =
        moment(newPartStartDate).daysInMonth() === moment(newPartStartDate).date()
          ? moment(newPartStartDate).add(newPart.termMonths, PeriodUnits.MONTH).endOf(PeriodUnits.MONTH)
          : moment(newPartStartDate).add(newPart.termMonths, PeriodUnits.MONTH);
      const oldPartStartDate = momentify(firstPart.startDate);

      const newPartShift = Math.floor(moment.duration(newPartStartDate.diff(oldPartStartDate)).asDays());

      const newERCs: MortgageErcModel[] = generateNewPartERCs(firstPart, newPartShift, newPartEndDate);
      const newRates: MortgagePartStepModel[] = generateNewPartRates(firstPart, newPartShift, newPartEndDate);

      return {
        ...state,
        entity: {
          ...state.entity,
          mortgageParts: [
            ...state.entity.mortgageParts,
            {
              initialAmount: newPart.initialAmount,
              startDate: formatMomentToISO(newPartStartDate),
              termMonths: newPart.termMonths,
              endDate: formatMomentToISO(newPartEndDate),
              repaymentType: newPart.repaymentType,
              mortgagePartOverpayments: [],
              mortgagePartUnderpayments: [],
              mortgagePartEarlyRepaymentCharges: newERCs,
              mortgagePartSteps: newRates,
            },
          ],
        },
        /**
         * Add new array of rate payments (set to default 0), for new part rates
         */
        metadata: {
          ...state.metadata,
          ratePayments: [...(state.metadata.ratePayments || []), newRates.map(() => 0)],
        },
      };

    /**
     * Remove mortgage part on given index
     * Also remove related rate payments array
     */
    case DeleteMortgageEditCurrentPartAction.type:
      if (state.metadata.selectedTab.partIndex == null || state.metadata.selectedTab.partIndex < 1) {
        return state;
      }
      return {
        ...state,
        entity: {
          ...state.entity,
          mortgageParts: state.entity.mortgageParts.filter((_, index) => index !== state.metadata.selectedTab.partIndex),
        },
        metadata: {
          ...state.metadata,
          ratePayments: (state.metadata.ratePayments || []).filter((_, index) => index !== state.metadata.selectedTab.partIndex),
        },
      };

    case SetMortgageEditCurrentPartValueAction.type:
      if (state.metadata.selectedTab.partIndex == null) {
        return state;
      }
      const currentPart = state.entity.mortgageParts[state.metadata.selectedTab.partIndex];

      return {
        ...state,
        entity: {
          ...state.entity,
          mortgageParts: state.entity.mortgageParts.map((part, index) => {
            if (index !== state.metadata.selectedTab.partIndex) {
              return part;
            }
            let dayInMonth: number;
            switch (action.key) {
              /**
               * If start date of part is adjusted, all rates need to be shifted by same amount
               */
              case 'startDate':
                const newStartDate = momentify(action.value as moment.Moment);
                dayInMonth = newStartDate.date();
                const isLastDayOfMonth = newStartDate.date() === newStartDate.daysInMonth();
                const monthsDiff = getDurationInMonths(currentPart.startDate, newStartDate);
                let previousRateEndDate;
                currentPart.mortgagePartEarlyRepaymentCharges[0].startDate = newStartDate;
                return {
                  ...currentPart,
                  startDate: formatMomentToISO(newStartDate),
                  endDate:
                    moment(newStartDate).daysInMonth() === moment(newStartDate).date()
                      ? moment(newStartDate).add(currentPart.termMonths, PeriodUnits.MONTH).endOf(PeriodUnits.MONTH)
                      : moment(newStartDate).add(currentPart.termMonths, PeriodUnits.MONTH),
                  mortgagePartSteps: currentPart.mortgagePartSteps.map((rate: MortgagePartStepModel, index, rates) => {
                    const step = {
                      ...rate,
                      startDate: rate.startDate
                        ? index === 0
                          ? formatMomentToISO(newStartDate)
                          : formatMomentToISO(moment(previousRateEndDate).add(1, 'day'))
                        : null,
                      endDate: rate.endDate
                        ? formatMomentToISO(getUpdatedRateDate(rate.endDate, monthsDiff, dayInMonth, isLastDayOfMonth))
                        : null,
                    };
                    previousRateEndDate = step.endDate;
                    return step;
                  }),
                };
              /**
               * Editing termMonths of part changes endDate (since endDate is calculated from startDate and term)
               * Also changes endDate of last rate
               */
              case 'termMonths':
                let newTerm = action.value as number;
                if (newTerm == null || newTerm < 0) {
                  newTerm = 0;
                }
                const diffInTerms: number = newTerm - currentPart.termMonths;
                return {
                  ...currentPart,
                  termMonths: newTerm,
                  endDate: currentPart.startDate
                    ? moment(currentPart.startDate).daysInMonth() === moment(currentPart.startDate).date()
                      ? moment(currentPart.startDate).add(newTerm, PeriodUnits.MONTH).endOf(PeriodUnits.MONTH)
                      : moment(currentPart.startDate).add(newTerm, PeriodUnits.MONTH)
                    : null,
                  mortgagePartSteps: currentPart.mortgagePartSteps.map((rate, rateIndex) => {
                    if (rateIndex !== currentPart.mortgagePartSteps.length - 1) {
                      return rate;
                    }
                    return {
                      ...rate,
                      endDate: rate.endDate
                        ? moment(rate.endDate).daysInMonth() === moment(rate.endDate).date()
                          ? moment(rate.endDate).add(diffInTerms, PeriodUnits.MONTH).endOf(PeriodUnits.MONTH)
                          : moment(rate.endDate).add(diffInTerms, PeriodUnits.MONTH)
                        : null,
                    };
                  }),
                };

              /**
               *  On any other attribute change, simply store value
               */
              default:
                return {
                  ...currentPart,
                  [action.key]: action.value,
                };
            }
          }),
        },
      };

    /**
     * ---------------------------------------------
     * OVERPAYMENTS ACTIONS - ADD, DELETE, SET VALUE
     * ---------------------------------------------
     */

    /**
     * Add new default overpayment to array of overpayments in mortgagePart with index stored in metadata
     */
    case AddMortgageEditOverpaymentAction.type:
      if (state.metadata.selectedTab.partIndex == null) {
        return state;
      }
      return {
        ...state,
        entity: {
          ...state.entity,
          mortgageParts: state.entity.mortgageParts.map((part, index) => {
            if (index !== state.metadata.selectedTab.partIndex) {
              return part;
            }
            return {
              ...part,
              mortgagePartOverpayments: [
                ...(state.entity.mortgageParts[state.metadata.selectedTab.partIndex].mortgagePartOverpayments || []),
                DEFAULTS.defaultExtraPayments(),
              ],
            };
          }),
        },
      };

    /**
     * Delete overpayments object on index given in payload, in mortgagePart with index stored in metadata
     */
    case DeleteMortgageEditOverpaymentAction.type:
      if (state.metadata.selectedTab.partIndex == null) {
        return state;
      }
      return {
        ...state,
        entity: {
          ...state.entity,
          mortgageParts: [
            {
              ...state.entity.mortgageParts[state.metadata.selectedTab.partIndex],
              mortgagePartOverpayments: (
                state.entity.mortgageParts[state.metadata.selectedTab.partIndex].mortgagePartOverpayments || []
              ).filter((_, index) => index !== action.payload),
            },
            ...state.entity.mortgageParts.slice(1),
          ],
        },
      };

    /**
     * Sets value of overpayment object, on index given in "action.index"
     * in mortgagePart with index stored in metadata
     */
    case SetMortgageEditOverpaymentValueAction.type:
      if (state.metadata.selectedTab.partIndex == null) {
        return state;
      }
      return {
        ...state,
        entity: {
          ...state.entity,
          mortgageParts: (state.entity.mortgageParts || []).map((part, index) => {
            if (index !== state.metadata.selectedTab.partIndex) {
              return part;
            }
            return {
              ...part,
              mortgagePartOverpayments: (
                state.entity.mortgageParts[state.metadata.selectedTab.partIndex].mortgagePartOverpayments || []
              ).map((overpayment, overpaymentIndex) => {
                if (overpaymentIndex !== action.index) {
                  return overpayment;
                }
                return {
                  ...overpayment,
                  [action.key]: action.value,
                };
              }),
            };
          }),
        },
      };

    /**
     * ---------------------------------------------
     * UNDERPAYMENT ACTIONS - ADD, DELETE, SET VALUE
     * ---------------------------------------------
     */

    /**
     * Add new default underpayment to array of underpayments in mortgagePart with index stored in metadata
     */
    case AddMortgageEditUnderpaymentAction.type:
      if (state.metadata.selectedTab.partIndex == null) {
        return state;
      }
      return {
        ...state,
        entity: {
          ...state.entity,
          mortgageParts: state.entity.mortgageParts.map((part, index) => {
            if (index !== state.metadata.selectedTab.partIndex) {
              return part;
            }
            return {
              ...part,
              mortgagePartUnderpayments: [
                ...(state.entity.mortgageParts[state.metadata.selectedTab.partIndex].mortgagePartUnderpayments || []),
                DEFAULTS.defaultExtraPayments(),
              ],
            };
          }),
        },
      };

    /**
     * Delete underpayment object on index given in payload, in mortgagePart with index stored in metadata
     */
    case DeleteMortgageEditUnderpaymentAction.type:
      if (state.metadata.selectedTab.partIndex == null) {
        return state;
      }
      return {
        ...state,
        entity: {
          ...state.entity,
          mortgageParts: [
            {
              ...state.entity.mortgageParts[state.metadata.selectedTab.partIndex],
              mortgagePartUnderpayments: (
                state.entity.mortgageParts[state.metadata.selectedTab.partIndex].mortgagePartUnderpayments || []
              ).filter((_, index) => index !== action.payload),
            },
            ...state.entity.mortgageParts.slice(1),
          ],
        },
      };

    /**
     * Sets value of underpayment object, on index given in "action.index"
     * in mortgagePart with index stored in metadata
     */
    case SetMortgageEditUnderpaymentValueAction.type:
      if (state.metadata.selectedTab.partIndex == null) {
        return state;
      }
      return {
        ...state,
        entity: {
          ...state.entity,
          mortgageParts: (state.entity.mortgageParts || []).map((part, index) => {
            if (index !== state.metadata.selectedTab.partIndex) {
              return part;
            }
            return {
              ...part,
              mortgagePartUnderpayments: (
                state.entity.mortgageParts[state.metadata.selectedTab.partIndex].mortgagePartUnderpayments || []
              ).map((underpayment, underpaymentIndex) => {
                if (underpaymentIndex !== action.index) {
                  return underpayment;
                }
                return {
                  ...underpayment,
                  [action.key]: action.value,
                };
              }),
            };
          }),
        },
      };

    /**
     * --------------------------------------
     * RATES ACTIONS - ADD, DELETE, SET VALUE
     * + special set for rate SVR
     * --------------------------------------
     */

    /**
     * Adds rate to rate array in mortgagePart with index stored in metadata
     * New rate is always placed on second to last index.
     * New rate's start date is set to same date as last rate's start date
     * New rate's end date is set to startDate + 12 months
     * Last rate start date is set to same date as new rates end date
     */
    case AddMortgageEditRateAction.type:
      if (state.metadata.selectedTab.partIndex == null) {
        return state;
      }
      const partRates = state.entity.mortgageParts[state.metadata.selectedTab.partIndex].mortgagePartSteps;
      const lastRate = partRates.slice(-1)[0];
      const newRateStartDate = formatMomentToISO(moment(lastRate.startDate));
      const newRateEndDate = formatMomentToISO(moment(newRateStartDate).subtract(1, 'day').add(MONTHS_IN_YEAR, PeriodUnits.MONTH));

      return {
        ...state,
        entity: {
          ...state.entity,
          mortgageParts: state.entity.mortgageParts.map((part, index) => {
            if (index !== state.metadata.selectedTab.partIndex) {
              return part;
            }
            return {
              ...part,
              mortgagePartSteps: [
                ...partRates.slice(0, partRates.length - 1),
                {
                  mortgagePartStepType: null,
                  startDate: newRateStartDate,
                  endDate: newRateEndDate,
                  fixedInterestRate: null,
                  variableInterestRate: null,
                  trackerRateMin: null,
                  trackerRateMax: null,
                },
                {
                  ...lastRate,
                  startDate: formatMomentToISO(moment(newRateEndDate).add(1, 'day')),
                },
              ],
            };
          }),
        },
        /**
         * In payments, new default value (0) is pushed to current part ratePayments array
         */
        metadata: {
          ...state.metadata,
          ratePayments: (state.metadata.ratePayments || []).map((partRatePayments, index) => {
            if (index !== state.metadata.selectedTab.partIndex) {
              return partRatePayments;
            }
            return [...partRatePayments.slice(0, partRates.length - 1), 0, partRatePayments[partRatePayments.length - 1]];
          }),
        },
      };

    /**
     * Deletes rate on the index given in "action.payload" in mortgagePart with index stored in metadata
     * All rates with higher index need to have their dates shifted back by the term of deleted rate
     * Last rate only gets startDate shifted, endDate stays same
     */
    case DeleteMortgageEditRateAction.type:
      if (state.metadata.selectedTab.partIndex == null) {
        return state;
      }
      const currentPartRates = state.entity.mortgageParts[state.metadata.selectedTab.partIndex].mortgagePartSteps;
      const indexOfDeletedRate = action.payload;
      const deletedRate = currentPartRates[indexOfDeletedRate];
      if (indexOfDeletedRate === currentPartRates.length - 1 || deletedRate == null) {
        return state;
      }
      const deletedRateTerm = getDurationInMonths(deletedRate.startDate, deletedRate.endDate);
      return {
        ...state,
        entity: {
          ...state.entity,
          mortgageParts: state.entity.mortgageParts.map((part, index) => {
            if (index !== state.metadata.selectedTab.partIndex) {
              return part;
            }
            return {
              ...part,
              mortgagePartSteps: currentPartRates
                .map((rate, rateIndex) => {
                  if (rateIndex < indexOfDeletedRate) {
                    return rate;
                  }
                  if (rateIndex === indexOfDeletedRate) {
                    return null;
                  }
                  if (rateIndex === currentPartRates.length - 1) {
                    return {
                      ...rate,
                      startDate: rate.startDate
                        ? formatMomentToISO(moment(rate.startDate).subtract(deletedRateTerm, PeriodUnits.MONTH))
                        : null,
                    };
                  }
                  return {
                    ...rate,
                    startDate: rate.startDate
                      ? formatMomentToISO(moment(rate.startDate).subtract(deletedRateTerm, PeriodUnits.MONTH))
                      : null,
                    endDate: rate.endDate ? formatMomentToISO(moment(rate.endDate).subtract(deletedRateTerm, PeriodUnits.MONTH)) : null,
                  };
                })
                .filter((rate) => rate != null),
            };
          }),
        },
        metadata: {
          ...state.metadata,
          ratePayments: (state.metadata.ratePayments || []).map((partRatePayments, index) => {
            if (index !== state.metadata.selectedTab.partIndex) {
              return partRatePayments;
            }
            return (partRatePayments || []).filter((_, ratePaymentIndex) => ratePaymentIndex === indexOfDeletedRate);
          }),
        },
      };

    /**
     * Sets value of mortgage rate on the index from "action.index"
     * in mortgagePart with index stored in metadata.
     */
    case SetMortgageEditCurrentPartRateValueAction.type:
      if (state.metadata.selectedTab.partIndex == null) {
        return state;
      }
      const currentRate = state.entity.mortgageParts[state.metadata.selectedTab.partIndex].mortgagePartSteps[action.index];
      return {
        ...state,
        entity: {
          ...state.entity,
          mortgageParts: state.entity.mortgageParts.map((part, index) => {
            if (index !== state.metadata.selectedTab.partIndex) {
              return part;
            }
            return {
              ...part,
              mortgagePartSteps: part.mortgagePartSteps.map((rate, rateIndex) => {
                /**
                 * Special handling of termMonths -> it's not MortgageRate attribute
                 * Term in Months is displayed to user, but this duration is stored as 'startDate' and 'endDate'
                 */
                if (action.key === 'termMonths') {
                  let newTermInMonths = action.value as number;
                  if (newTermInMonths == null || newTermInMonths < 0) {
                    newTermInMonths = 0;
                  }
                  const differenceInMonths = newTermInMonths - getDurationInMonths(currentRate.startDate, currentRate.endDate);
                  const isLastDayOfMonth = moment(part.startDate).date() === moment(part.startDate).daysInMonth();
                  const dayOfMonth = moment(part.startDate).date();
                  /**
                   * Rates with index number lower than rate that is being changed are unaffected
                   * Also, if there is no difference in term, nothing happens
                   */
                  if (differenceInMonths === 0 || rateIndex < action.index) {
                    return rate;
                  }
                  /**
                   * The rate which was changed, keeps its startDate,
                   * but endDate needs to be increased by "differenceInMonths" duration
                   */
                  if (rateIndex === action.index) {
                    return {
                      ...rate,
                      endDate: rate.endDate
                        ? formatMomentToISO(getUpdatedRateDate(rate.endDate, differenceInMonths, dayOfMonth, isLastDayOfMonth))
                        : null,
                    };
                  }
                  /**
                   * Last rate has startDate changed by the "differenceInMonths" duration.
                   * endDate is not changed
                   * therefore last rate duration is gets longer or shorter, depending on the previous rates
                   */
                  if (rateIndex === part.mortgagePartSteps.length - 1) {
                    return {
                      ...rate,
                      startDate: rate.startDate
                        ? formatMomentToISO(
                            getUpdatedRateDate(rate.startDate, differenceInMonths, dayOfMonth, isLastDayOfMonth).add(1, 'day'),
                          )
                        : null,
                    };
                  }
                  /**
                   * Finally, all other rates have term unchanged
                   * both startDate and endDate are changed by the same amount, in the same direction
                   */
                  return {
                    ...rate,
                    startDate: rate.startDate
                      ? formatMomentToISO(
                          moment(rate.startDate).startOf(PeriodUnits.MONTH).add(differenceInMonths, PeriodUnits.MONTH).add(1, 'day'),
                        )
                      : null,
                    endDate: rate.endDate
                      ? formatMomentToISO(moment(rate.endDate).startOf(PeriodUnits.MONTH).add(differenceInMonths, PeriodUnits.MONTH))
                      : null,
                  };
                }

                if (rateIndex !== action.index) {
                  return {
                    ...rate,
                  };
                }
                /**
                 * For fixed type rates, rate is simply stored as "rate"
                 * For variable rate types, rate is defined  by underlying SVR
                 * rate displayed to user is underlying SVR rate + "trackerRateMargin"
                 * trackerRateMargin can be negative value (e.g. user pays less than SVR)
                 * on mortgage update, trackerRateMargin is deleted if mortgage is fixed type
                 * and "rate" attribute is deleted if mortgage is variable type
                 */
                if (action.key === 'fixedInterestRate') {
                  const newRateValue = Number(action.value) || 0;
                  return {
                    ...rate,
                    fixedInterestRate: newRateValue,
                  };
                }
                return {
                  ...rate,
                  [action.key]: action.value,
                };
              }),
            };
          }),
        },
      };

    case SetMortgageEditCurrentPartSVRAction.type:
      if (state.metadata.selectedTab.partIndex == null) {
        return state;
      }
      return {
        ...state,
        entity: {
          ...state.entity,
          mortgageParts: state.entity.mortgageParts.map((part, index) => {
            if (index !== state.metadata.selectedTab.partIndex) {
              return part;
            }
            return {
              ...part,
              mortgagePartSteps: part.mortgagePartSteps.map((rate, rateIndex) => {
                if (rateIndex !== action.payload.rateIndex) {
                  return {
                    ...rate,
                  };
                }
                return {
                  ...rate,
                  variableLenderSvrId: action.payload.variableLenderSvrId,
                  variableInterestRate: action.payload.fixedInterestRate,
                  fixedInterestRate: action.payload.fixedInterestRate,
                };
              }),
            };
          }),
        },
      };

    /**
     * ------------------------------------
     * ERC ACTIONS - ADD, DELETE, SET VALUE
     * ------------------------------------
     */

    /**
     * Add new default ERC to array of ERCs in mortgagePart with index stored in metadata
     */
    case AddMortgageEditERCAction.type:
      if (state.metadata.selectedTab.partIndex == null) {
        return state;
      }
      return {
        ...state,
        entity: {
          ...state.entity,
          mortgageParts: state.entity.mortgageParts.map((part, partIndex) => {
            if (partIndex !== state.metadata.selectedTab.partIndex) {
              return part;
            }
            const startDate: moment.Moment =
              part.mortgagePartEarlyRepaymentCharges == null || part.mortgagePartEarlyRepaymentCharges.length < 1
                ? momentify(state.entity.startDate) || moment()
                : momentify(part.mortgagePartEarlyRepaymentCharges[part.mortgagePartEarlyRepaymentCharges.length - 1].endDate);
            return {
              ...part,
              mortgagePartEarlyRepaymentCharges: [
                ...(part.mortgagePartEarlyRepaymentCharges || []),
                {
                  ercStartDate: formatMomentToISO(startDate),
                  endDate: formatMomentToISO(moment(startDate).add(1, PeriodUnits.YEAR)),
                  amount: null,
                  mortgagePartEarlyRepaymentChargeType:
                    ENUM_MortgagePartEarlyRepaymentChargeType.MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_FIXED_AMOUNT,
                  mortgagePartId: part.id,
                },
              ],
            };
          }),
        },
      };

    /**
     * Delete ERC object on index given in "action.payload", in mortgagePart with index stored in metadata
     */
    case DeleteMortgageEditERCAction.type:
      if (state.metadata.selectedTab.partIndex == null) {
        return state;
      }
      return {
        ...state,
        entity: {
          ...state.entity,
          mortgageParts: state.entity.mortgageParts.map((part, partIndex) => {
            if (partIndex !== state.metadata.selectedTab.partIndex) {
              return part;
            }
            return {
              ...part,
              mortgagePartEarlyRepaymentCharges: (part.mortgagePartEarlyRepaymentCharges || []).filter(
                (_, index) => index !== action.payload,
              ),
            };
          }),
        },
      };

    /**
     * Sets value of ERC object, on index given in "action.index"
     * in mortgagePart with index stored in metadata
     */
    case SetMortgageEditERCValueAction.type:
      if (state.metadata.selectedTab.partIndex == null) {
        return state;
      }
      return {
        ...state,
        entity: {
          ...state.entity,
          mortgageParts: state.entity.mortgageParts.map((part, partIndex) => {
            if (partIndex !== state.metadata.selectedTab.partIndex) {
              return part;
            }
            let previousErcEndDate;
            return {
              ...part,
              mortgagePartEarlyRepaymentCharges: (part.mortgagePartEarlyRepaymentCharges || []).map((erc, ercIndex) => {
                let updatedErc = erc;
                if (ercIndex === action.index) {
                  updatedErc = {
                    ...erc,
                    [action.key]: action.value,
                  };
                }
                if (ercIndex > 0) {
                  updatedErc.startDate = formatMomentToISO(moment(previousErcEndDate).add(1, 'day'));
                }
                previousErcEndDate = updatedErc.endDate;
                return updatedErc;
              }),
            };
          }),
        },
      };

    case GetLenderSvrsForMortgageEditSuccessAction.type:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          lender: action.payload,
        },
      };

    default:
      return state;
  }
}
