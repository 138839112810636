import { Injectable } from '@angular/core';
import { MortgageApiService } from '@api-new/mortgageservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadInitialPlatformData } from '@shared/store/common.actions';
import { getSmartSearchDeals } from '@shared/store/smart-search-deals/smart-search-deals.actions';
import {
  addSmartSearch,
  getSmartSearches,
  getSmartSearchesFailure,
  getSmartSearchesSuccess,
  updateSmartSearch,
} from '@shared/store/smart-searches/smart-searches.actions';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class SmartSearchesEffects {
  handleLoadInitialPlatformData = createEffect(() => this.actions$.pipe(ofType(loadInitialPlatformData), map(getSmartSearches)));

  getSmartSearches = createEffect(() =>
    this.actions$.pipe(
      ofType(getSmartSearches),
      switchMap(() => this.mortgageApiService.HTTP_CP_ListSmartSearches()),
      map((smartSearches) => getSmartSearchesSuccess(smartSearches)),
      catchError((error) => of(getSmartSearchesFailure({ error }))),
    ),
  );

  createSmartSearchesSuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(addSmartSearch, updateSmartSearch),
      map(({ smartSearch }) => getSmartSearchDeals({ smartSearchId: smartSearch.id })),
    ),
  );

  constructor(
    private readonly mortgageApiService: MortgageApiService,
    private readonly actions$: Actions,
  ) {}
}
