// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_MortgageProductDistributionType.proto

export enum ENUM_MortgageProductDistributionType {
  MORTGAGE_PRODUCT_DISTRIBUTION_TYPE_UNSPECIFIED = 'MORTGAGE_PRODUCT_DISTRIBUTION_TYPE_UNSPECIFIED',
  MORTGAGE_PRODUCT_DISTRIBUTION_TYPE_ANY_DISTRIBUTION = 'MORTGAGE_PRODUCT_DISTRIBUTION_TYPE_ANY_DISTRIBUTION',
  MORTGAGE_PRODUCT_DISTRIBUTION_TYPE_DIRECT = 'MORTGAGE_PRODUCT_DISTRIBUTION_TYPE_DIRECT',
  MORTGAGE_PRODUCT_DISTRIBUTION_TYPE_INTERMEDIARY = 'MORTGAGE_PRODUCT_DISTRIBUTION_TYPE_INTERMEDIARY',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
