import { HTTP_CP_GetMortgageDealComparison_Request } from '@api-new/mortgageservice';
import { ErrorModel } from '../../models/app-state.model';
import { SavingChartModel } from '../../models/search-response.model';
import { PayloadAction } from '../store-utils';

export class GetSavingChartAction extends PayloadAction<{
  dealHash: string;
  request: HTTP_CP_GetMortgageDealComparison_Request;
}> {
  static readonly type = 'GetSavingChartAction';
  readonly type = GetSavingChartAction.type;
}

export class GetSavingChartSuccessAction extends PayloadAction<{ dealHash: string; savingChart: SavingChartModel }> {
  static readonly type = 'GetSavingChartSuccessAction';
  readonly type = GetSavingChartSuccessAction.type;
}

export class GetSavingChartFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'GetSavingChartFailureAction';
  readonly type = GetSavingChartFailureAction.type;
}

export type SavingChartActions = GetSavingChartAction | GetSavingChartSuccessAction | GetSavingChartFailureAction;
