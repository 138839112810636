// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_ClientUserEmploymentSelfEmployedType.proto

export enum ENUM_ClientUserEmploymentSelfEmployedType {
  CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_UNSPECIFIED = 'CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_UNSPECIFIED',
  CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_SOLE_TRADER = 'CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_SOLE_TRADER',
  CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_PARTNERSHIP = 'CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_PARTNERSHIP',
  CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_LIMITED_COMPANY = 'CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_LIMITED_COMPANY',
  CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_LLP = 'CLIENT_USER_EMPLOYMENT_SELF_EMPLOYED_TYPE_LLP',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
