<div
  class="checkbox-container"
  [ngClass]="{
    'light-variant': variant === 'light',
    'filter-variant': variant === 'filter',
    disabled: disabled,
  }"
  tabindex="0">
  <div class="checkbox-wrapper" (click)="checkboxClicked()" (keydown.enter)="checkboxClicked()">
    <div class="checkbox" [ngClass]="{ checked: checked }">
      @if (checked && variant !== "light") {
        <img src="assets/icons/checkmark.svg" />
      }
      @if (checked && variant === "light") {
        <img src="assets/icons/checkmark-blue.svg" />
      }
    </div>
  </div>

  @if (label) {
    <span class="checkbox-label" (click)="checkboxClicked()" (keydown.enter)="checkboxClicked()">
      {{ label }}
    </span>
  }
  @if (tooltipId) {
    <img
      src="assets/icons/circle-info-icon.svg"
      class="dashly-info-icon"
      [id]="tooltipId"
      cdk-overlay-origin
      #tooltipOrigin="cdkOverlayOrigin" />
  }
</div>
