import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

export interface RedirectToExternalWebsiteInputData {
  websiteUrl: string;
  queryParamsRenameMap: Record<string, string>;
}

@Component({
  templateUrl: './redirect-to-external-website.component.html',
  styleUrls: ['./redirect-to-external-website.component.scss'],
  standalone: true,
})
export class RedirectToExternalWebsiteComponent {
  constructor(private readonly route: ActivatedRoute) {
    this.redirectWithRenamedQueryParams(this.route.snapshot.data.redirectToExternalWebsiteData);
  }

  redirectWithRenamedQueryParams(inputData: RedirectToExternalWebsiteInputData): void {
    const url = new URL(inputData.websiteUrl);
    const queryParams = new URLSearchParams(this.route.snapshot.queryParams);
    const renamedParams = new URLSearchParams();

    queryParams.forEach((value: string, key: string) => {
      const renamedKey = inputData.queryParamsRenameMap[key] || key;
      renamedParams.append(renamedKey, value);
    });

    url.search = renamedParams.toString();
    window.location.href = url.toString();
  }
}
