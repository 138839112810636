import { ErrorModel, SavingChartState } from '../../models/app-state.model';
import {
  GetSavingChartAction,
  GetSavingChartFailureAction,
  GetSavingChartSuccessAction,
  SavingChartActions,
} from '../actions/saving-chart.action';
import { PayloadAction } from '../store-utils';

const initialState: SavingChartState = {
  entities: {},
  errors: null,
  loading: false,
};

export function savingChartReducer(state: SavingChartState = initialState, action: SavingChartActions): SavingChartState {
  switch (action.type) {
    case GetSavingChartAction.type:
      return {
        ...state,
        loading: true,
        errors: null,
      };

    case GetSavingChartFailureAction.type:
      return {
        ...state,
        loading: false,
        errors: (action as PayloadAction<ErrorModel>).payload,
      };

    case GetSavingChartSuccessAction.type: {
      const savings = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          [savings.dealHash]: savings.savingChart,
        },
        loading: false,
      };
    }

    default:
      return state;
  }
}
