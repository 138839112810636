import { ENUM_PropertyInvestmentType } from '@api-new/common';
import { HTTP_CP_Property } from '@api-new/propertyservice';
import { AppState } from '@app/OLD_shared/models/app-state.model';
import { createFeatureSelector, createSelector } from '@ngrx/store';
import { MemoizedSelector } from '@ngrx/store/src/selector';
import { propertiesAdapter } from '@shared/store/properties/properties.reducer';
import { PropertiesState } from '@shared/store/properties/properties.state';

const { selectAll, selectEntities } = propertiesAdapter.getSelectors();

export const selectPropertiesState = createFeatureSelector<PropertiesState>('properties');

export const selectProperties = createSelector(selectPropertiesState, (propertiesState: PropertiesState) => selectAll(propertiesState));

export const selectPropertyEntities = createSelector(selectPropertiesState, (propertiesState: PropertiesState) =>
  selectEntities(propertiesState),
);

export const selectPropertyById = (propertyId: string): MemoizedSelector<AppState, HTTP_CP_Property> =>
  createSelector(selectPropertyEntities, (propertyEntities) => propertyEntities[propertyId]);

export const selectResidentialProperty = createSelector(selectPropertiesState, (propertiesState: PropertiesState) =>
  selectAll(propertiesState).find(
    (property) => property.investmentType === ENUM_PropertyInvestmentType.PROPERTY_INVESTMENT_TYPE_RESIDENTIAL,
  ),
);

export const selectIsLoadingProperties = createSelector(
  selectPropertiesState,
  (propertiesState: PropertiesState) => propertiesState.loading,
);

export const selectIsUpdatingProperties = createSelector(
  selectPropertiesState,
  (propertiesState: PropertiesState) => propertiesState.updating,
);

export const selectPropertiesNumber = createSelector(selectProperties, (properties): number => properties.length ?? 0);
