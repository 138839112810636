import { Action, createReducer, on } from '@ngrx/store';
import {
  clearClientUser,
  deleteClientUser,
  deleteClientUserFailure,
  deleteClientUserSuccess,
  dismissClientUserLandlordPropertyBannerFailure,
  dismissClientUserLandlordPropertyBannerSuccess,
  getClientUser,
  getClientUserFailure,
  getClientUserSuccess,
  provideClientUserDeleteCodeFailure,
  updateClientUser,
  updateClientUserFailure,
  updateClientUserSuccess,
} from '@shared/store/client-user/client-user.actions';
import { ClientUserState } from '@shared/store/client-user/client-user.state';

export const clientUserInitialState: ClientUserState = {
  clientUser: null,
  loading: true,
  errors: null,
};

const reducer = createReducer(
  clientUserInitialState,

  on(getClientUser, updateClientUser, deleteClientUser, (state) => ({
    ...state,
    loading: true,
  })),

  on(updateClientUserSuccess, getClientUserSuccess, dismissClientUserLandlordPropertyBannerSuccess, (state, { clientUser }) => ({
    ...state,
    clientUser,
    loading: false,
  })),

  on(
    updateClientUserFailure,
    getClientUserFailure,
    provideClientUserDeleteCodeFailure,
    deleteClientUserFailure,
    dismissClientUserLandlordPropertyBannerFailure,
    (state, { error }) => ({
      ...state,
      loading: false,
      errors: error,
    }),
  ),

  on(clearClientUser, deleteClientUserSuccess, () => ({
    ...clientUserInitialState,
  })),
);

export function clientUserReducer(state: ClientUserState = clientUserInitialState, action: Action): ClientUserState {
  return reducer(state, action);
}
