import { Injectable } from '@angular/core';
import { MortgageApiService } from '@api-new/mortgageservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import {
  getSmartSearchDeals,
  getSmartSearchDealsFailure,
  getSmartSearchDealsSilently,
  getSmartSearchDealsSuccess,
} from '@shared/store/smart-search-deals/smart-search-deals.actions';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class SmartSearchDealsEffects {
  getSmartSearchDeals = createEffect(() =>
    this.actions$.pipe(
      ofType(getSmartSearchDeals),
      switchMap((request) =>
        this.mortgageApiService
          .HTTP_CP_ListSmartSearchDeals({
            smartSearchDeal: request.smartSearchDeal,
            smartSearchId: request.smartSearchId,
          })
          .pipe(
            map((response) =>
              getSmartSearchDealsSuccess({
                smartSearchDealMap: [
                  {
                    smartSearchId: request.smartSearchId,
                    smartSearchDeals: response.smartSearchDeals,
                    numberOfSmartSearchDeals: response.numberOfSmartSearchDeals,
                  },
                ],
              }),
            ),
            catchError((error) => of(getSmartSearchDealsFailure({ error }))),
          ),
      ),
    ),
  );

  getSmartSearchDealsSilently = createEffect(() =>
    this.actions$.pipe(
      ofType(getSmartSearchDealsSilently),
      switchMap((request) =>
        this.mortgageApiService
          .HTTP_CP_ListSmartSearchDeals({
            smartSearchDeal: request.smartSearchDeal,
            smartSearchId: request.smartSearchId,
          })
          .pipe(
            map((response) =>
              getSmartSearchDealsSuccess({
                smartSearchDealMap: [
                  {
                    smartSearchId: request.smartSearchId,
                    smartSearchDeals: response.smartSearchDeals,
                    numberOfSmartSearchDeals: response.numberOfSmartSearchDeals,
                  },
                ],
              }),
            ),
            catchError((error) => of(getSmartSearchDealsFailure({ error }))),
          ),
      ),
    ),
  );

  constructor(
    private readonly mortgageApiService: MortgageApiService,
    private readonly actions$: Actions,
  ) {}
}
