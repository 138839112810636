import { HTTP_CP_SmartSearchDeal } from '@api-new/mortgageservice';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { getDashboardDataSuccess } from '@shared/store/common.actions';
import {
  getSmartSearchDeals,
  getSmartSearchDealsFailure,
  getSmartSearchDealsSuccess,
} from '@shared/store/smart-search-deals/smart-search-deals.actions';
import { SmartSearchDealsState } from '@shared/store/smart-search-deals/smart-search-deals.state';

export const getSmartSearchDealsById = (
  smartSearchDeal: Pick<{ smartSearchId: string; deals: HTTP_CP_SmartSearchDeal[] }, 'smartSearchId'>,
): string => smartSearchDeal.smartSearchId;

export const smartSearchDealsAdapter: EntityAdapter<{
  smartSearchId: string;
  smartSearchDeals: HTTP_CP_SmartSearchDeal[];
  numberOfSmartSearchDeals?: number;
}> = createEntityAdapter<{
  smartSearchId: string;
  smartSearchDeals: HTTP_CP_SmartSearchDeal[];
  numberOfSmartSearchDeals?: number;
}>({
  selectId: getSmartSearchDealsById,
});

const smartSearchDealsInitialState: SmartSearchDealsState = smartSearchDealsAdapter.getInitialState({
  loading: true,
  errors: null,
});

const reducer = createReducer(
  smartSearchDealsInitialState,

  on(getDashboardDataSuccess, (state, dashboardData) => {
    if (!dashboardData.smartSearchIdToDashboardDataForSmartSearchDealsMap) {
      return { ...state, loading: false };
    }
    const map = new Map(Object.entries(dashboardData.smartSearchIdToDashboardDataForSmartSearchDealsMap));
    const entities: {
      smartSearchId: string;
      smartSearchDeals: HTTP_CP_SmartSearchDeal[];
      numberOfSmartSearchDeals?: number;
    }[] = [];
    map.forEach((value, key) =>
      entities.push({
        smartSearchId: key,
        smartSearchDeals: value.smartSearchDeals,
        numberOfSmartSearchDeals: value.numberOfSmartSearchDeals,
      }),
    );
    return smartSearchDealsAdapter.addMany(entities, {
      ...state,
      loading: false,
      errors: null,
    });
  }),

  on(getSmartSearchDeals, (state) => ({
    ...state,
    loading: true,
  })),

  on(getSmartSearchDealsSuccess, (state, { smartSearchDealMap }) =>
    smartSearchDealsAdapter.upsertOne(smartSearchDealMap[0] ? smartSearchDealMap[0] : null, {
      ...state,
      loading: false,
      errors: null,
    }),
  ),

  on(getSmartSearchDealsFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),
);

export function smartSearchDealsReducer(
  state: SmartSearchDealsState = smartSearchDealsInitialState,
  action: Action,
): SmartSearchDealsState {
  return reducer(state, action);
}
