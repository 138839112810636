import { Injectable } from '@angular/core';
import { ENUM_MortgagePartStepType } from '@api-new/common';
import {
  HTTP_CP_UpdateMortgage_Request,
  HTTP_CP_UpdateMortgage_Request_MortgagePart,
  HTTP_CP_UpdateMortgage_Request_MortgagePart_MortgagePartEarlyRepaymentCharge,
  HTTP_CP_UpdateMortgage_Request_MortgagePart_MortgagePartOverpayment,
  HTTP_CP_UpdateMortgage_Request_MortgagePart_MortgagePartStep,
  HTTP_CP_UpdateMortgage_Request_MortgagePart_MortgagePartUnderpayment,
  MortgageApiService,
} from '@api-new/mortgageservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { dateToISOStringWithoutTimezone } from '@shared-lib/utils/date-utils/dateToISOStringWithoutTimezone';
import { Observable, of } from 'rxjs';
import { catchError, debounceTime, filter, map, mergeMap, switchMap, take } from 'rxjs/operators';
import { MORTGAGE_EDIT_SUMMARY_TAB } from '../../common';
import { AppState } from '../../models/app-state.model';
import { CalculationsService } from '../../services/calculations.service';
import { GetLenderAdditionalsAction } from '../actions/lender.action';
import {
  AddMortgageEditRateAction,
  CalculateMortgageRatePaymentsAction,
  CalculateMortgageRatePaymentsSuccessAction,
  DeleteMortgageEditRateAction,
  GetLenderSvrsForMortgageEditAction,
  GetLenderSvrsForMortgageEditFailureAction,
  GetLenderSvrsForMortgageEditSuccessAction,
  SetCurrentMortgageAsEditedAction,
  SetEditedMortgageAction,
  SetMortgageEditCurrentPartRateValueAction,
  SetMortgageEditCurrentPartSVRAction,
  SetMortgageEditCurrentPartValueAction,
  SetMortgageEditMetadataValueAction,
  UploadCurrentEditedMortgage,
} from '../actions/mortgage-edit.action';
import { UpdateMortgageAction } from '../actions/mortgage.action';
import { $currentEditedMortgage, $currentPartCalculationData, $editMortgageSavingDisabled } from '../selectors/mortgage-edit.selectors';
import { $currentMortgage } from '../selectors/mortgage.selectors';

@Injectable()
export class MortgageEditEffect {
  uploadEditedMortgage: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(UploadCurrentEditedMortgage.type),
      switchMap(() =>
        this.store.pipe(
          select($editMortgageSavingDisabled),
          take(1),
          filter((disabled) => !disabled),
          switchMap(() =>
            this.store.pipe(
              select($currentEditedMortgage),
              take(1),
              filter((mortgage) => mortgage != null),
              map((mortgage) => {
                const adjustedMortgage: HTTP_CP_UpdateMortgage_Request = {
                  id: mortgage.id,
                  accountNumber: mortgage.accountNumber,
                  paymentDay: mortgage.paymentDay,
                  mortgageParts: (mortgage.mortgageParts || []).map((part) => {
                    const partRequest: HTTP_CP_UpdateMortgage_Request_MortgagePart = {
                      id: part.id,
                      repaymentType: part.repaymentType,
                      initialAmount: part.initialAmount,
                      mortgagePartEarlyRepaymentCharges: part.mortgagePartEarlyRepaymentCharges?.map((erc) => {
                        const requestErc: HTTP_CP_UpdateMortgage_Request_MortgagePart_MortgagePartEarlyRepaymentCharge = {
                          id: erc.id,
                          mortgagePartEarlyRepaymentChargeType: erc.mortgagePartEarlyRepaymentChargeType,
                          startDate: dateToISOStringWithoutTimezone(erc.startDate),
                          endDate: dateToISOStringWithoutTimezone(erc.endDate),
                          amount: erc.amount,
                        };
                        return requestErc;
                      }),
                      mortgagePartOverpayments: part.mortgagePartOverpayments?.map((overpayment) => {
                        const overpaymentRequest: HTTP_CP_UpdateMortgage_Request_MortgagePart_MortgagePartOverpayment = {
                          id: overpayment.id,
                          mortgagePartOverpaymentType: overpayment.mortgagePartOverpaymentType,
                          startDate: overpayment.startDate,
                          endDate: overpayment.endDate,
                          amount: overpayment.amount,
                        };
                        return overpaymentRequest;
                      }),
                      mortgagePartUnderpayments: part.mortgagePartUnderpayments?.map((underpayment) => {
                        const underpaymentRequest: HTTP_CP_UpdateMortgage_Request_MortgagePart_MortgagePartUnderpayment = {
                          id: underpayment.id,
                          mortgagePartUnderpaymentType: underpayment.mortgagePartUnderpaymentType,
                          startDate: underpayment.startDate,
                          endDate: underpayment.endDate,
                          amount: underpayment.amount,
                        };
                        return underpaymentRequest;
                      }),
                      mortgagePartSteps: (part.mortgagePartSteps || []).map((step) => {
                        const requestStep: HTTP_CP_UpdateMortgage_Request_MortgagePart_MortgagePartStep = {
                          id: step.id,
                          mortgagePartStepType: step.mortgagePartStepType,
                          startDate: dateToISOStringWithoutTimezone(step.startDate),
                          endDate: dateToISOStringWithoutTimezone(step.endDate),
                          variableLenderSvrId:
                            step.mortgagePartStepType === ENUM_MortgagePartStepType.MORTGAGE_PART_STEP_TYPE_VARIABLE
                              ? step.variableLenderSvrId
                              : null,
                          variableInterestRate:
                            step.mortgagePartStepType === ENUM_MortgagePartStepType.MORTGAGE_PART_STEP_TYPE_VARIABLE
                              ? step.fixedInterestRate
                                ? step.fixedInterestRate
                                : step.variableInterestRate
                              : null,
                          fixedInterestRate:
                            step.mortgagePartStepType === ENUM_MortgagePartStepType.MORTGAGE_PART_STEP_TYPE_FIXED
                              ? step.fixedInterestRate
                              : null,
                        };
                        return requestStep;
                      }),
                    };
                    return partRequest;
                  }),
                };
                return new UpdateMortgageAction(adjustedMortgage);
              }),
            ),
          ),
        ),
      ),
    ),
  );

  setEditedMortgage: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(SetCurrentMortgageAsEditedAction.type),
      switchMap(() =>
        this.store.pipe(
          select($currentMortgage),
          take(1),
          filter((mortgage) => mortgage != null),
          mergeMap((mortgage) => [
            new SetEditedMortgageAction(mortgage),
            new GetLenderSvrsForMortgageEditAction(mortgage.lenderId),
            new GetLenderAdditionalsAction(mortgage.lenderId),
            new SetMortgageEditMetadataValueAction('selectedTab', MORTGAGE_EDIT_SUMMARY_TAB),
          ]),
        ),
      ),
    ),
  );

  triggerRatePaymentsCalculation: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(
        SetMortgageEditMetadataValueAction.type,
        SetMortgageEditCurrentPartValueAction.type,
        SetMortgageEditCurrentPartRateValueAction.type,
        SetMortgageEditCurrentPartSVRAction.type,
        AddMortgageEditRateAction.type,
        DeleteMortgageEditRateAction.type,
      ),
      map(() => new CalculateMortgageRatePaymentsAction()),
    ),
  );

  calculateRatePayments: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(CalculateMortgageRatePaymentsAction.type),
      debounceTime(500),
      switchMap(() =>
        this.store.pipe(
          select($currentPartCalculationData),
          take(1),
          filter((data) => data != null && !data.parts[0].rates.some(({ rate }) => rate === 0)),
          switchMap((data) =>
            this.calculationService.calculatedPayment(data).pipe(
              filter((response) => response != null),
              map((response) => new CalculateMortgageRatePaymentsSuccessAction(response.monthlyPayments || [])),
            ),
          ),
        ),
      ),
    ),
  );

  getLenderSvrsForMortgageEdit: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GetLenderSvrsForMortgageEditAction.type),
      switchMap(({ payload }) =>
        this.mortgageApiService.HTTP_CP_GetLender({ id: payload }).pipe(
          map((lender) => new GetLenderSvrsForMortgageEditSuccessAction(lender)),
          catchError((error) => of(new GetLenderSvrsForMortgageEditFailureAction(error))),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly calculationService: CalculationsService,
    private readonly mortgageApiService: MortgageApiService,
  ) {}
}
