import { Injectable } from '@angular/core';
import { MortgageApiService } from '@api-new/mortgageservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import {
  betterDealNotFound,
  getBetterDeal,
  getBetterDealFailure,
  getBetterDeals,
  getBetterDealsFailure,
  getBetterDealsSuccess,
  setSelectedBetterDeal,
} from '@shared/store/better-deal/better-deal.actions';
import { Observable } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, filter, map, switchMap } from 'rxjs/operators';

@Injectable()
export class BetterDealEffects {
  getLatestSearch$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getBetterDeals),
      filter(Boolean),
      switchMap(({ mortgageId, mortgageDeal }) =>
        this.mortgageApiService.HTTP_CP_ListMortgageDeals({ mortgageId, mortgageDeal }).pipe(
          map(({ mortgageDeals }) => getBetterDealsSuccess({ mortgageId, mortgageDeals })),
          catchError((error) => {
            return of(getBetterDealsFailure(error));
          }),
        ),
      ),
    ),
  );

  getBetterDealById$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getBetterDeal),
      filter(Boolean),
      switchMap(({ mortgageId, mortgageDeal }) =>
        this.mortgageApiService.HTTP_CP_ListMortgageDeals({ mortgageId, mortgageDeal }).pipe(
          map((search) => {
            if (search.mortgageDeals?.length >= 1) {
              return setSelectedBetterDeal({ deal: search.mortgageDeals[0], selectedBetterDealIndex: 0 });
            } else {
              return betterDealNotFound();
            }
          }),
          catchError((error) => {
            return of(getBetterDealFailure(error));
          }),
        ),
      ),
    ),
  );

  constructor(
    private readonly mortgageApiService: MortgageApiService,
    private readonly actions$: Actions,
  ) {}
}
