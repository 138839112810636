<p-toast
  position="top-center"
  [showTransitionOptions]="'200ms'"
  [hideTransitionOptions]="'200ms'"
  [showTransformOptions]="'translateY(-100%)'">
  <ng-template let-message pTemplate="message">
    <div class="toast-message">
      <div class="icon-container">
        @if (message.severity === "error") {
          <shared-icon class="toast-message-icon" icon="triangle-exclamation" size="large" />
        }
        @if (message.severity === "info") {
          <shared-icon class="toast-message-icon" icon="notification" size="large" />
        }
        @if (message.severity === "warn") {
          <shared-icon class="toast-message-icon" icon="triangle-exclamation" size="large" />
        }
        @if (message.severity === "success") {
          <shared-icon class="toast-message-icon" icon="circle-check" size="large" />
        }
      </div>
      <div class="toast-message-text">
        @if (message.summary) {
          <p class="summary">{{ message.summary }}</p>
        }
        <p class="toast-message-text-detail">{{ message.detail }}</p>
      </div>
    </div>
  </ng-template>
</p-toast>
