import { HTTP_CP_Company } from '@api-new/companyservice';
import { createSelector } from '@ngrx/store';
import { AppState, CompanyState } from '../../models/app-state.model';
import { CompanyIddModel } from '../../models/broker-details.model';
import { companyInitialState } from './company.reducer';

export const $companyState = (state: AppState): CompanyState => (state && state.OLD_company) || companyInitialState;

export const $company = createSelector($companyState, (state: CompanyState): HTTP_CP_Company | null => state.entity?.company || null);

export const $companyIdds = createSelector($companyState, (state: CompanyState): CompanyIddModel[] => state.entity?.companyIDDs);

export const $companyLogo = createSelector($company, (company: HTTP_CP_Company): string | null => company?.logo || null);

export const $companyTradingName = createSelector($company, (company: HTTP_CP_Company): string | null => company?.tradingName || null);

export const $companyFooter = createSelector($company, (company: HTTP_CP_Company): string => company?.footer ?? '');
