import { Injectable } from '@angular/core';
import { HTTP_CP_Company } from '@api-new/companyservice';
import { Store, select } from '@ngrx/store';
import { filter } from 'rxjs/operators';
import { AppState } from '../models/app-state.model';
import { $company } from '../store/company/company.selectors';

@Injectable({
  providedIn: 'root',
})
export class GlobalColorService {
  company: HTTP_CP_Company;
  defaultColour = 'ffffff';

  constructor(private readonly store: Store<AppState>) {}

  initCompanyColorListener(): void {
    this.store.pipe(select($company), filter(Boolean)).subscribe((company: HTTP_CP_Company) => {
      this.company = company;
      this.setGlobalCompanyCssColours(company.primaryColor);
    });
  }

  private setGlobalCompanyCssColours(colour: string): void {
    const partnerHexColour = colour || this.defaultColour;
    document.documentElement.style.setProperty('--company-color', `#${partnerHexColour}`);
    document.documentElement.style.setProperty('--company-color8', `#${partnerHexColour}CC`);
  }
}
