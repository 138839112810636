import { HTTP_CP_MortgageDeal } from '@api-new/mortgageservice/index';

export enum BestDealFlag {
  BIGGEST_STAVING,
  SAME_LENDER,
  LOWEST_PAYMENT,
}
export interface BestBetterDeal extends HTTP_CP_MortgageDeal {
  flag: BestDealFlag;
}

export interface BestBetterDealViewModel {
  mortgageId: string;
  mortgageDeals: HTTP_CP_MortgageDeal[];
  numberOfMortgageDeals?: number;
  bestDeals?: BestBetterDeal[];
}
