import { CurrencyPipe, PercentPipe } from '@angular/common';
import {
  DATA_AddressLines,
  ENUM_MortgagePartEarlyRepaymentChargeType,
  ENUM_MortgagePartOverpaymentType,
  ENUM_MortgagePartStepType,
  ENUM_MortgagePartUnderpaymentType,
  ENUM_MortgageRepaymentType,
} from '@api-new/common';
import { HTTP_CP_Lender, HTTP_CP_Lender_Svr } from '@api-new/mortgageservice';
import { HTTP_CP_Property } from '@api-new/propertyservice';
import { DateTimeFormats } from '@app/OLD_shared/enums/date-time-formates.enum';
import { PeriodUnits } from '@app/OLD_shared/enums/period-units.enum';
import { MortgageRatePartModel } from '@app/OLD_shared/models/mortgage-rate-part.model';
import { createSelector } from '@ngrx/store';
import { MAX_MORTGAGE_DURATION_IN_MONTHS } from '@shared-lib/constants';
import moment from 'moment';
import {
  MORTGAGE_EDIT_OVERPAYMENTS_TAB,
  MORTGAGE_EDIT_SUMMARY_TAB,
  MORTGAGE_EDIT_UNDERPAYMENTS_TAB,
  getDurationInMonths,
  momentify,
  svrTypeToReadable,
} from '../../common';
import { SelectInterface } from '../../form-components/select/select.component';
import { AppState, MortgageEditState, PropertyState } from '../../models/app-state.model';
import { PaymentCalculatorModel } from '../../models/calculations.model';
import { MortgageErcModel } from '../../models/mortgage-erc.model';
import { MortgagePartModel } from '../../models/mortgage-part.model';
import {
  MortgageEditMenuEnum,
  MortgageEditMetadata,
  MortgageEditPartModel,
  MortgageEditRateModel,
  MortgageEditTabModel,
  MortgageModel,
} from '../../models/mortgage.model';
import { OverpaymentModel, UnderpaymentModel } from '../../models/overpayment.model';
import { $propertyState } from '../property/property.selectors';

const isEveryOverpaymentValid = (part: MortgagePartModel): boolean =>
  (part.mortgagePartOverpayments || []).every(
    (overpayments) =>
      overpayments.amount != null &&
      overpayments.amount > 0 &&
      overpayments.mortgagePartOverpaymentType != null &&
      overpayments.startDate != null &&
      (overpayments.mortgagePartOverpaymentType === ENUM_MortgagePartOverpaymentType.MORTGAGE_PART_OVERPAYMENT_TYPE_ONE_TIME ||
        (overpayments.mortgagePartOverpaymentType === ENUM_MortgagePartOverpaymentType.MORTGAGE_PART_OVERPAYMENT_TYPE_RECURRING &&
          overpayments.endDate != null &&
          moment(overpayments.startDate).isBefore(moment(overpayments.endDate), PeriodUnits.MONTH))),
  );

const isEveryUnderpaymentValid = (part: MortgagePartModel): boolean =>
  (part.mortgagePartUnderpayments || []).every(
    (underpayment) =>
      underpayment.amount != null &&
      underpayment.amount > 0 &&
      underpayment.mortgagePartUnderpaymentType != null &&
      underpayment.startDate != null &&
      (underpayment.mortgagePartUnderpaymentType === ENUM_MortgagePartUnderpaymentType.MORTGAGE_PART_UNDERPAYMENT_TYPE_ONE_TIME ||
        (underpayment.mortgagePartUnderpaymentType === ENUM_MortgagePartUnderpaymentType.MORTGAGE_PART_UNDERPAYMENT_TYPE_RECURRING &&
          underpayment.endDate != null &&
          moment(underpayment.startDate).isBefore(moment(underpayment.endDate), PeriodUnits.MONTH))),
  );

const isEveryRateValid = (part: MortgagePartModel): boolean =>
  (part.mortgagePartSteps || []).every(
    (rate) =>
      rate != null &&
      rate.startDate != null &&
      rate.endDate != null &&
      moment(rate.startDate).isBefore(rate.endDate, PeriodUnits.MONTH) &&
      ((rate.fixedInterestRate != null && rate.fixedInterestRate > 0) ||
        (rate.variableInterestRate != null && rate.variableInterestRate > 0)) &&
      rate.mortgagePartStepType != null &&
      (rate.mortgagePartStepType === ENUM_MortgagePartStepType.MORTGAGE_PART_STEP_TYPE_FIXED ||
        (rate.mortgagePartStepType === ENUM_MortgagePartStepType.MORTGAGE_PART_STEP_TYPE_VARIABLE && rate.variableLenderSvrId != null)),
  );

const isEveryERCValid = (part: MortgagePartModel): boolean =>
  (part.mortgagePartEarlyRepaymentCharges || []).every(
    (erc) =>
      erc != null &&
      erc.amount != null &&
      erc.amount > 0 &&
      erc.mortgagePartEarlyRepaymentChargeType != null &&
      erc.startDate != null &&
      erc.endDate != null,
  );

export const $mortgageEditState = (state: AppState): MortgageEditState => state.OLD_mortgageEdit;

export const $mortgageEditMetadata = createSelector($mortgageEditState, (state: MortgageEditState): MortgageEditMetadata => state.metadata);

export const $currentEditedMortgage = createSelector($mortgageEditState, (state: MortgageEditState): MortgageModel => state.entity || null);

export const $editedMortgageLender = createSelector(
  $mortgageEditState,
  (state: MortgageEditState): HTTP_CP_Lender => state.metadata.lender,
);

export const $editedMortgageLenderSVRs = createSelector(
  $mortgageEditState,
  (state: MortgageEditState): HTTP_CP_Lender_Svr[] => state.metadata.lender?.svrs,
);

export const $editedMortgageLenderSVROptions = createSelector(
  $editedMortgageLenderSVRs,
  (svrs: HTTP_CP_Lender_Svr[]): SelectInterface<string>[] => {
    const percentPipe = new PercentPipe('en-US');
    return (svrs || []).map((item: HTTP_CP_Lender_Svr) => ({
      value: item.id,
      label: `${svrTypeToReadable(item.type)} (${percentPipe.transform(item.currentRate || 0, '1.2-2')})`,
    }));
  },
);

export const $editedMortgageLenderName = createSelector(
  $mortgageEditState,
  (state: MortgageEditState): string => state.metadata.lender?.name ?? 'Unknown lender',
);

export const $editedMortgageProperty = createSelector(
  $currentEditedMortgage,
  $propertyState,
  (mortgage: MortgageModel, propertyState: PropertyState): HTTP_CP_Property =>
    (propertyState && propertyState.entities && propertyState.entities[(mortgage && mortgage.propertyId) || null]) || null,
);

export const $editedMortgagePropertyAddress = createSelector(
  $editedMortgageProperty,
  (property: HTTP_CP_Property): DATA_AddressLines => property && property.addressLines,
);

export const $editedMortgageParts = createSelector($currentEditedMortgage, (mortgage: MortgageModel): MortgagePartModel[] =>
  ((mortgage && mortgage.mortgageParts) || [])
    .filter((part) => part != null)
    .map((part) => ({
      ...part,
      mortgagePartSteps: part.mortgagePartSteps.slice().sort((a, b) => moment(a.startDate).unix() - moment(b.startDate).unix()),
      mortgagePartOverpayments: part.mortgagePartOverpayments || [],
    })),
);

export const $editedMortgageMenuItems = createSelector(
  $editedMortgageParts,
  (mortgageParts: MortgagePartModel[]): MortgageEditTabModel[] => [
    MORTGAGE_EDIT_SUMMARY_TAB,
    MORTGAGE_EDIT_OVERPAYMENTS_TAB,
    MORTGAGE_EDIT_UNDERPAYMENTS_TAB,
    ...(mortgageParts || []).map((_, i) => ({
      label: `part ${i + 1}`,
      type: MortgageEditMenuEnum.MORTGAGE_PART,
      partIndex: i,
      index: i + 3,
    })),
  ],
);

export const $mortgageEditSelectedTab = createSelector(
  $mortgageEditMetadata,
  (metadata: MortgageEditMetadata): MortgageEditTabModel => metadata.selectedTab || MORTGAGE_EDIT_SUMMARY_TAB,
);

export const $mortgageEditSelectedTabPartIndex = createSelector($mortgageEditSelectedTab, (currentTab: MortgageEditTabModel): number => {
  if (currentTab == null || currentTab.type === MortgageEditMenuEnum.MORTGAGE_SUMMARY || currentTab.partIndex == null) {
    return null;
  }
  return currentTab.partIndex;
});

export const $mortgageEditAddingPart = createSelector(
  $mortgageEditMetadata,
  (metadata: MortgageEditMetadata): boolean => !!metadata.addingPart,
);

export const $editedMortgageCurrentPart = createSelector(
  $editedMortgageParts,
  $mortgageEditSelectedTabPartIndex,
  (mortgageParts: MortgagePartModel[], currentPartIndex: number): MortgagePartModel => {
    if (mortgageParts == null || currentPartIndex == null) {
      return null;
    }
    return mortgageParts[currentPartIndex];
  },
);

/**
 * Simpler model, that copies actual edit form structure.
 * Original model is too complex, requiring some attributes to be calculated on the go.
 */
export const $editedMortgageCurrentPartForm = createSelector(
  $editedMortgageCurrentPart,
  $editedMortgageLenderSVRs,
  (part: MortgagePartModel, svrs: HTTP_CP_Lender_Svr[]): MortgageEditPartModel =>
    part == null
      ? null
      : {
          ...part,
          startDate: part.startDate,
          endDate: momentify(part.endDate),
          mortgagePartSteps: (part.mortgagePartSteps || []).map((rate) => ({
            ...rate,
            startDate: momentify(rate.startDate),
            endDate: momentify(rate.endDate),
            termMonths: getDurationInMonths(rate.startDate, rate.endDate),
            fixedInterestRate: rate.fixedInterestRate ? rate.fixedInterestRate : rate.variableInterestRate,
            intervalString:
              `${rate.startDate ? moment(rate.startDate).format(DateTimeFormats.ddMMYYYY_SLASH) : 'N/A'} - ` +
              `${rate.endDate ? moment(rate.endDate).format(DateTimeFormats.ddMMYYYY_SLASH) : 'N/A'}`,
          })),
        },
);

export const $editedMortgageCurrentPartRatesForm = createSelector(
  $editedMortgageCurrentPartForm,
  (partForm: MortgageEditPartModel): MortgageEditRateModel[] => (partForm && partForm.mortgagePartSteps) || [],
);

export const $editedMortgageCurrentPartStartDate = createSelector(
  $editedMortgageCurrentPart,
  (part: MortgagePartModel): moment.Moment => momentify(part && part.startDate),
);

export const $editedMortgageCurrentPartEndDate = createSelector(
  $editedMortgageCurrentPart,
  (part: MortgagePartModel): moment.Moment => momentify(part && part.endDate),
);

export const $editedMortgageCurrentPartERCBoundaries = createSelector(
  $editedMortgageCurrentPartStartDate,
  $editedMortgageCurrentPartEndDate,
  (startDate: moment.Moment, endDate: moment.Moment): { startDate: moment.Moment; endDate: moment.Moment } => ({
    startDate,
    endDate,
  }),
);

export const $enableMortgagePartRemoval = createSelector($mortgageEditSelectedTab, (currentTab: MortgageEditTabModel): boolean =>
  currentTab && currentTab.partIndex ? currentTab.partIndex >= 1 : false,
);

export const $editedMortgageAccountNumber = createSelector(
  $currentEditedMortgage,
  (mortgage: MortgageModel): string => (mortgage && mortgage.accountNumber) || 'unknown',
);

export const $editedMortgageCurrentPartERCs = createSelector(
  $editedMortgageCurrentPart,
  (part: MortgagePartModel): MortgageErcModel[] => (part && part.mortgagePartEarlyRepaymentCharges) || [],
);

export const $editedMortgageCurrentPartCurrentERC = createSelector(
  $editedMortgageCurrentPartERCs,
  (ercs: MortgageErcModel[]): MortgageErcModel =>
    (ercs || []).find((item) => moment().isBetween(moment(item.startDate), moment(item.endDate), PeriodUnits.DAY)) || null,
);

export const $editedMortgageCurrentPartCurrentERCPayment = createSelector(
  $editedMortgageCurrentPartCurrentERC,
  $editedMortgageCurrentPart,
  (currentERC: MortgageErcModel, mortgagePart: MortgagePartModel): string => {
    if (currentERC == null) {
      return null;
    }
    const value: number =
      currentERC.mortgagePartEarlyRepaymentChargeType ===
      ENUM_MortgagePartEarlyRepaymentChargeType.MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_PERCENTAGE_OF_REPAID_AMOUNT
        ? (currentERC.amount || 0) * (mortgagePart?.currentOutstandingBalance || 0)
        : currentERC.amount || 0;
    const currencyPipe = new CurrencyPipe('en-US');
    return currencyPipe.transform(value, 'GBP', 'symbol', '1.0-0');
  },
);

export const $editedMortgageOverpayments = createSelector(
  $currentEditedMortgage,
  $mortgageEditSelectedTabPartIndex,
  (mortgage: MortgageModel, selectedPartIndex: number): OverpaymentModel[] => {
    if (mortgage == null || mortgage.mortgageParts == null || selectedPartIndex == null) {
      return [];
    }
    return mortgage.mortgageParts[selectedPartIndex].mortgagePartOverpayments || [];
  },
);

export const $editedMortgageUnderpayments = createSelector(
  $currentEditedMortgage,
  $mortgageEditSelectedTabPartIndex,
  (mortgage: MortgageModel, selectedPartIndex: number): UnderpaymentModel[] => {
    if (mortgage == null || mortgage.mortgageParts == null || selectedPartIndex == null) {
      return [];
    }
    return mortgage.mortgageParts[selectedPartIndex].mortgagePartUnderpayments || [];
  },
);

export const $editedMortgageFirstPartStartDate = createSelector(
  $currentEditedMortgage,
  (mortgage: MortgageModel): moment.Moment => momentify(mortgage.mortgageParts[0].startDate),
);

export const $editedMortgageFirstPartEndDate = createSelector(
  $currentEditedMortgage,
  (mortgage: MortgageModel): moment.Moment => moment(mortgage.mortgageParts[0].endDate).subtract(1, 'day'),
);

export const $currentPartCalculationData = createSelector($editedMortgageCurrentPart, (part: MortgagePartModel): PaymentCalculatorModel => {
  if (part == null) {
    return null;
  }

  return {
    parts: [
      {
        rates: part.mortgagePartSteps.map((rate) => ({
          ends: moment(rate.endDate).diff(moment(part.mortgagePartSteps[0].startDate), PeriodUnits.MONTH),
          rate: rate.fixedInterestRate ? rate.fixedInterestRate : getEffectiveRate(rate.effectiveInterestRates)?.interestRate ?? 0,
        })),
        interestRateSteps: part.mortgagePartSteps.map((rate) => ({
          numberOfMonths: Math.round(moment(rate.endDate).diff(moment(rate.startDate), PeriodUnits.MONTH, true)),
          interestRate: rate.fixedInterestRate ? rate.fixedInterestRate : getEffectiveRate(rate.effectiveInterestRates)?.interestRate,
        })),

        overpayments: part.mortgagePartOverpayments.map((overpayment) => ({
          amount: overpayment.amount,
          length:
            overpayment.mortgagePartOverpaymentType === ENUM_MortgagePartOverpaymentType.MORTGAGE_PART_OVERPAYMENT_TYPE_ONE_TIME
              ? 1
              : moment(overpayment.endDate).diff(moment(overpayment.startDate), PeriodUnits.MONTH),
          start: moment(overpayment.endDate).diff(moment(part.mortgagePartSteps[0].startDate), PeriodUnits.MONTH),
        })),
        initialAmount: part.initialAmount,
        interestOnly: part.repaymentType === ENUM_MortgageRepaymentType.MORTGAGE_REPAYMENT_TYPE_INTEREST_ONLY,
      },
    ],
  };
});

export const $currentPartRatePayments = createSelector(
  $mortgageEditSelectedTabPartIndex,
  $mortgageEditMetadata,
  (currentPartIndex: number, mortgageEditMetadata: MortgageEditMetadata): number[] => {
    if (currentPartIndex == null || mortgageEditMetadata == null || mortgageEditMetadata.ratePayments == null) {
      return [];
    }
    return mortgageEditMetadata.ratePayments[currentPartIndex] || [];
  },
);

export const $editMortgageSavingDisabled = createSelector($editedMortgageParts, (parts: MortgagePartModel[]): boolean =>
  (parts || []).some((part) => {
    const isPartValid =
      part.startDate != null &&
      part.termMonths != null &&
      part.termMonths > 0 &&
      part.termMonths <= MAX_MORTGAGE_DURATION_IN_MONTHS &&
      part.initialAmount != null &&
      part.initialAmount > 0 &&
      part.initialAmount < 10 ** 8;

    return !(
      isPartValid &&
      isEveryOverpaymentValid(part) &&
      isEveryUnderpaymentValid(part) &&
      isEveryRateValid(part) &&
      isEveryERCValid(part)
    );
  }),
);

const getEffectiveRate = (effectiveInterestRates: MortgageRatePartModel[]): MortgageRatePartModel => {
  const effectiveInterestRate = effectiveInterestRates?.find(
    (effectiveInterestRate) =>
      moment().isAfter(moment(effectiveInterestRate.startDate)) && moment().isBefore(moment(effectiveInterestRate.endDate)),
  );
  return effectiveInterestRates?.length
    ? effectiveInterestRate
      ? effectiveInterestRate
      : effectiveInterestRates[effectiveInterestRates?.length - 1]
    : null;
};
