// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_MortgageClosedReason.proto

export enum ENUM_MortgageClosedReason {
  /** MORTGAGE_CLOSED_REASON_UNSPECIFIED - ============================= 461x ---> MORTGAGE_CLOSED_REASON_UNSPECIFIED */
  MORTGAGE_CLOSED_REASON_UNSPECIFIED = 'MORTGAGE_CLOSED_REASON_UNSPECIFIED',
  /** MORTGAGE_CLOSED_REASON_NOT_CLOSED - ================================ 0x ---> - */
  MORTGAGE_CLOSED_REASON_NOT_CLOSED = 'MORTGAGE_CLOSED_REASON_NOT_CLOSED',
  /** MORTGAGE_CLOSED_REASON_SOLD_PROPERTY_NEW_MORTGAGE - ================ 1x ---> MORTGAGE_CLOSED_REASON_UNSPECIFIED */
  MORTGAGE_CLOSED_REASON_SOLD_PROPERTY_NEW_MORTGAGE = 'MORTGAGE_CLOSED_REASON_SOLD_PROPERTY_NEW_MORTGAGE',
  /** MORTGAGE_CLOSED_REASON_SOLD_PROPERTY_OLD_MORTGAGE - ================ 3x ---> MORTGAGE_CLOSED_REASON_UNSPECIFIED */
  MORTGAGE_CLOSED_REASON_SOLD_PROPERTY_OLD_MORTGAGE = 'MORTGAGE_CLOSED_REASON_SOLD_PROPERTY_OLD_MORTGAGE',
  /** MORTGAGE_CLOSED_REASON_REMORTGAGE_SAME_LENDER - ================ 2 023x ---> MORTGAGE_CLOSED_REASON_REMORTGAGED */
  MORTGAGE_CLOSED_REASON_REMORTGAGE_SAME_LENDER = 'MORTGAGE_CLOSED_REASON_REMORTGAGE_SAME_LENDER',
  /** MORTGAGE_CLOSED_REASON_REMORTGAGE_NEW_LENDER - ================= 1 529x ---> MORTGAGE_CLOSED_REASON_REMORTGAGED */
  MORTGAGE_CLOSED_REASON_REMORTGAGE_NEW_LENDER = 'MORTGAGE_CLOSED_REASON_REMORTGAGE_NEW_LENDER',
  /** MORTGAGE_CLOSED_REASON_PAID_OFF - ============================== 2 158x ---> MORTGAGE_CLOSED_REASON_REPAID */
  MORTGAGE_CLOSED_REASON_PAID_OFF = 'MORTGAGE_CLOSED_REASON_PAID_OFF',
  /** MORTGAGE_CLOSED_REASON_NEW_PROPERTY_WITH_INVESTMENT - ============== 0x ---> - */
  MORTGAGE_CLOSED_REASON_NEW_PROPERTY_WITH_INVESTMENT = 'MORTGAGE_CLOSED_REASON_NEW_PROPERTY_WITH_INVESTMENT',
  /** MORTGAGE_CLOSED_REASON_PROPERTY_SOLD - ========================= 5 500x ---> MORTGAGE_CLOSED_REASON_UNSPECIFIED */
  MORTGAGE_CLOSED_REASON_PROPERTY_SOLD = 'MORTGAGE_CLOSED_REASON_PROPERTY_SOLD',
  /** MORTGAGE_CLOSED_REASON_CLIENT_LOST - ============================== 35x ---> MORTGAGE_CLOSED_REASON_UNSPECIFIED */
  MORTGAGE_CLOSED_REASON_CLIENT_LOST = 'MORTGAGE_CLOSED_REASON_CLIENT_LOST',
  /** MORTGAGE_CLOSED_REASON_CLOSED_WITH_NEW_PROPERTY - =============== 102x ---> MORTGAGE_CLOSED_REASON_UNSPECIFIED */
  MORTGAGE_CLOSED_REASON_CLOSED_WITH_NEW_PROPERTY = 'MORTGAGE_CLOSED_REASON_CLOSED_WITH_NEW_PROPERTY',
  /** MORTGAGE_CLOSED_REASON_CLIENT_USER_IMPORT_REMORTGAGE - ======== 1 078x ---> MORTGAGE_CLOSED_REASON_REMORTGAGED */
  MORTGAGE_CLOSED_REASON_CLIENT_USER_IMPORT_REMORTGAGE = 'MORTGAGE_CLOSED_REASON_CLIENT_USER_IMPORT_REMORTGAGE',
  /** MORTGAGE_CLOSED_REASON_CLIENT_USER_IMPORT_FORCED_UPDATE - === 221 069x ---> DELETED */
  MORTGAGE_CLOSED_REASON_CLIENT_USER_IMPORT_FORCED_UPDATE = 'MORTGAGE_CLOSED_REASON_CLIENT_USER_IMPORT_FORCED_UPDATE',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
