import { HTTP_CP_SmartSearchCase } from '@api-new/caseservice';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import {
  addSmartSearchCase,
  getSmartSearchCases,
  getSmartSearchCasesFailure,
  getSmartSearchCasesSuccess,
  removeSmartSearchCase,
} from '@shared/store/smart-search-cases/smart-search-cases.actions';
import { SmartSearchCasesState } from '@shared/store/smart-search-cases/smart-search-cases.state';

export const getSmartSearchCaseById = (smartSearchCase: Pick<HTTP_CP_SmartSearchCase, 'id'>): string => smartSearchCase.id;

export const smartSearchCasesAdapter: EntityAdapter<HTTP_CP_SmartSearchCase> = createEntityAdapter<HTTP_CP_SmartSearchCase>({
  selectId: getSmartSearchCaseById,
});

export const smartSearchCasesInitialState: SmartSearchCasesState = smartSearchCasesAdapter.getInitialState({
  loading: true,
  errors: null,
});

const reducer = createReducer(
  smartSearchCasesInitialState,

  on(getSmartSearchCases, (state) => ({
    ...state,
    loading: true,
  })),

  on(getSmartSearchCasesSuccess, (state, { smartSearchCases }) =>
    smartSearchCasesAdapter.setMany(smartSearchCases ? smartSearchCases : [], {
      ...state,
      loading: false,
      errors: null,
    }),
  ),

  on(getSmartSearchCasesFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),

  on(addSmartSearchCase, (state, { smartSearchCase }) => smartSearchCasesAdapter.addOne(smartSearchCase, state)),

  on(removeSmartSearchCase, (state, { smartSearchCaseId }) => smartSearchCasesAdapter.removeOne(smartSearchCaseId, state)),
);

export function smartSearchCasesReducer(
  state: SmartSearchCasesState = smartSearchCasesInitialState,
  action: Action,
): SmartSearchCasesState {
  return reducer(state, action);
}
