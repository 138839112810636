import { EnvironmentModel } from '@app/OLD_shared/models/environment.model';
import { FeatureFlag } from '@shared/models/feature-config.model';

export const environment: EnvironmentModel = {
  production: true,
  urls: { apiUrl: 'https://api.dashly.com' },
  sentryDSN: 'https://bd1aab99a16b44338ef222c2676f472c@sentry.io/1470764',
  googleCloudPlatformApiKey: 'AIzaSyCPNENGzQewO8-zubI6JB8fsoB2fxiS4O4',
  signUpUrl: 'https://welcome.dashly.com',
  featureConfig: {
    [FeatureFlag.LANDCLAN_ADDRESS]: false,
    [FeatureFlag.CREDIT_REPORT_SETTINGS]: false,
    [FeatureFlag.ADD_SMART_SEARCH_FORM]: false,
  },
};
