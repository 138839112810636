import { CdkOverlayOrigin } from '@angular/cdk/overlay';
import { PercentPipe } from '@angular/common';
import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  Output,
  ViewChild,
  forwardRef,
} from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { InputVariantType } from '../../models/base.model';

const INVALID_KEYS = ['e', 'E'];

@Component({
  selector: 'app-input',
  templateUrl: './input.component.html',
  styleUrls: ['./input.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputComponent),
      multi: true,
    },
  ],
})
export class InputComponent implements AfterViewInit {
  @ViewChild('inputElement', { static: true }) inputElement: ElementRef<HTMLInputElement>;

  // TODO (Michal D.): 23/03/2021 - convert type to enum // (Marek): Please don't
  @Input() type: 'text' | 'number' | 'percent' | 'currency' | 'password' | 'email' = 'text';
  @Input() variant: InputVariantType = 'default';

  @Input() pattern = '.*';
  @Input() placeholder = '';
  @Input() label: string;
  @Input() name: string;
  @Input() tooltipId: string;
  @Input() min: number; // only use with number type, will be ignored otherwise
  @Input() max: number; // only use with number type, will be ignored otherwise

  @Input() disabled = false;
  @Input() warnState = false;
  @Input() sensitive = false;

  @Output() enterSubmit = new EventEmitter<void>();
  @Output() inputFocus = new EventEmitter<boolean>();

  @Input() inputValue: any;
  @Output() inputValueChange = new EventEmitter<any>();

  @Input() prefix: string;
  @Input() suffix: string;
  @Input() fsBlock = false;

  @Input() disableAutoComplete = false;

  @Input() inputId = `input_id_${String(Math.random()).substr(2)}`;

  /**
   * This variable is here for fixing bug
   * where disabling autofilling was  ignored bz majority of browsers
   */
  isFocused = false;

  @ViewChild('tooltipOrigin') tooltipOrigin: CdkOverlayOrigin;

  set value(newValue: any) {
    let value: any;
    switch (this.type) {
      case 'percent': {
        const sanitizedPercent = newValue.replace(/[^0-9.]+/g, '');
        value = sanitizedPercent === '' ? null : parseFloat(sanitizedPercent) / 100;
        break;
      }
      case 'currency': {
        const sanitizedCurrency = newValue.replace(/[^0-9]+/g, '');
        value = sanitizedCurrency ? parseInt(sanitizedCurrency, 10) : null;
        break;
      }
      case 'number':
        // to ensure that value is number, not string
        value = newValue ? parseInt(newValue, 10) : null;
        break;
      case 'text':
        value = newValue.trim();
        break;

      default:
        value = newValue;
    }
    this.inputValue = value;
    this.inputValueChange.emit(value);
    this.changeCallback(value);
  }

  get value(): any {
    if (this.inputValue == null || this.inputValue === 'not_set' || this.inputValue === '') {
      return '';
    }
    switch (this.type) {
      case 'percent': {
        if (typeof this.inputValue !== 'number' || isNaN(this.inputValue)) {
          return 0;
        }
        const result = this.percentPipe.transform(this.inputValue, '1.0-3');
        return parseFloat(result.substr(0, result.length - 1).replace(',', ''));
      }
      case 'currency':
        return Math.round(this.inputValue).toLocaleString('en-UK');

      default:
        return `${this.inputValue}`;
    }
  }

  sensitiveShown = false;

  changeCallback: (value: any) => void = () => null;
  touchCallback: () => void = () => null;

  constructor(
    private readonly percentPipe: PercentPipe,
    private readonly cd: ChangeDetectorRef,
  ) {}

  ngAfterViewInit(): void {
    if (this.disableAutoComplete) {
      this.inputElement.nativeElement.setAttribute('readonly', 'readonly');
    }
  }

  onClick(): void {
    if (this.type === 'currency' && this.value === '0') {
      this.inputElement.nativeElement.select();
    }
  }

  focusInput(): void {
    this.cd.detectChanges();
    this.inputElement.nativeElement.focus();
  }

  onFocus(): void {
    this.inputFocus.emit(true);

    // following piece of code ensures that autofill is disabled by
    // setting readonly attribute
    if (this.disableAutoComplete && this.inputElement.nativeElement.hasAttribute('readonly')) {
      this.inputElement.nativeElement.removeAttribute('readonly');
      this.inputElement.nativeElement.blur();
      this.inputElement.nativeElement.focus();
    }
    this.isFocused = true;
  }

  onBlur(): void {
    this.inputFocus.emit(false);
    this.touched();

    // following piece of code ensures that autofill is disabled by
    // setting readonly attribute
    if (this.disableAutoComplete && !this.inputElement.nativeElement.hasAttribute('readonly') && this.isFocused) {
      this.isFocused = false;
      this.inputElement.nativeElement.setAttribute('readonly', 'readonly');
      this.inputElement.nativeElement.blur();
    }
  }

  changeVisibility(): void {
    this.sensitiveShown = !this.sensitiveShown;
  }

  filterInput(event: KeyboardEvent): boolean {
    if (this.inputValue && this.inputValue.length === 0 && event.key === ' ') {
      return false;
    }
    if (this.type !== 'number' && this.type !== 'percent') {
      return true;
    }
    return !INVALID_KEYS.find((key) => key === event.key);
  }

  writeValue(value: any): void {
    this.cd.markForCheck();
    this.inputValue = value;
  }

  registerOnChange(fn: (value: any) => void): void {
    this.changeCallback = fn;
  }

  registerOnTouched(fn: () => void): void {
    this.touchCallback = fn;
  }

  setDisabledState(disabled: boolean): void {
    this.disabled = disabled;
  }

  touched(): void {
    if (this.touchCallback) {
      this.touchCallback();
    }
  }

  onPaste(event: ClipboardEvent | null): void {
    event.preventDefault();
    event.stopPropagation();
    this.value = (event.clipboardData?.getData('text') || '').trim();
  }
}
