import { AsyncPipe } from '@angular/common';
import { ChangeDetectorRef, Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Meta } from '@angular/platform-browser';
import { ActivatedRoute, Params, Router, RouterOutlet } from '@angular/router';
import { AuthService } from '@shared/services/auth.service';
import { AuthFacadeService } from './auth-facade.service';

@Component({
  selector: 'cp-auth',
  templateUrl: './auth.component.html',
  standalone: true,
  providers: [AuthFacadeService],
  imports: [AsyncPipe, RouterOutlet],
  styleUrls: ['./auth.component.scss'],
})
export class AuthComponent {
  readonly isLoginFromParamsLoading$ = this.facade.isLoginFromParamsLoading$;

  // TODO: Remove this when we have a proper way to detect MAB domain
  protected isMabDomain = false;

  constructor(
    private readonly facade: AuthFacadeService,
    private readonly router: Router,
    private readonly route: ActivatedRoute,
    private readonly changeDetectorRef: ChangeDetectorRef,
    private readonly meta: Meta,
    private readonly authService: AuthService,
  ) {
    this.meta.updateTag({ name: 'viewport', content: 'width=device-width, initial-scale=1' });
    const params = this.route.snapshot.queryParams;
    if (params['token']) {
      this.loginWithToken(params);
    } else if (params['auth']) {
      this.loginWithMagicLink(params);
    }
    // TODO: Remove this when we have a proper way to detect MAB domain
    if (location.hostname.includes('mortgageadvicebureau')) {
      this.isMabDomain = true;
    }
    this.router.events.pipe(takeUntilDestroyed()).subscribe(() => this.changeDetectorRef.detectChanges());
  }

  private loginWithMagicLink(params: Params): void {
    const decodedParams = new URLSearchParams(atob(params['auth']));
    const email = decodedParams.get('email');
    const code = decodedParams.get('code');
    const redirectUrl = decodedParams.get('redirectUrl');

    if (code && email) {
      this.facade.loginMagicLink(email, code, redirectUrl);
    }
  }

  private loginWithToken(params: Params): void {
    this.authService.logout();
    this.authService.setImpersonated();
    this.facade.loginWithToken(params['token'], params['link']);
  }
}
