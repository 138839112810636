import { ChangeDetectionStrategy, Component } from '@angular/core';
import { ButtonModule } from 'primeng/button';
import { ToastModule } from 'primeng/toast';
import { IconComponent } from '../icon/icon.component';

@Component({
  selector: 'shared-toast',
  templateUrl: './shared-toast.component.html',
  styleUrls: ['./shared-toast.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  standalone: true,
  imports: [ToastModule, ButtonModule, IconComponent],
})
export class SharedToastComponent {}
