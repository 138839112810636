import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BouncingBallsComponent } from './bouncing-balls.component';

@NgModule({
  imports: [CommonModule],
  declarations: [BouncingBallsComponent],
  exports: [BouncingBallsComponent],
})
export class BouncingBallsModule {}
