import { HTTP_CP_SmartSearch } from '@api-new/mortgageservice';
import { createSelector } from '@ngrx/store';
import { paths } from '@platform/paths';
import { AppState, MortgageState } from '../../models/app-state.model';
import { AccountsMetadata, MortgageModel } from '../../models/mortgage.model';
import { selectPreparedNewMortgageCase } from '../mortgage-case/mortgage-case.selectors';

export const $mortgageState = (state: AppState): MortgageState => state.OLD_mortgage;

export const $mortgageList = createSelector($mortgageState, (state: MortgageState) =>
  Object.keys(state.entities).reduce((acc, item) => [...acc, state.entities[item]], []),
);

export const $mortgagesLoaded = createSelector($mortgageState, (state: MortgageState) => state.loaded);

export const $mortgagesLoading = createSelector($mortgageState, (state: MortgageState) => state.loading);

export const $mortgageByPropertyId = (id: string) =>
  createSelector($mortgageState, (state: MortgageState) =>
    state.entities != null ? state.entities[Object.keys(state.entities).find((item) => state.entities[item].propertyId === id)] : null,
  );

export const $mortgageById = (id: string) =>
  createSelector($mortgageState, (state: MortgageState) => (state.entities != null ? state.entities[id] : null));

export const $mortgageByPreparedNewApplication = createSelector(
  $mortgageState,
  selectPreparedNewMortgageCase,
  (state, mortgageCase: any): MortgageModel => state.entities != null && mortgageCase != null && state.entities[mortgageCase.fkMortgage],
);

export const clientUserData$ = createSelector(
  $mortgageState,
  (state: MortgageState): HTTP_CP_SmartSearch[] => state.metadata.smartSearches,
);

export const $accountsMetadata = createSelector(
  $mortgageState,
  (state: MortgageState): AccountsMetadata => state.metadata.accountsMetadata,
);

export const $accountsDealHash = createSelector($accountsMetadata, (metadata: AccountsMetadata): string => metadata.dealHash);

export const $accountsDetailId = createSelector($accountsMetadata, (metadata: AccountsMetadata): string => metadata.detailId);

export const $accountsDetailType = createSelector($accountsMetadata, (metadata: AccountsMetadata): string => metadata.detailType);

export const $currentMortgage = createSelector(
  $mortgageState,
  $accountsDetailId,
  $accountsDetailType,
  (mortgageState: MortgageState, detailId: string, detailType: string): MortgageModel =>
    detailType === paths.platform.mortgages.SEARCH_RESULTS ? null : mortgageState.entities[detailId] || null,
);
