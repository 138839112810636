import { HTTP_CP_Company } from '@api-new/companyservice';
import { createSelector } from '@ngrx/store';
import { AffiliateState, AppState } from '../../models/app-state.model';
import { affiliateInitialState } from './affiliate.reducer';

export const $affiliateState = (state: AppState): AffiliateState => (state && state.OLD_affiliate) || affiliateInitialState;

export const $affiliate = createSelector($affiliateState, (state: AffiliateState): HTTP_CP_Company | null => state.entity || null);

export const $affiliateLogo = createSelector(
  $affiliate,
  (affiliate: HTTP_CP_Company): string | null => (affiliate && affiliate.logo) || null,
);
