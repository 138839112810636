import { isSimpleObject } from './isSimpleObject';

export const explodeObjectIntoQueryParams = (params: any, sourceKey: string = '', explodedParams: any = {}): any => {
  for (const key of Object.keys(params)) {
    if (isSimpleObject(params[key])) {
      explodeObjectIntoQueryParams(params[key], sourceKey ? `${sourceKey}.${key}` : key, explodedParams);
    } else {
      explodedParams[sourceKey ? `${sourceKey}.${key}` : key] = params[key];
    }
  }
  return explodedParams;
};
