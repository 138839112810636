import { CommonModule } from '@angular/common';
import { ErrorHandler, NgModule } from '@angular/core';
import { ChunkLoadErrorHandler } from './chunk-load-error-handler.service';
import { ERROR_HANDLERS, GlobalErrorHandler } from './global-error-handler';
import { SentryErrorHandler } from './sentry-error-handler';

@NgModule({
  imports: [CommonModule],
  providers: [
    {
      provide: ERROR_HANDLERS,
      useClass: ChunkLoadErrorHandler,
      multi: true,
    },
    {
      provide: ERROR_HANDLERS,
      useClass: SentryErrorHandler,
      multi: true,
    },

    { provide: ErrorHandler, useClass: GlobalErrorHandler },
  ],
})
export class ErrorHandlerModule {}
