// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_MortgagePartEarlyRepaymentChargeType.proto

export enum ENUM_MortgagePartEarlyRepaymentChargeType {
  MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_UNSPECIFIED = 'MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_UNSPECIFIED',
  MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_FIXED_AMOUNT = 'MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_FIXED_AMOUNT',
  MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_PERCENTAGE_OF_INITIAL_AMOUNT = 'MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_PERCENTAGE_OF_INITIAL_AMOUNT',
  MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_PERCENTAGE_OF_REPAID_AMOUNT = 'MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_PERCENTAGE_OF_REPAID_AMOUNT',
  MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_MONTHS_INTEREST = 'MORTGAGE_PART_EARLY_REPAYMENT_CHARGE_TYPE_MONTHS_INTEREST',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
