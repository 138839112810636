import { HTTP_CP_Property } from '@api-new/propertyservice';
import { ErrorModel, PropertyState } from '../../models/app-state.model';
import { PayloadAction } from '../store-utils';
import {
  ClearPropertyWaitingForClose,
  ClosePropertyAction,
  ClosePropertyFailureAction,
  ClosePropertySuccessAction,
  CreatePropertyAction,
  CreatePropertyFailureAction,
  CreatePropertySuccessAction,
  CreatePropertyValuationSuccessAction,
  DeletePropertyValuationSuccessAction,
  GetPropertiesAction,
  GetPropertiesFailureAction,
  GetPropertiesSuccessAction,
  GetSinglePropertyAction,
  GetSinglePropertyFailureAction,
  GetSinglePropertySuccessAction,
  PropertyAction,
  SetPropertyWaitingForClose,
  SetSelectedPropertyId,
  UpdatePropertyAction,
  UpdatePropertyFailureAction,
  UpdatePropertySuccessAction,
} from './property.action';

const initialState: PropertyState = {
  entities: {},
  errors: null,
  loading: false,
  loaded: false,
  metadata: {
    selectedProperty: null,
    selectedToClose: null,
  },
};

export function propertyReducer(state: PropertyState = initialState, action: PropertyAction): PropertyState {
  switch (action.type) {
    case GetPropertiesAction.type:
      return {
        ...state,
        loading: !state.loaded,
      };
    case CreatePropertyAction.type:
    case UpdatePropertyAction.type:
    case ClosePropertyAction.type:
    case GetSinglePropertyAction.type:
      return {
        ...state,
        loading: true,
      };

    case GetPropertiesFailureAction.type:
    case CreatePropertyFailureAction.type:
    case UpdatePropertyFailureAction.type:
    case ClosePropertyFailureAction.type:
    case GetSinglePropertyFailureAction.type:
      return {
        ...state,
        loading: false,
        errors: (action as PayloadAction<ErrorModel>).payload,
      };

    case GetPropertiesSuccessAction.type: {
      const properties: HTTP_CP_Property[] = action.payload;
      return {
        ...state,
        entities: {
          ...(properties || []).reduce(
            (acc: { [id: string]: HTTP_CP_Property }, property) => ({
              ...acc,
              [property.id]: property,
            }),
            {},
          ),
        },
        loading: false,
        loaded: properties != null,
      };
    }

    case GetSinglePropertySuccessAction.type: {
      const property: HTTP_CP_Property = action.payload;
      if (property == null || property.id == null) {
        return {
          ...state,
          loading: false,
        };
      }
      return {
        ...state,
        entities: {
          ...state.entities,
          [property.id]: property,
        },
        loading: false,
      };
    }

    case CreatePropertySuccessAction.type: {
      return {
        ...state,
        loading: false,
      };
    }
    case UpdatePropertySuccessAction.type: {
      const property = (action as PayloadAction<HTTP_CP_Property>).payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          [property.id]: property,
        },
        loading: false,
      };
    }

    case ClosePropertySuccessAction.type: {
      const { [action.payload.id]: removed, ...entities } = state.entities;
      return {
        ...state,
        entities,
        loading: false,
      };
    }

    case SetSelectedPropertyId.type:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          selectedProperty: action.payload,
        },
      };

    case SetPropertyWaitingForClose.type:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          selectedToClose: action.payload,
        },
      };

    case ClearPropertyWaitingForClose.type:
      return {
        ...state,
        metadata: {
          ...state.metadata,
          selectedToClose: null,
        },
      };

    case DeletePropertyValuationSuccessAction.type: {
      const removeValuationResponse = action.payload;

      return {
        ...state,
        entities: {
          ...state.entities,
          [removeValuationResponse.propertyId]: {
            ...state.entities[removeValuationResponse.propertyId],
            roundedCurrentValue: removeValuationResponse.response.currentPropertyValue,
            propertyValues: removeValuationResponse.response.propertyValues,
          },
        },
      };
    }
    case CreatePropertyValuationSuccessAction.type: {
      const newValuation = action.payload;
      return {
        ...state,
        entities: {
          ...state.entities,
          [newValuation.propertyValues[0]?.propertyId]: {
            ...state.entities[newValuation.propertyValues[0]?.propertyId],
            roundedCurrentValue: newValuation.currentPropertyValue,
            propertyValues: newValuation.propertyValues,
          },
        },
      };
    }

    default:
      return state;
  }
}
