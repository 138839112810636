import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Inject, Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Environment } from '../environment/environment';
import { explodeObjectIntoQueryParams } from '../utils/explodeObjectIntoQueryParams';
import { removeNullValuesFromObject } from '../utils/removeNullValuesFromObject';

export interface IRequestOptions {
  headers?: HttpHeaders;
  observe?: 'body';
  params?: any;
  reportProgress?: boolean;
  responseType?: 'json';
  withCredentials?: boolean;
  body?: any;
}

export const applicationHttpClientCreator = (http: HttpClient, environment: Environment) => {
  return new ApplicationHttpClient(http, environment);
};

@Injectable()
export class ApplicationHttpClient {
  constructor(
    public http: HttpClient,
    @Inject(Environment) private environment: Environment,
  ) {}

  get<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    let params;
    if (options?.params) {
      params = removeNullValuesFromObject(explodeObjectIntoQueryParams(options.params));
    }
    return this.http.get<T>(`${this.environment.urls.apiUrl}${endPoint}`, {
      ...options,
      ...{ params: params ? params : null },
    });
  }

  post<T>(endPoint: string, body: Object | undefined, options?: IRequestOptions): Observable<T> {
    return this.http.post<T>(`${this.environment.urls.apiUrl}${endPoint}`, body, options);
  }

  put<T>(endPoint: string, body: Object | undefined, options?: IRequestOptions): Observable<T> {
    return this.http.put<T>(`${this.environment.urls.apiUrl}${endPoint}`, body, options);
  }

  delete<T>(endPoint: string, options?: IRequestOptions): Observable<T> {
    return this.http.delete<T>(`${this.environment.urls.apiUrl}${endPoint}`, options);
  }

  patch<T>(endPoint: string, body: Object | undefined, options?: IRequestOptions): Observable<T> {
    return this.http.patch<T>(`${this.environment.urls.apiUrl}${endPoint}`, body, options);
  }
}
