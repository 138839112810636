import { HTTP_X_ListLenders_Response_Lender } from '@api-new/mortgageservice';
import { createSelector } from '@ngrx/store';
import { BANK_OF_ENGLAND_NAME, divideLenders } from '../../common';
import { AppState, LenderState } from '../../models/app-state.model';

export const $lenderState = (state: AppState): LenderState => state.OLD_lender;

export const $lenderList = createSelector(
  $lenderState,
  (state: LenderState): HTTP_X_ListLenders_Response_Lender[] =>
    Object.keys(state.entities)
      .reduce((acc, item) => [...acc, state.entities[item]], [])
      .filter(({ name }) => name !== BANK_OF_ENGLAND_NAME),
  /*
    ^^ It is not possible to have a loan with Bank of England,
    It is still necessary to have it among lenders because their base rate is used
     */
);

// tslint:disable-next-line:typedef
export const $lenderById = (id: string) =>
  createSelector($lenderState, (state: LenderState): HTTP_X_ListLenders_Response_Lender => state?.entities?.[id] || null);

export const $bankOfEngland = createSelector(
  $lenderState,
  (state: LenderState) => state.entities[Object.keys(state.entities).find((key) => state.entities[key].name === BANK_OF_ENGLAND_NAME)],
);

export const $lendersLoaded = createSelector($lenderState, (state: LenderState) => state.loaded);

export const $lendersError = createSelector(
  $lenderState,
  (state: LenderState): boolean => state.errors != null || (!state.loading && Object.keys(state.entities).length === 0),
);

export const $dividedLenders = createSelector(
  $lenderList,
  (
    lenders: HTTP_X_ListLenders_Response_Lender[],
  ): {
    mainLenders: HTTP_X_ListLenders_Response_Lender[];
    allLenders: HTTP_X_ListLenders_Response_Lender[];
  } => divideLenders(lenders),
);

export const $isAnyLenderExcluded = createSelector($lenderList, (lenders: HTTP_X_ListLenders_Response_Lender[]) =>
  lenders.some((lender) => lender.isExcluded),
);
