// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_PropertyClosedReason.proto

export enum ENUM_PropertyClosedReason {
  /** PROPERTY_CLOSED_REASON_UNSPECIFIED - ========================= 0x ---> - */
  PROPERTY_CLOSED_REASON_UNSPECIFIED = 'PROPERTY_CLOSED_REASON_UNSPECIFIED',
  /** PROPERTY_CLOSED_REASON_NOT_CLOSED - ========================== 0x ---> - */
  PROPERTY_CLOSED_REASON_NOT_CLOSED = 'PROPERTY_CLOSED_REASON_NOT_CLOSED',
  /** PROPERTY_CLOSED_REASON_SOLD_PROPERTY_NEW_MORTGAGE - ========== 1x ---> PROPERTY_CLOSED_REASON_SOLD */
  PROPERTY_CLOSED_REASON_SOLD_PROPERTY_NEW_MORTGAGE = 'PROPERTY_CLOSED_REASON_SOLD_PROPERTY_NEW_MORTGAGE',
  /** PROPERTY_CLOSED_REASON_SOLD_PROPERTY_OLD_MORTGAGE - ========== 4x ---> PROPERTY_CLOSED_REASON_SOLD */
  PROPERTY_CLOSED_REASON_SOLD_PROPERTY_OLD_MORTGAGE = 'PROPERTY_CLOSED_REASON_SOLD_PROPERTY_OLD_MORTGAGE',
  /** PROPERTY_CLOSED_REASON_REMORTGAGE_SAME_LENDER - ============== 0x ---> - */
  PROPERTY_CLOSED_REASON_REMORTGAGE_SAME_LENDER = 'PROPERTY_CLOSED_REASON_REMORTGAGE_SAME_LENDER',
  /** PROPERTY_CLOSED_REASON_REMORTGAGE_NEW_LENDER - =============== 0x ---> - */
  PROPERTY_CLOSED_REASON_REMORTGAGE_NEW_LENDER = 'PROPERTY_CLOSED_REASON_REMORTGAGE_NEW_LENDER',
  /** PROPERTY_CLOSED_REASON_PAID_OFF - ============================ 0x ---> - */
  PROPERTY_CLOSED_REASON_PAID_OFF = 'PROPERTY_CLOSED_REASON_PAID_OFF',
  /** PROPERTY_CLOSED_REASON_NEW_PROPERTY_WITH_INVESTMENT - ======== 0x ---> - */
  PROPERTY_CLOSED_REASON_NEW_PROPERTY_WITH_INVESTMENT = 'PROPERTY_CLOSED_REASON_NEW_PROPERTY_WITH_INVESTMENT',
  /** PROPERTY_CLOSED_REASON_PROPERTY_SOLD - =================== 5 554x ---> PROPERTY_CLOSED_REASON_SOLD */
  PROPERTY_CLOSED_REASON_PROPERTY_SOLD = 'PROPERTY_CLOSED_REASON_PROPERTY_SOLD',
  /** PROPERTY_CLOSED_REASON_CLIENT_LOST - ========================= 0x ---> - */
  PROPERTY_CLOSED_REASON_CLIENT_LOST = 'PROPERTY_CLOSED_REASON_CLIENT_LOST',
  /** PROPERTY_CLOSED_REASON_CLOSED_WITH_NEW_PROPERTY - ========= 124x ---> PROPERTY_CLOSED_REASON_UNSPECIFIED */
  PROPERTY_CLOSED_REASON_CLOSED_WITH_NEW_PROPERTY = 'PROPERTY_CLOSED_REASON_CLOSED_WITH_NEW_PROPERTY',
  /** PROPERTY_CLOSED_REASON_CLIENT_USER_IMPORT_REMORTGAGE - ====== 0x ---> - */
  PROPERTY_CLOSED_REASON_CLIENT_USER_IMPORT_REMORTGAGE = 'PROPERTY_CLOSED_REASON_CLIENT_USER_IMPORT_REMORTGAGE',
  /** PROPERTY_CLOSED_REASON_CLIENT_USER_IMPORT_FORCED_UPDATE - === 0x ---> - */
  PROPERTY_CLOSED_REASON_CLIENT_USER_IMPORT_FORCED_UPDATE = 'PROPERTY_CLOSED_REASON_CLIENT_USER_IMPORT_FORCED_UPDATE',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
