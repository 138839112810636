import { Injectable } from '@angular/core';
import { HTTP_CP_GetMortgageDealComparison_Response, MortgageApiService } from '@api-new/mortgageservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import { GetSavingChartAction, GetSavingChartFailureAction, GetSavingChartSuccessAction } from '../actions/saving-chart.action';

@Injectable()
export class SavingChartEffect {
  getSavingChart$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(GetSavingChartAction.type),
      map((action: GetSavingChartAction) => action.payload),
      switchMap(({ dealHash, request }) =>
        this.mortgageApiService.HTTP_CP_GetMortgageDealComparison(request).pipe(
          map((savingChart: HTTP_CP_GetMortgageDealComparison_Response) => new GetSavingChartSuccessAction({ dealHash, savingChart })),
          catchError((error) => of(new GetSavingChartFailureAction(error))),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly mortgageApiService: MortgageApiService,
  ) {}
}
