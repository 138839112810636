import { Injectable } from '@angular/core';
import { UserApiService } from '@api-new/userservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store } from '@ngrx/store';
import { AuthService } from '@shared/services/auth.service';
import { getAdviserDetail, getAdviserDetailFailure, getAdviserDetailSuccess } from '@shared/store/adviser/adviser.actions';
import { Observable, of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class AdviserEffects {
  getAdviserDetail: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(getAdviserDetail),
      switchMap(() => {
        return this.userApiService.HTTP_CP_GetAdvisorUser().pipe(
          map((adviser) => getAdviserDetailSuccess({ adviser })),
          catchError((error) => {
            console.error(error);
            return of(getAdviserDetailFailure(error));
          }),
        );
      }),
    ),
  );

  constructor(
    private readonly authService: AuthService,
    private readonly userApiService: UserApiService,
    private readonly actions$: Actions,
    private readonly store: Store,
  ) {}
}
