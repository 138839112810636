import { Injectable } from '@angular/core';
import { CaseApiService } from '@api-new/caseservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { loadInitialPlatformData } from '@shared/store/common.actions';
import {
  getSmartSearchCases,
  getSmartSearchCasesFailure,
  getSmartSearchCasesSuccess,
} from '@shared/store/smart-search-cases/smart-search-cases.actions';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class SmartSearchCasesEffects {
  handleLoadInitialPlatformData = createEffect(() => this.actions$.pipe(ofType(loadInitialPlatformData), map(getSmartSearchCases)));

  getSmartSearchCases = createEffect(() =>
    this.actions$.pipe(
      ofType(getSmartSearchCases),
      switchMap(() => this.caseApiService.HTTP_CP_ListSmartSearchCases()),
      map((response) => getSmartSearchCasesSuccess(response)),
      catchError((error) => of(getSmartSearchCasesFailure({ error }))),
    ),
  );

  constructor(
    private readonly caseApiService: CaseApiService,
    private readonly actions$: Actions,
  ) {}
}
