import { Component, Input } from '@angular/core';

export enum IconColor {
  TURQUOISE = 'turquoise',
}

@Component({
  selector: 'shared-icon',
  templateUrl: './icon.component.html',
  styleUrls: ['./icon.component.scss'],
  standalone: true,
  imports: [],
})
export class IconComponent {
  @Input({ required: true }) icon = 'icon-placeholder';
  @Input() width: string | undefined;
  @Input() height: string | undefined;
  @Input() fill = '';
  @Input() stroke = '';
  @Input() iconColor: IconColor | undefined;
  @Input() size: 'extra-small' | 'small' | 'medium' | 'large' | 'extra-large' = 'medium';
}
