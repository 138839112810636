import { HTTP_CP_ClientUserNotificationSettings } from '@api-new/notificationservice';
import { Action } from '@ngrx/store';
import { ErrorModel } from '../../models/app-state.model';
import { PayloadAction } from '../store-utils';

export class GetNotificationSettings implements Action {
  static readonly type = '[NOTIFICATION-SETTINGS] Get Notification Settings';
  readonly type = GetNotificationSettings.type;
}

export class GetNotificationSettingsSuccess extends PayloadAction<HTTP_CP_ClientUserNotificationSettings> {
  static readonly type = '[NOTIFICATION-SETTINGS] Get Notification Settings Success';
  readonly type = GetNotificationSettingsSuccess.type;
}

export class GetNotificationSettingsFailure extends PayloadAction<ErrorModel> {
  static readonly type = '[NOTIFICATION-SETTINGS] Get Notification Settings Failure';
  readonly type = GetNotificationSettingsFailure.type;
}

export class UpdateNotificationSettings extends PayloadAction<HTTP_CP_ClientUserNotificationSettings> {
  static readonly type = '[NOTIFICATION-SETTINGS] Update Notification Settings';
  readonly type = UpdateNotificationSettings.type;
}

export class UpdateNotificationSettingsSuccess extends PayloadAction<HTTP_CP_ClientUserNotificationSettings> {
  static readonly type = '[NOTIFICATION-SETTINGS] Update Notification Settings Success';
  readonly type = UpdateNotificationSettingsSuccess.type;
}

export class UpdateNotificationSettingsFailure extends PayloadAction<ErrorModel> {
  static readonly type = '[NOTIFICATION-SETTINGS] Update Notification Settings Failure';
  readonly type = UpdateNotificationSettingsFailure.type;
}

export type NotificationSettingsAction =
  | GetNotificationSettings
  | GetNotificationSettingsSuccess
  | GetNotificationSettingsFailure
  | UpdateNotificationSettings
  | UpdateNotificationSettingsSuccess
  | UpdateNotificationSettingsFailure;
