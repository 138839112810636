import { createSelector } from '@ngrx/store';
import { AppState, CalendarEventState } from '../../models/app-state.model';

export const $calendarEventState = (state: AppState): CalendarEventState => state.OLD_calendarEvents;

export const $calendarEventsLoaded = createSelector($calendarEventState, (state: CalendarEventState) => state.loaded);

export const $calendarEvents = createSelector($calendarEventState, (state: CalendarEventState) =>
  state.entity != null ? state.entity : [],
);
