import { Injectable } from '@angular/core';
import * as Sentry from '@sentry/browser';
import { environment } from '../../../environments/environment';
import { SkipableErrorHandler } from './skipable-error-handler.interface';

@Injectable({ providedIn: 'root' })
export class SentryErrorHandler implements SkipableErrorHandler {
  handleError(error: any): boolean {
    if (!environment.production) {
      console.error(error);
    }
    if (window.location.origin.includes('localhost')) {
      return;
    }
    Sentry.captureException(error.originalError || error);
    // const eventId = Sentry.captureException(error.originalError || error);
    // Sentry.showReportDialog({ eventId });
  }
}
