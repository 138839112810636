import { AsyncPipe } from '@angular/common';
import { ChangeDetectionStrategy, Component } from '@angular/core';
import { UntypedFormBuilder, UntypedFormGroup, Validators } from '@angular/forms';
import { DASHLY_LINKS, showWarnState } from '@app/OLD_shared/common';
import { FormComponentsModule } from '@app/OLD_shared/form-components/form-components.module';
import { AuthFacadeService } from '../../auth-facade.service';

@Component({
  selector: 'cp-terms-and-conditions-agreement',
  templateUrl: './terms-and-conditions-agreement.component.html',
  styleUrls: ['../../auth.component.scss'],
  standalone: true,
  imports: [AsyncPipe, FormComponentsModule],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TermsAndConditionsAgreementComponent {
  readonly loading$ = this.facade.loading$;

  readonly privacyPolicyLink = DASHLY_LINKS.privacyPolicy;
  readonly tacLink = DASHLY_LINKS.termsOfUse;
  acceptedTermsAndConditionsForm: UntypedFormGroup;

  constructor(
    private readonly facade: AuthFacadeService,
    private readonly fb: UntypedFormBuilder,
  ) {
    this.acceptedTermsAndConditionsForm = this.fb.group({
      agreeTerms: [null, [Validators.requiredTrue]],
    });
  }

  agreeAndLogin(): void {
    if (this.acceptedTermsAndConditionsForm.invalid) {
      return;
    }
    this.facade.agreeToTermsAndConditions();
  }

  // ngOnInit(): void {
  //   this.signInResponse$.pipe(take(1)).subscribe((response) => {
  //     if (!response) {
  //       this.facade.navigateToCheckMail();
  //     }
  //   });
  // }

  showWarnState(field: string, formSubmitted: boolean): boolean {
    const control = this.acceptedTermsAndConditionsForm.controls.agreeTerms;

    return showWarnState(field, this.acceptedTermsAndConditionsForm) || (control.invalid && formSubmitted);
  }
}
