import { Injectable } from '@angular/core';
import { NotificationApiService } from '@api-new/notificationservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, select } from '@ngrx/store';
import { Observable, of } from 'rxjs';
import { catchError, filter, map, switchMap, take } from 'rxjs/operators';
import { AppState } from '../../models/app-state.model';
import * as calendarEventsActions from '../actions/calendar-event.action';
import { $calendarEventsLoaded } from '../selectors/calendar-event.selectors';

@Injectable()
export class CalendarEventEffect {
  getCalendarEvents$: Observable<Action> = createEffect(() =>
    this.actions$.pipe(
      ofType(calendarEventsActions.GetCalendarEventsAction.type),
      switchMap(() =>
        this.store.pipe(
          select($calendarEventsLoaded),
          take(1),
          filter((loaded) => !loaded),
          switchMap(() =>
            this.notificationApiService.HTTP_CP_ListCalendarEvents().pipe(
              map(({ calendarEvents }) => new calendarEventsActions.GetCalendarEventsSuccessAction(calendarEvents)),
              catchError((error) => of(new calendarEventsActions.GetCalendarEventsFailureAction(error))),
            ),
          ),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly store: Store<AppState>,
    private readonly notificationApiService: NotificationApiService,
  ) {}
}
