import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, CanActivate, CanActivateChild, Router, RouterStateSnapshot } from '@angular/router';
import { Store } from '@ngrx/store';
import { paths } from '@platform/paths';
import { AuthService } from '@shared/services/auth.service';
import { setRedirectUrlAfterLoginAction } from '@shared/store/auth/auth.action';
import { Observable, of } from 'rxjs';

@Injectable()
export class AuthGuard implements CanActivate, CanActivateChild {
  constructor(
    private readonly router: Router,
    private readonly authService: AuthService,
    private readonly store: Store,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | boolean {
    if (state.url.includes(`/${paths.AGGREGATOR_LANDING_PAGE}`) && state.url.includes(`/${paths.UNSUBSCRIBE}`)) {
      return of(true);
    }
    if (!this.authService.tokenValid()) {
      this.store.dispatch(setRedirectUrlAfterLoginAction({ redirectUrl: state.url }));
      void this.router.navigateByUrl(`/${paths.AUTH}`);
      return of(false);
    }
    if (state.url.startsWith(`/${paths.PLATFORM}`) || state.url.startsWith(`/${paths.AGGREGATOR_LANDING_PAGE}`)) {
      return of(true);
    } else {
      void this.router.navigateByUrl(`/${paths.PLATFORM}`);
      return of(false);
    }
  }

  canActivateChild(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> {
    if (!this.authService.tokenValid()) {
      this.router.navigateByUrl(`/${paths.AUTH}`);
      return of(false);
    }

    return of(true);
  }
}
