@if (isLoginFromParamsLoading$ | async) {
  <div class="dashly-loading-container">
    <img alt="loading..." src="../../assets/OLD_images/loading.gif" />
  </div>
} @else {
  <div class="landing-container">
    <router-outlet />
    <div class="landing-image" [class.mab-branding]="isMabDomain">
      <!-- TODO: Remove this when we have a proper way to detect MAB domain -->
      @if (isMabDomain) {
        <img class="landing-house-image mab" src="../../assets/images/landing_mab_mm_bg.png" />
      } @else {
        <img class="landing-house-image" src="../../assets/OLD_images/onboarding-05.jpg" />
      }
    </div>
  </div>
}
