import { HTTP_X_ListLenders_Response_Lender } from '@api-new/mortgageservice';
import { EntityAdapter, createEntityAdapter } from '@ngrx/entity';
import { Action, createReducer, on } from '@ngrx/store';
import { getLenders, getLendersFailure, getLendersSuccess } from '@shared/store/lender/lenders.actions';
import { LendersState } from '@shared/store/lender/lenders.state';

export const getLenderById = (lender: Pick<HTTP_X_ListLenders_Response_Lender, 'id'>): string => lender.id;

export const lendersAdapter: EntityAdapter<HTTP_X_ListLenders_Response_Lender> = createEntityAdapter<HTTP_X_ListLenders_Response_Lender>({
  selectId: getLenderById,
});

export const lendersInitialState: LendersState = lendersAdapter.getInitialState({
  loading: true,
  loaded: false,
  errors: null,
});

const reducer = createReducer(
  lendersInitialState,

  on(getLenders, (state) => ({
    ...state,
    loading: true,
  })),

  on(getLendersSuccess, (state, lendersResponse) =>
    lendersAdapter.setMany(lendersResponse.lenders ? lendersResponse.lenders : [], {
      ...state,
      loading: false,
      errors: null,
      loaded: true,
    }),
  ),

  on(getLendersFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),
);

export function lendersReducer(state: LendersState = lendersInitialState, action: Action): LendersState {
  return reducer(state, action);
}
