import { ENUM_MortgageSavingMethod } from '@api-new/common';
import { HTTP_CP_MortgageComparisonSettings } from '@api-new/mortgageservice';
import { DEFAULT_COMPARISON_PERIOD } from './common';
import { MortgagePartModel } from './models/mortgage-part.model';
import { MortgageSearchCriteriaMetadataModel } from './models/mortgage-search.model';
import { MortgageModel } from './models/mortgage.model';
import { OverpaymentModel } from './models/overpayment.model';

export const DEFAULTS = {
  defaultMortgage: (): MortgageModel => {
    const defaultCriteria: HTTP_CP_MortgageComparisonSettings = DEFAULTS.defaultMortgageSearch();
    return {
      lenderId: null,
      roughCurrentMonthlyPayment: true,
      outstandingBalanceDate: null,
      mortgageParts: [DEFAULTS.defaultMortgagePart()],
      mortgageComparisonSettings: {
        ...defaultCriteria,
        showFixed: true,
        showStandard: true,
        showStepped: true,
      },
    };
  },

  defaultMortgagePart: (): MortgagePartModel => ({
    repaymentType: null,
    initialPeriod: null,
    roughInitialAmount: true,
    mortgagePartOverpayments: [],
    mortgagePartUnderpayments: [],
    mortgagePartSteps: [
      {
        mortgagePartStepType: null,
        fixedInterestRate: null,
      },
    ],
  }),

  defaultMortgageSearch: (): any => ({
    excludedLenderIds: [],
    savingMethod: ENUM_MortgageSavingMethod.MORTGAGE_SAVING_METHOD_TOTAL_SAVINGS,
    preferredComparisonPeriod: DEFAULT_COMPARISON_PERIOD,
  }),

  fillDefaultMortgageSearch: (newCriteria: HTTP_CP_MortgageComparisonSettings): any => {
    const defaultCriteria: Partial<HTTP_CP_MortgageComparisonSettings> = DEFAULTS.defaultMortgageSearch();
    if (newCriteria == null) {
      return defaultCriteria;
    }
    return {
      ...defaultCriteria,
      ...newCriteria,
      maxInitialPayments:
        newCriteria.maxInitialPayments != null
          ? Math.round(newCriteria.maxInitialPayments <= 0 ? 0 : newCriteria.maxInitialPayments)
          : null,
      savingMethod: newCriteria.savingMethod ?? ENUM_MortgageSavingMethod.MORTGAGE_SAVING_METHOD_TOTAL_SAVINGS,
    };
  },

  defaultSearchPreferencesMetadata: (): MortgageSearchCriteriaMetadataModel => ({
    selectedIndex: 0,
    restrictBanks: false,
    minimumSavingsForMortgageExpiryTypeEarly: false,
    maxFees: false,
    maxRate: false,
    maxInitialPayments: false,
    minRatePeriod: false,
    maxRatePeriod: false,
  }),

  defaultExtraPayments: (): OverpaymentModel => ({
    id: null,
    startDate: null,
    endDate: null,
    mortgagePartOverpaymentType: null,
    amount: null,
  }),
};
