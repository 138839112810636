import { HTTP_CP_MortgageCase } from '@api-new/caseservice';
import { createSelector } from '@ngrx/store';
import { AppState, MortgageCaseState } from '../../models/app-state.model';
import { MortgageCaseMetadataModel } from '../../models/mortgage-case.model';
import { AccountsMetadata } from '../../models/mortgage.model';
import { mortgageCaseAdapter, mortgageCaseInitialState } from './mortgage-case.reducer';

const { selectAll } = mortgageCaseAdapter.getSelectors();

export const selectMortgageCaseState = (state: AppState): MortgageCaseState => state.OLD_mortgageCase || mortgageCaseInitialState;

export const selectMortgageCaseFullList = createSelector(selectMortgageCaseState, (state: MortgageCaseState): HTTP_CP_MortgageCase[] =>
  selectAll(state.data),
);

export const selectMortgageCaseList = createSelector(
  selectMortgageCaseFullList,
  (mortgageCases: HTTP_CP_MortgageCase[]): HTTP_CP_MortgageCase[] => mortgageCases || [],
);

export const selectMortgageCasesLoaded = createSelector(selectMortgageCaseState, (state: MortgageCaseState): boolean => state.loaded);

export const selectMortgageCaseMetadata = createSelector(
  selectMortgageCaseState,
  (state: MortgageCaseState): MortgageCaseMetadataModel => state.metadata,
);

export const selectPreparedNewMortgageCase = createSelector(
  selectMortgageCaseMetadata,
  (metadata: MortgageCaseMetadataModel): HTTP_CP_MortgageCase => metadata.newMortgageCase,
);

export const selectNewMortgageCaseURLMetadata = createSelector(
  selectMortgageCaseMetadata,
  (metadata: MortgageCaseMetadataModel): AccountsMetadata => metadata.newMortgageCaseURLMetadata,
);

export const selectMortgageCaseCreationLoading = createSelector(
  selectMortgageCaseMetadata,
  (metadata: MortgageCaseMetadataModel): boolean => metadata.createMortgageCaseLoading,
);
