import { AdviserEffects } from '@shared/store/adviser/adviser.effects';
import { BetterDealEffects } from '@shared/store/better-deal/better-deal.effects';
import { ClientUserEffects } from '@shared/store/client-user/client-user.effects';
import { CommonEffects } from '@shared/store/common.effects';
import { HelpCasesEffects } from '@shared/store/help-cases/help-cases.effects';
import { LendersEffects } from '@shared/store/lender/lenders.effects';
import { MortgageCasesEffects } from '@shared/store/mortgage-cases/mortgage-cases.effects';
import { MortgagesEffects } from '@shared/store/mortgages/mortgages.effects';
import { PropertiesEffects } from '@shared/store/properties/properties.effects';
import { SmartSearchCasesEffects } from '@shared/store/smart-search-cases/smart-search-cases.effects';
import { SmartSearchDealsEffects } from '@shared/store/smart-search-deals/smart-search-deals.effects';
import { SmartSearchesEffects } from '@shared/store/smart-searches/smart-searches.effects';

export const effects = [
  CommonEffects,
  AdviserEffects,
  BetterDealEffects,
  LendersEffects,
  MortgageCasesEffects,
  MortgagesEffects,
  PropertiesEffects,
  SmartSearchCasesEffects,
  SmartSearchDealsEffects,
  SmartSearchesEffects,
  ClientUserEffects,
  HelpCasesEffects,
];
