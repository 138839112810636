import { Injectable } from '@angular/core';
import { Observable, fromEvent } from 'rxjs';
import { distinctUntilChanged, map, shareReplay, startWith } from 'rxjs/operators';

export enum BreakpointSize {
  DESKTOP = 960,
  TABLET = 800,
  MOBILE = 0,
}

export enum ScreenSizeBreakpointNames {
  DESKTOP = 'desktop',
  TABLET = 'tablet',
  MOBILE = 'mobile',
}
const QUERY: Map<ScreenSizeBreakpointNames, BreakpointSize> = new Map([
  [ScreenSizeBreakpointNames.DESKTOP, BreakpointSize.DESKTOP],
  [ScreenSizeBreakpointNames.TABLET, BreakpointSize.TABLET],
  [ScreenSizeBreakpointNames.MOBILE, BreakpointSize.MOBILE],
]);

@Injectable()
export class ScreenSizeBreakpointObserverService {
  private readonly _screenSizeBreakpointName$: Observable<ScreenSizeBreakpointNames>;

  constructor() {
    this._screenSizeBreakpointName$ = fromEvent(window, 'resize').pipe(
      startWith(this._getScreenSize()),
      map(() => {
        return this._getScreenSize();
      }),
      distinctUntilChanged(),
      shareReplay(1),
    );
  }

  public get screenSizeBreakpointName$(): Observable<ScreenSizeBreakpointNames> {
    return this._screenSizeBreakpointName$;
  }

  private _getScreenSize(): ScreenSizeBreakpointNames {
    const [[newSize]] = Array.from(QUERY.entries()).filter(([, width]) => window.innerWidth >= width);
    return newSize;
  }
}
