import { Action, createReducer, on } from '@ngrx/store';
import { getAdviserDetail, getAdviserDetailFailure, getAdviserDetailSuccess } from '@shared/store/adviser/adviser.actions';
import { AdviserState } from '@shared/store/adviser/adviser.state';

export const adviserInitialState: AdviserState = {
  adviser: null,
  loading: true,
  errors: null,
};

const reducer = createReducer(
  adviserInitialState,

  on(getAdviserDetail, (state) => ({
    ...state,
    loading: true,
  })),

  on(getAdviserDetailSuccess, (state, { adviser }) => ({
    ...state,
    adviser,
    loading: false,
  })),

  on(getAdviserDetailFailure, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),
);

export function adviserReducer(state: AdviserState = adviserInitialState, action: Action): AdviserState {
  return reducer(state, action);
}
