import {
  HTTP_CP_CreateProperty_Request,
  HTTP_CP_Property,
  HTTP_CP_SetPropertyValue_Request,
  HTTP_CP_SetPropertyValue_Response,
  HTTP_CP_UnsetPropertyValue_Request,
  HTTP_CP_UnsetPropertyValue_Response,
} from '@api-new/propertyservice';
import { Action } from '@ngrx/store';
import { ErrorModel } from '../../models/app-state.model';
import { PropertyClosingModel } from '../../models/property.model';
import { PayloadAction } from '../store-utils';

export class GetPropertiesAction implements Action {
  static readonly type = 'GetPropertiesAction';
  readonly type = GetPropertiesAction.type;
}

export class GetPropertiesSuccessAction extends PayloadAction<HTTP_CP_Property[]> {
  static readonly type = 'GetPropertiesSuccessAction';
  readonly type = GetPropertiesSuccessAction.type;
}

export class GetPropertiesFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'GetPropertiesFailureAction';
  readonly type = GetPropertiesFailureAction.type;
}

export class GetSinglePropertyAction extends PayloadAction<string> {
  static readonly type = 'GetSinglePropertyAction';
  readonly type = GetSinglePropertyAction.type;
}

export class GetSinglePropertySuccessAction extends PayloadAction<HTTP_CP_Property> {
  static readonly type = 'GetSinglePropertySuccessAction';
  readonly type = GetSinglePropertySuccessAction.type;
}

export class GetSinglePropertyFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'GetSinglePropertyFailureAction';
  readonly type = GetSinglePropertyFailureAction.type;
}

export class CreatePropertyAction extends PayloadAction<HTTP_CP_CreateProperty_Request> {
  static readonly type = 'CreatePropertyAction';
  readonly type = CreatePropertyAction.type;
}

export class CreatePropertySuccessAction extends PayloadAction<HTTP_CP_Property> {
  static readonly type = 'CreatePropertySuccessAction';
  readonly type = CreatePropertySuccessAction.type;
}

export class CreatePropertyFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'CreatePropertyFailureAction';
  readonly type = CreatePropertyFailureAction.type;
}

export class UpdatePropertyAction extends PayloadAction<HTTP_CP_Property> {
  static readonly type = 'UpdatePropertyAction';
  readonly type = UpdatePropertyAction.type;
}

export class UpdatePropertySuccessAction extends PayloadAction<HTTP_CP_Property> {
  static readonly type = 'UpdatePropertySuccessAction';
  readonly type = UpdatePropertySuccessAction.type;
}

export class UpdatePropertyFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'UpdatePropertyFailureAction';
  readonly type = UpdatePropertyFailureAction.type;
}

export class ClosePropertyAction extends PayloadAction<PropertyClosingModel> {
  static readonly type = 'ClosePropertyAction';
  readonly type = ClosePropertyAction.type;
}

export class ClosePropertySuccessAction extends PayloadAction<PropertyClosingModel> {
  static readonly type = 'ClosePropertySuccessAction';
  readonly type = ClosePropertySuccessAction.type;
}

export class ClosePropertyFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'ClosePropertyFailureAction';
  readonly type = ClosePropertyFailureAction.type;
}

export class SetPropertyWaitingForClose extends PayloadAction<PropertyClosingModel> {
  static readonly type = 'SetPropertyWaitingForClose';
  readonly type = SetPropertyWaitingForClose.type;
}

export class ClearPropertyWaitingForClose implements Action {
  static readonly type = 'ClearPropertyWaitingForClose';
  readonly type = ClearPropertyWaitingForClose.type;
}

export class SetSelectedPropertyId extends PayloadAction<string> {
  static readonly type = 'SetSelectedPropertyId';
  readonly type = SetSelectedPropertyId.type;
}

export class DeletePropertyValuationAction extends PayloadAction<HTTP_CP_UnsetPropertyValue_Request> {
  static readonly type = 'DeletePropertyValuationAction';
  readonly type = DeletePropertyValuationAction.type;
}

export class DeletePropertyValuationSuccessAction extends PayloadAction<{
  propertyId: string;
  response: HTTP_CP_UnsetPropertyValue_Response;
}> {
  static readonly type = 'DeletePropertyValuationSuccessAction';
  readonly type = DeletePropertyValuationSuccessAction.type;
}

export class DeletePropertyValuationFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'DeletePropertyValuationFailureAction';
  readonly type = DeletePropertyValuationFailureAction.type;
}

export class CreatePropertyValuationAction extends PayloadAction<HTTP_CP_SetPropertyValue_Request> {
  static readonly type = 'CreatePropertyValuationAction';
  readonly type = CreatePropertyValuationAction.type;
}

export class CreatePropertyValuationSuccessAction extends PayloadAction<HTTP_CP_SetPropertyValue_Response> {
  static readonly type = 'CreatePropertyValuationSuccessAction';
  readonly type = CreatePropertyValuationSuccessAction.type;
}

export class CreatePropertyValuationFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'CreatePropertyValuationFailureAction';
  readonly type = CreatePropertyValuationFailureAction.type;
}

export class PropertyValuationNotFoundAction extends PayloadAction<{ address: string }> {
  static readonly type = 'PropertyValuationNotFoundAction';
  readonly type = PropertyValuationNotFoundAction.type;
}

export type PropertyAction =
  | GetPropertiesAction
  | GetPropertiesSuccessAction
  | GetPropertiesFailureAction
  | GetSinglePropertyAction
  | GetSinglePropertySuccessAction
  | GetSinglePropertyFailureAction
  | CreatePropertyAction
  | CreatePropertySuccessAction
  | CreatePropertyFailureAction
  | UpdatePropertyAction
  | UpdatePropertySuccessAction
  | UpdatePropertyFailureAction
  | ClosePropertyAction
  | ClosePropertySuccessAction
  | ClosePropertyFailureAction
  | SetSelectedPropertyId
  | SetPropertyWaitingForClose
  | ClearPropertyWaitingForClose
  | DeletePropertyValuationAction
  | DeletePropertyValuationSuccessAction
  | DeletePropertyValuationFailureAction
  | CreatePropertyValuationAction
  | CreatePropertyValuationSuccessAction
  | CreatePropertyValuationFailureAction
  | PropertyValuationNotFoundAction;
