import { HTTP_CP_ListCalendarEvents_Response_CalendarEvent } from '@api-new/notificationservice';
import { Action } from '@ngrx/store';
import { ErrorModel } from '../../models/app-state.model';
import { PayloadAction } from '../store-utils';

export class GetCalendarEventsAction implements Action {
  static readonly type = 'GetCalendarEventsAction';
  readonly type = GetCalendarEventsAction.type;
}

export class GetCalendarEventsSuccessAction extends PayloadAction<HTTP_CP_ListCalendarEvents_Response_CalendarEvent[]> {
  static readonly type = 'GetCalendarEventsSuccessAction';
  readonly type = GetCalendarEventsSuccessAction.type;
}

export class GetCalendarEventsFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'GetCalendarEventsFailureAction';
  readonly type = GetCalendarEventsFailureAction.type;
}

export type CalendarEventAction = GetCalendarEventsAction | GetCalendarEventsSuccessAction | GetCalendarEventsFailureAction;
