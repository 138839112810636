import { HTTP_CP_GetDashboardData_Response } from '@api-new/mortgageservice';
import { ErrorModel } from '@app/OLD_shared/models/app-state.model';
import { createAction, props } from '@ngrx/store';

export const loadInitialPlatformData = createAction('[COMMON] Load Initial Platform Data');

export const getDashboardData = createAction('[COMMON] Get Dashboard Data');

export const getDashboardDataSuccess = createAction('[COMMON] Get Dashboard Data Success', props<HTTP_CP_GetDashboardData_Response>());

export const getDashboardDataFailure = createAction('[COMMON] Get Dashboard Data Failure', props<{ error: ErrorModel }>());
