// Code generated by protoc-gen-ts_proto. DO NOT EDIT.
// source: shared/api/common/ENUM_ClientUserEmploymentOtherIncomeType.proto

export enum ENUM_ClientUserEmploymentOtherIncomeType {
  CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_UNSPECIFIED = 'CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_UNSPECIFIED',
  CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_PART_TIME = 'CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_PART_TIME',
  CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_SALARY_FROM_ANOTHER_JOB = 'CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_SALARY_FROM_ANOTHER_JOB',
  CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_OVERTIME__FROM_ANOTHER_JOB = 'CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_OVERTIME__FROM_ANOTHER_JOB',
  CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_BONUS_FROM_ANOTHER_JOB = 'CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_BONUS_FROM_ANOTHER_JOB',
  CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_COMMISSIONS_FROM_ANOTHER_JOB = 'CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_COMMISSIONS_FROM_ANOTHER_JOB',
  CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_NET_PROFIT_FROM_ANOTHER_JOB = 'CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_NET_PROFIT_FROM_ANOTHER_JOB',
  CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_DIVIDENDS_FROM_ANOTHER_JOB = 'CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_DIVIDENDS_FROM_ANOTHER_JOB',
  CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_CAR_ALLOWANCE__FROM_ANOTHER_JOB = 'CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_CAR_ALLOWANCE__FROM_ANOTHER_JOB',
  CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_HOUSE_ALLOWANCE_FROM_ANOTHER_JOB = 'CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_HOUSE_ALLOWANCE_FROM_ANOTHER_JOB',
  CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_PENSION = 'CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_PENSION',
  CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_RENTAL_INCOME = 'CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_RENTAL_INCOME',
  CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_INVESTMENT_INCOME = 'CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_INVESTMENT_INCOME',
  CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_MAINTENANCE_PAYMENTS = 'CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_MAINTENANCE_PAYMENTS',
  CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_STATE_BENEFIT = 'CLIENT_USER_EMPLOYMENT_OTHER_INCOME_TYPE_STATE_BENEFIT',
  UNRECOGNIZED = 'UNRECOGNIZED',
}
