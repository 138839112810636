import { Component } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { Title } from '@angular/platform-browser';
import { ActivatedRoute, ChildActivationEnd, NavigationEnd, Router } from '@angular/router';
import { GlobalColorService } from '@app/OLD_shared/services/global-color.service';
import { environment } from '@environments/environment';
import { init as initFullStory } from '@fullstory/browser';
import { Observable, combineLatest } from 'rxjs';
import { filter, map, mergeMap, startWith, take } from 'rxjs/operators';

@Component({
  selector: 'cp-root',
  templateUrl: './app.component.html',
})
export class AppComponent {
  readonly appLoading$: Observable<boolean> = combineLatest([
    this.router.events.pipe(
      filter((event) => event instanceof ChildActivationEnd),
      take(1),
      map(() => false),
      startWith(true),
    ),
  ]).pipe(map(([appLoading]) => appLoading));

  constructor(
    private readonly router: Router,
    private readonly activatedRoute: ActivatedRoute,
    private readonly titleService: Title,
    private readonly globalColorService: GlobalColorService,
  ) {
    this.initFullStory();
    this.globalColorService.initCompanyColorListener();

    this.router.events
      .pipe(
        takeUntilDestroyed(),
        filter((event) => event instanceof NavigationEnd),
        map(() => this.activatedRoute),
        map((route) => {
          while (route.firstChild) {
            route = route.firstChild;
          }
          return route;
        }),
        filter((route) => route.outlet === 'primary'),
        mergeMap((route) => route.data),
      )
      .subscribe((data: { title: string }) => {
        this.titleService.setTitle(`${data.title ? `${data.title} | ` : ''}Mortgage Monitor`);
      });
  }

  private initFullStory(): void {
    initFullStory({
      orgId: '9ZEQS',
      devMode: !environment.production,
    });
  }
}
