import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-arrow-button',
  templateUrl: './arrow-button.component.html',
  styleUrls: ['./arrow-button.component.scss'],
})
export class ArrowButtonComponent {
  @Input() disabled = false;
  @Input() loading = false;
  @Input() variant: 'dark' | 'default' | 'responsive-height' | 'reset' | 'secondary' = 'default';
}
