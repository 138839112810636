import { HTTP_X_ListLenders_Response_Lender } from '@api-new/mortgageservice';
import { Action } from '@ngrx/store';
import { ErrorModel } from '../../models/app-state.model';
import { PayloadAction } from '../store-utils';

export class GetLendersAction implements Action {
  static readonly type = 'GetLendersAction';
  readonly type = GetLendersAction.type;
}

export class GetLendersSuccessAction extends PayloadAction<HTTP_X_ListLenders_Response_Lender[]> {
  static readonly type = 'GetLendersSuccessAction';
  readonly type = GetLendersSuccessAction.type;
}

export class GetLendersFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'GetLendersFailureAction';
  readonly type = GetLendersFailureAction.type;
}

export class GetLenderAdditionalsAction extends PayloadAction<string> {
  static readonly type = 'GetLenderAdditionalsAction';
  readonly type = GetLenderAdditionalsAction.type;
}

export class GetLenderAdditionalsSuccessAction extends PayloadAction<HTTP_X_ListLenders_Response_Lender> {
  static readonly type = 'GetLenderAdditionalsSuccessAction';
  readonly type = GetLenderAdditionalsSuccessAction.type;
}

export class GetLenderAdditionalsFailureAction extends PayloadAction<ErrorModel> {
  static readonly type = 'GetLenderAdditionalsFailureAction';
  readonly type = GetLenderAdditionalsFailureAction.type;
}

export type LenderAction =
  | GetLendersAction
  | GetLendersSuccessAction
  | GetLendersFailureAction
  | GetLenderAdditionalsAction
  | GetLenderAdditionalsSuccessAction
  | GetLenderAdditionalsFailureAction;
