import { CalendarEventState } from '../../models/app-state.model';
import {
  CalendarEventAction,
  GetCalendarEventsAction,
  GetCalendarEventsFailureAction,
  GetCalendarEventsSuccessAction,
} from '../actions/calendar-event.action';

const initialState: CalendarEventState = {
  errors: null,
  loading: false,
  loaded: false,
};

export function calendarEventReducer(state: CalendarEventState = initialState, action: CalendarEventAction): CalendarEventState {
  switch (action.type) {
    case GetCalendarEventsAction.type:
      return {
        ...state,
        loading: !state.loaded,
      };

    case GetCalendarEventsSuccessAction.type:
      return {
        ...state,
        loading: false,
        loaded: true,
        entity: action.payload,
      };

    case GetCalendarEventsFailureAction.type:
      return {
        ...state,
        loading: false,
        errors: action.payload,
      };

    default:
      return state;
  }
}
