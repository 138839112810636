import { Injectable } from '@angular/core';
import { HTTP_AP_AdvisorUserNotificationSettings } from '@api-new/notificationservice/HTTP_AP_AdvisorUserNotificationSettings';
import { HTTP_AP_BulkSetClientUsersNotificationSettings_Request } from '@api-new/notificationservice/HTTP_AP_BulkSetClientUsersNotificationSettings_Request';
import { HTTP_AP_ClientUserNotificationSettings } from '@api-new/notificationservice/HTTP_AP_ClientUserNotificationSettings';
import { HTTP_AP_CompanyNotificationSettings } from '@api-new/notificationservice/HTTP_AP_CompanyNotificationSettings';
import { HTTP_AP_ExportEmailNotificationReporting_Request } from '@api-new/notificationservice/HTTP_AP_ExportEmailNotificationReporting_Request';
import { HTTP_AP_GenerateEmailNotificationReportingDashboardPDF_Request } from '@api-new/notificationservice/HTTP_AP_GenerateEmailNotificationReportingDashboardPDF_Request';
import { HTTP_AP_GenerateEmailNotificationReportingDashboardPDF_Response } from '@api-new/notificationservice/HTTP_AP_GenerateEmailNotificationReportingDashboardPDF_Response';
import { HTTP_AP_GetAdvisorUserNotificationSettings_Request } from '@api-new/notificationservice/HTTP_AP_GetAdvisorUserNotificationSettings_Request';
import { HTTP_AP_GetClientUserNotificationSettings_Request } from '@api-new/notificationservice/HTTP_AP_GetClientUserNotificationSettings_Request';
import { HTTP_AP_GetCompanyNotificationSettings_Request } from '@api-new/notificationservice/HTTP_AP_GetCompanyNotificationSettings_Request';
import { HTTP_AP_GetEmailNotificationReportingDashboard_Request } from '@api-new/notificationservice/HTTP_AP_GetEmailNotificationReportingDashboard_Request';
import { HTTP_AP_GetEmailNotificationReportingDashboard_Response } from '@api-new/notificationservice/HTTP_AP_GetEmailNotificationReportingDashboard_Response';
import { HTTP_AP_GetNetworkNotificationSettings_Request } from '@api-new/notificationservice/HTTP_AP_GetNetworkNotificationSettings_Request';
import { HTTP_AP_GetNotificationImages_Request } from '@api-new/notificationservice/HTTP_AP_GetNotificationImages_Request';
import { HTTP_AP_GetNotificationImages_Response } from '@api-new/notificationservice/HTTP_AP_GetNotificationImages_Response';
import { HTTP_AP_NetworkNotificationSettings } from '@api-new/notificationservice/HTTP_AP_NetworkNotificationSettings';
import { HTTP_AP_ResendBetterDealAlertNotifications_Request } from '@api-new/notificationservice/HTTP_AP_ResendBetterDealAlertNotifications_Request';
import { HTTP_AP_ResendHomeReportNotifications_Request } from '@api-new/notificationservice/HTTP_AP_ResendHomeReportNotifications_Request';
import { HTTP_AP_UpdateAdvisorUserNotificationSettings_Request } from '@api-new/notificationservice/HTTP_AP_UpdateAdvisorUserNotificationSettings_Request';
import { HTTP_AP_UpdateClientUserNotificationSettings_Request } from '@api-new/notificationservice/HTTP_AP_UpdateClientUserNotificationSettings_Request';
import { HTTP_AP_UpdateCompanyNotificationSettings_Request } from '@api-new/notificationservice/HTTP_AP_UpdateCompanyNotificationSettings_Request';
import { HTTP_AP_UpdateNetworkNotificationSettings_Request } from '@api-new/notificationservice/HTTP_AP_UpdateNetworkNotificationSettings_Request';
import { HTTP_AP_UpdateNotificationImages_Request } from '@api-new/notificationservice/HTTP_AP_UpdateNotificationImages_Request';
import { HTTP_AP_UpdateNotificationImages_Response } from '@api-new/notificationservice/HTTP_AP_UpdateNotificationImages_Response';
import { HTTP_AP_ValidateBulkSetClientUsersNotificationSettings_Response } from '@api-new/notificationservice/HTTP_AP_ValidateBulkSetClientUsersNotificationSettings_Response';
import { HTTP_CP_ClientUserNotificationSettings } from '@api-new/notificationservice/HTTP_CP_ClientUserNotificationSettings';
import { HTTP_CP_ListCalendarEvents_Response } from '@api-new/notificationservice/HTTP_CP_ListCalendarEvents_Response';
import { HTTP_CP_UpdateClientUserNotificationSettings_Request } from '@api-new/notificationservice/HTTP_CP_UpdateClientUserNotificationSettings_Request';
import { Observable } from 'rxjs';
import { ApplicationHttpClient } from '../../../../../lib/services/application-http.service';

@Injectable({ providedIn: 'root' })
export class NotificationApiService {
  constructor(private readonly httpClient: ApplicationHttpClient) {}

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- GRPC Endpoints ----------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // --- HTTP Endpoints - Adviser Portal (ap) ------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_AP_BulkSetClientUsersNotificationSettings(request: HTTP_AP_BulkSetClientUsersNotificationSettings_Request): Observable<undefined> {
    return this.httpClient.post(`/notification-service/ap/client-user-notification-settings/all/BulkSet`, request);
  }

  HTTP_AP_ExportEmailNotificationReporting(request: HTTP_AP_ExportEmailNotificationReporting_Request): Observable<undefined> {
    return this.httpClient.post(`/notification-service/ap/ExportEmailNotificationReporting`, request);
  }

  HTTP_AP_ExportHomeReportDataToCSV(request?: undefined): Observable<undefined> {
    return this.httpClient.post(`/notification-service/ap/ExportHomeReportDataToCSV`, request);
  }

  HTTP_AP_GenerateEmailNotificationReportingDashboardPDF(
    request: HTTP_AP_GenerateEmailNotificationReportingDashboardPDF_Request,
  ): Observable<HTTP_AP_GenerateEmailNotificationReportingDashboardPDF_Response> {
    return this.httpClient.get(`/notification-service/ap/email-notification-reporting-dashboard-pdf`, { params: request });
  }

  HTTP_AP_GetAdvisorUserNotificationSettings(
    request: HTTP_AP_GetAdvisorUserNotificationSettings_Request,
  ): Observable<HTTP_AP_AdvisorUserNotificationSettings> {
    const { advisorUserId, ...updatedRequest } = request;
    return this.httpClient.get(`/notification-service/ap/advisor-user-notification-settings/${request.advisorUserId}`, {
      params: updatedRequest,
    });
  }

  HTTP_AP_GetClientUserNotificationSettings(
    request: HTTP_AP_GetClientUserNotificationSettings_Request,
  ): Observable<HTTP_AP_ClientUserNotificationSettings> {
    const { clientUserId, ...updatedRequest } = request;
    return this.httpClient.get(`/notification-service/ap/client-user-notification-settings/${request.clientUserId}`, {
      params: updatedRequest,
    });
  }

  HTTP_AP_GetCompanyNotificationSettings(
    request: HTTP_AP_GetCompanyNotificationSettings_Request,
  ): Observable<HTTP_AP_CompanyNotificationSettings> {
    const { companyId, ...updatedRequest } = request;
    return this.httpClient.get(`/notification-service/ap/company-notification-settings/${request.companyId}`, { params: updatedRequest });
  }

  HTTP_AP_GetEmailNotificationReportingDashboard(
    request: HTTP_AP_GetEmailNotificationReportingDashboard_Request,
  ): Observable<HTTP_AP_GetEmailNotificationReportingDashboard_Response> {
    return this.httpClient.get(`/notification-service/ap/email-notification-reporting-dashboard`, { params: request });
  }

  HTTP_AP_GetNetworkNotificationSettings(
    request: HTTP_AP_GetNetworkNotificationSettings_Request,
  ): Observable<HTTP_AP_NetworkNotificationSettings> {
    const { networkId, ...updatedRequest } = request;
    return this.httpClient.get(`/notification-service/ap/network-notification-settings/${request.networkId}`, { params: updatedRequest });
  }

  HTTP_AP_GetNotificationImages(request: HTTP_AP_GetNotificationImages_Request): Observable<HTTP_AP_GetNotificationImages_Response> {
    return this.httpClient.get(`/notification-service/ap/notification-images/one`, { params: request });
  }

  HTTP_AP_ResendBetterDealAlertNotifications(request: HTTP_AP_ResendBetterDealAlertNotifications_Request): Observable<undefined> {
    return this.httpClient.post(`/notification-service/ap/ResendBetterDealAlertNotifications`, request);
  }

  HTTP_AP_ResendHomeReportNotifications(request: HTTP_AP_ResendHomeReportNotifications_Request): Observable<undefined> {
    return this.httpClient.post(`/notification-service/ap/ResendHomeReportNotifications`, request);
  }

  HTTP_AP_UpdateAdvisorUserNotificationSettings(
    request: HTTP_AP_UpdateAdvisorUserNotificationSettings_Request,
  ): Observable<HTTP_AP_AdvisorUserNotificationSettings> {
    return this.httpClient.put(`/notification-service/ap/advisor-user-notification-settings/${request.advisorUserId}`, request);
  }

  HTTP_AP_UpdateClientUserNotificationSettings(
    request: HTTP_AP_UpdateClientUserNotificationSettings_Request,
  ): Observable<HTTP_AP_ClientUserNotificationSettings> {
    return this.httpClient.put(`/notification-service/ap/client-user-notification-settings/${request.clientUserId}`, request);
  }

  HTTP_AP_UpdateCompanyNotificationSettings(
    request: HTTP_AP_UpdateCompanyNotificationSettings_Request,
  ): Observable<HTTP_AP_CompanyNotificationSettings> {
    return this.httpClient.put(`/notification-service/ap/company-notification-settings/${request.companyId}`, request);
  }

  HTTP_AP_UpdateNetworkNotificationSettings(
    request: HTTP_AP_UpdateNetworkNotificationSettings_Request,
  ): Observable<HTTP_AP_NetworkNotificationSettings> {
    return this.httpClient.put(`/notification-service/ap/network-notification-settings/${request.networkId}`, request);
  }

  HTTP_AP_UpdateNotificationImages(
    request: HTTP_AP_UpdateNotificationImages_Request,
  ): Observable<HTTP_AP_UpdateNotificationImages_Response> {
    return this.httpClient.put(`/notification-service/ap/notification-images/one`, request);
  }

  HTTP_AP_ValidateBulkSetClientUsersNotificationSettings(
    request: HTTP_AP_BulkSetClientUsersNotificationSettings_Request,
  ): Observable<HTTP_AP_ValidateBulkSetClientUsersNotificationSettings_Response> {
    return this.httpClient.post(`/notification-service/ap/client-user-notification-settings/all/ValidateBulkSet`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Client Portal (cp) -------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  HTTP_CP_GetClientUserNotificationSettings(request?: undefined): Observable<HTTP_CP_ClientUserNotificationSettings> {
    return this.httpClient.get(`/notification-service/cp/client-user-notification-settings/one`, { params: request });
  }

  HTTP_CP_ListCalendarEvents(request?: undefined): Observable<HTTP_CP_ListCalendarEvents_Response> {
    return this.httpClient.get(`/notification-service/cp/calendar-events`, { params: request });
  }

  HTTP_CP_UpdateClientUserNotificationSettings(
    request: HTTP_CP_UpdateClientUserNotificationSettings_Request,
  ): Observable<HTTP_CP_ClientUserNotificationSettings> {
    return this.httpClient.put(`/notification-service/cp/client-user-notification-settings/one`, request);
  }

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Dashly IQ (iq) ------------------------------------------------------------------------------------------------------------------ //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Universal Lead Capture (ulc) ---------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- HTTP Endpoints - Extended (x) -------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // Empty

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
  // --- SPECIAL Endpoints -------------------------------------------------------------------------------------------------------------------------------- //
  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //

  // ------------------------------------------------------------------------------------------------------------------------------------------------------ //
}
