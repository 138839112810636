import { Injectable } from '@angular/core';
import { CompanyApiService } from '@api-new/companyservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';
import { getAffiliateByIDAction, getAffiliateFailureAction, getAffiliateSuccessAction } from './affiliate.action';

@Injectable()
export class AffiliateEffect {
  getAffiliateById = createEffect(() =>
    this.actions$.pipe(
      ofType(getAffiliateByIDAction),
      switchMap(({ id }) => this.companyApiService.HTTP_CP_GetCompany({ companyId: id })),
      map((company) => getAffiliateSuccessAction({ company })),
      catchError((error) => of(getAffiliateFailureAction({ error }))),
    ),
  );

  constructor(
    private readonly companyApiService: CompanyApiService,
    private readonly actions$: Actions,
  ) {}
}
