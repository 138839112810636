import { Action, createReducer, on } from '@ngrx/store';
import { AffiliateState } from '../../models/app-state.model';
import { getAffiliateByIDAction, getAffiliateFailureAction, getAffiliateSuccessAction } from './affiliate.action';

export const affiliateInitialState: AffiliateState = {
  loading: false,
  entity: null,
  errors: null,
};

const reducer = createReducer(
  affiliateInitialState,

  on(getAffiliateByIDAction, (state) => ({
    ...state,
    loading: false,
  })),

  on(getAffiliateSuccessAction, (state, { company }) => ({
    ...state,
    loading: false,
    loaded: true,
    entity: company,
  })),

  on(getAffiliateFailureAction, (state, { error }) => ({
    ...state,
    loading: false,
    errors: error,
  })),
);

export function affiliateReducer(state: AffiliateState = affiliateInitialState, action: Action): AffiliateState {
  return reducer(state, action);
}
