import { Injectable, inject } from '@angular/core';
import { ENUM_PropertyClosedReason } from '@api-new/common';
import { HTTP_CP_Property, PropertyApiService } from '@api-new/propertyservice';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { concatLatestFrom } from '@ngrx/operators';
import { Store } from '@ngrx/store';
import { ToastService, ToastSeverity } from '@shared-lib/services/toast.service';
import { dateToISOStringWithoutTimezone } from '@shared-lib/utils/date-utils/dateToISOStringWithoutTimezone';
import { getBetterDeals } from '@shared/store/better-deal/better-deal.actions';
import { loadInitialPlatformData } from '@shared/store/common.actions';
import { closeMortgageSuccess } from '@shared/store/mortgages/mortgages.actions';
import { selectMortgageByPropertyId, selectMortgages } from '@shared/store/mortgages/mortgages.selectors';
import {
  addProperty,
  addPropertyFailure,
  addPropertySuccess,
  closeProperty,
  closePropertyFailure,
  closePropertySuccess,
  closePropertyWithMortgageSuccess,
  deletePropertyValue,
  deletePropertyValueFailure,
  deletePropertyValueSuccess,
  getProperties,
  getPropertiesFailure,
  getPropertiesSuccess,
  updateProperty,
  updatePropertyFailure,
  updatePropertySuccess,
  updatePropertyValuation,
  updatePropertyValuationFailure,
  updatePropertyValuationSuccess,
} from '@shared/store/properties/properties.actions';
import { EMPTY } from 'rxjs';
import { of } from 'rxjs/internal/observable/of';
import { catchError, map, switchMap } from 'rxjs/operators';

@Injectable()
export class PropertiesEffects {
  private readonly propertyApiService = inject(PropertyApiService);
  private readonly actions$ = inject(Actions);
  private readonly store = inject(Store);
  private readonly toastService = inject(ToastService);

  handleLoadInitialPlatformData = createEffect(() => this.actions$.pipe(ofType(loadInitialPlatformData), map(getProperties)));

  getProperties = createEffect(() =>
    this.actions$.pipe(
      ofType(getProperties),
      switchMap(() => this.propertyApiService.HTTP_CP_ListProperties()),
      map((properties) => getPropertiesSuccess(properties)),
      catchError((error) => of(getPropertiesFailure({ error }))),
    ),
  );

  addProperty = createEffect(() =>
    this.actions$.pipe(
      ofType(addProperty),
      switchMap(({ property }) => {
        return this.propertyApiService.HTTP_CP_CreateProperty(property).pipe(
          map((property: HTTP_CP_Property) => {
            return addPropertySuccess({ property });
          }),
          catchError((error) => of(addPropertyFailure({ error }))),
        );
      }),
    ),
  );

  updateProperty = createEffect(() =>
    this.actions$.pipe(
      ofType(updateProperty),
      switchMap(({ property }) => {
        return this.propertyApiService.HTTP_CP_UpdateProperty(property).pipe(
          map((property) => {
            return updatePropertySuccess({ property });
          }),
          catchError((error) => of(updatePropertyFailure({ error }))),
        );
      }),
    ),
  );

  handleUpdatePropertySuccess = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePropertySuccess),
      concatLatestFrom(() => [this.store.select(selectMortgages)]),
      switchMap(([{ property }, mortgages]) => {
        const mortgage = mortgages.find((mortgage) => mortgage.propertyId === property.id);
        this.toastService.showToast(ToastSeverity.success, 'Property was successfully updated');
        if (mortgage != null) {
          return of(getBetterDeals({ mortgageId: mortgage.id }));
        } else {
          return EMPTY;
        }
      }),
    ),
  );

  handleUpdatePropertyFailure = createEffect(
    () =>
      this.actions$.pipe(
        ofType(updatePropertyFailure),
        map(() => {
          this.toastService.showToast(ToastSeverity.error, 'Failed to update property');
        }),
      ),
    { dispatch: false },
  );

  updatePropertyValuation = createEffect(() =>
    this.actions$.pipe(
      ofType(updatePropertyValuation),
      switchMap(({ propertyValuation }) => {
        return this.propertyApiService.HTTP_CP_SetPropertyValue(propertyValuation).pipe(
          map((newPropertyValuation) => {
            return updatePropertyValuationSuccess({ propertyId: propertyValuation.propertyId, propertyValuation: newPropertyValuation });
          }),
          catchError((error) => of(updatePropertyValuationFailure({ error }))),
        );
      }),
    ),
  );

  deletePropertyValue = createEffect(() =>
    this.actions$.pipe(
      ofType(deletePropertyValue),
      switchMap(({ propertyValue }) => {
        return this.propertyApiService
          .HTTP_CP_UnsetPropertyValue({
            propertyId: propertyValue.propertyId,
            month: propertyValue.month,
            year: propertyValue.year,
          })
          .pipe(
            map((newPropertyValuation) => {
              return deletePropertyValueSuccess({ propertyId: propertyValue.propertyId, propertyValuation: newPropertyValuation });
            }),
            catchError((error) => of(deletePropertyValueFailure({ error }))),
          );
      }),
    ),
  );

  closeProperty = createEffect(() =>
    this.actions$.pipe(
      ofType(closeProperty),
      concatLatestFrom(({ propertyId }) => [this.store.select(selectMortgageByPropertyId(propertyId))]),
      switchMap(([{ propertyId, closedDate }, mortgage]) =>
        this.propertyApiService
          .HTTP_CP_CloseProperty({
            propertyId,
            when: dateToISOStringWithoutTimezone(closedDate),
            reason: ENUM_PropertyClosedReason.PROPERTY_CLOSED_REASON_PAID_OFF,
          })
          .pipe(
            map(() => {
              if (mortgage) {
                return closePropertyWithMortgageSuccess({ propertyId, mortgageId: mortgage.id });
              }
              return closePropertySuccess({ propertyId });
            }),
            catchError((error) => {
              return of(closePropertyFailure({ error }));
            }),
          ),
      ),
    ),
  );

  dispatchClosePropertyWithMortgageCloseProperty = createEffect(() =>
    this.actions$.pipe(
      ofType(closePropertyWithMortgageSuccess),
      map(({ propertyId }) => closePropertySuccess({ propertyId })),
    ),
  );

  dispatchClosePropertyWithMortgageCloseMortgage = createEffect(() =>
    this.actions$.pipe(
      ofType(closePropertyWithMortgageSuccess),
      map(({ mortgageId }) => closeMortgageSuccess({ mortgageId })),
    ),
  );
}
