import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { CaseApiService } from '@api-new/caseservice';
import { ErrorService } from '@app/OLD_shared/services/error.service';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { ToastService, ToastSeverity } from '@shared-lib/services/toast.service';
import { of } from 'rxjs';
import { catchError, map, switchMap } from 'rxjs/operators';
import {
  closeMortgageCase,
  closeMortgageCaseFailure,
  closeMortgageCaseSuccess,
  getMortgageCasesFailure,
  getMortgageCasesSuccess,
  getOldMortgageCases,
} from './mortgage-case.action';

@Injectable()
export class MortgageCaseEffect {
  getMortgageCases = createEffect(() =>
    this.actions$.pipe(
      ofType(getOldMortgageCases),
      switchMap(() =>
        this.caseApiService.HTTP_CP_ListMortgageCases().pipe(
          map(({ mortgageCases }) => getMortgageCasesSuccess({ mortgageCases: mortgageCases || [] })),
          catchError((error) => {
            this.errorService.pushError(error);
            return of(getMortgageCasesFailure({ error }));
          }),
        ),
      ),
    ),
  );

  closeMortgageCase = createEffect(() =>
    this.actions$.pipe(
      ofType(closeMortgageCase),
      switchMap(({ mortgageCaseCloseRequest }) =>
        this.caseApiService.HTTP_CP_UpdateMortgageCase(mortgageCaseCloseRequest).pipe(
          map(() => {
            this.toastService.showToast(ToastSeverity.success, 'Remortgage was successfully closed');
            return closeMortgageCaseSuccess({ mortgageCaseId: mortgageCaseCloseRequest.mortgageCaseId });
          }),
          catchError((error) => {
            this.toastService.showToast(ToastSeverity.error, 'Remortgage failed to close');
            return of(closeMortgageCaseFailure({ error }));
          }),
        ),
      ),
    ),
  );

  constructor(
    private readonly actions$: Actions,
    private readonly router: Router,
    private readonly errorService: ErrorService,
    private readonly toastService: ToastService,
    private readonly caseApiService: CaseApiService,
  ) {}
}
